import React from 'react'
import { useTranslation } from 'react-i18next'

import { ItemText } from './ItemText'

import { formatJstDate } from '../../../utils'
import { Col, Container, Row } from '../../basics'

type CancelledProps = {
  cancelledAt?: string | null
}

const translateNamespace = `components.ReservationDetail`

export const Cancelled: React.FC<CancelledProps> = ({ cancelledAt }) => {
  const { t, i18n } = useTranslation()

  return (
    <Container>
      <Row>
        <Col pc={3} sp={6}>
          <ItemText>{t(`${translateNamespace}.cancellationDate`)}</ItemText>
        </Col>
        <Col pc={3} sp={6}>
          <ItemText>
            {cancelledAt &&
              formatJstDate(
                cancelledAt,
                i18n.language === 'ja'
                  ? 'yyyy/MM/dd (E) HH:mm'
                  : 'MM/dd/yyyy HH:mm',
                i18n.language
              )}
          </ItemText>
        </Col>
      </Row>
    </Container>
  )
}
