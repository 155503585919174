import useSWR from 'swr'

import { newsApi, options } from '../../apis/suitebookClient'

export const useNewsApi = () => {
  const { data, isLoading, isValidating, error } = useSWR('/news', () =>
    newsApi.getNews(options).then((res) => res.data)
  )

  return { data, isLoading, isValidating, error }
}
