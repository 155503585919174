import { differenceInDays, parseISO } from 'date-fns'
import en from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import { format } from 'date-fns-tz'
import {
  isValidPhoneNumber as isValidLibPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'

import { RoomTypeEnum, TIMEZONE } from '../constants'
import i18n from '../i18n'

export const formatJstDatetime = (date: Date) =>
  format(date, "yyyy-MM-dd'T'HH:mm:ss.000xxx", {
    timeZone: TIMEZONE,
  })

export const formatJstDate = (
  date: string | Date,
  fmt = 'yyyy-MM-dd',
  locale = 'ja'
) => {
  if (typeof date === 'string') {
    return format(parseISO(date), fmt, {
      timeZone: TIMEZONE,
      locale: locale === 'ja' ? ja : en,
    })
  }
  return format(date, fmt, {
    timeZone: TIMEZONE,
    locale: locale === 'ja' ? ja : en,
  })
}

export const formatNights = (checkInDate: string, checkOutDate: string) => {
  const nights = differenceInDays(parseISO(checkOutDate), parseISO(checkInDate))
  if (nights === 0) {
    return i18n.t('utils.formatters.dayuse')
  }
  return i18n.t('utils.formatters.nights', { nights })
}

export const formatRoomSizeUnit = (unit: string) => {
  switch (unit) {
    case 'square metre':
      return '㎡'
    case 'tatami mat':
      return i18n.t('utils.formatters.tatamiMat')
    default:
      return ''
  }
}

export const formatRoomType = (roomType: string) => {
  switch (roomType) {
    case RoomTypeEnum.Single:
      return i18n.t('utils.formatters.roomType.single')
    case RoomTypeEnum.ThreeQuarter:
      return i18n.t('utils.formatters.roomType.threeQuarter')
    case RoomTypeEnum.Double:
      return i18n.t('utils.formatters.roomType.double')
    case RoomTypeEnum.Twin:
      return i18n.t('utils.formatters.roomType.twin')
    case RoomTypeEnum.Triple:
      return i18n.t('utils.formatters.roomType.triple')
    case RoomTypeEnum.FourBeds:
      return i18n.t('utils.formatters.roomType.fourBeds')
    case RoomTypeEnum.FiveBeds:
      return i18n.t('utils.formatters.roomType.fiveBeds')
    default:
      return i18n.t('utils.formatters.roomType.others')
  }
}

export const canonicalizeHyphen = (value: string) =>
  value
    .replace(/-/g, '')
    .replace(/ー/g, '')
    .replace(/[０１２３４５６７８９]/g, (c) =>
      String.fromCharCode(
        '0'.charCodeAt(0) + (c.charCodeAt(0) - '０'.charCodeAt(0))
      )
    )

export const isValidPhoneNumber = (value: string) =>
  isValidLibPhoneNumber(value) || isValidLibPhoneNumber(value, 'JP')

export const formatPhoneNumber = (value: string) => {
  if (isValidLibPhoneNumber(value)) {
    return parsePhoneNumber(value).formatInternational()
  }
  return parsePhoneNumber(value, 'JP').formatNational()
}
