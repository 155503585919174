import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Location as ReactRouterLocation,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { subDays } from 'date-fns'

import * as styles from './ReservationIndex.module.scss'

import { ReservationResponse } from '../../../apis/fvillage'
import { EXTERNAL_PATH, PATH, reservationRouteType } from '../../../constants'
import {
  useAuthProvider,
  useCreateNextPath,
  useLogin,
  useReservationPath,
  useReservationsApi,
  useSearchQueryParams,
} from '../../../hooks'
import { formatJstDate, getCurrentJstDateTime } from '../../../utils'
import { Sort, Tabs } from '../../basics'
import { Loader, NotFound } from '../../contents'
import { ReservationHistoryMainContent } from '../../layouts'
import { LoginModal } from '../../modals'
import { ReservationItem } from '../ReservationItem'

const translateNamespace = 'components.ReservationIndex'

type ListProps = {
  reservations: ReservationResponse[]
  total: number
}

type Order =
  | 'check_in_date'
  | '-check_in_date'
  | 'created_at'
  | '-created_at'
  | 'check_out_date'
  | '-check_out_date'

const isOrder = (value?: string): value is Order => {
  switch (value) {
    case 'check_in_date':
    case '-check_in_date':
    case 'created_at':
    case '-created_at':
    case 'check_out_date':
    case '-check_out_date':
      return true
    default:
      return false
  }
}

const isHistoryPage = (location: ReactRouterLocation) =>
  [
    PATH.reservations.hotels.history,
    PATH.reservations.spas.history,
    PATH.reservations.activities.history,
  ].includes(location.pathname)

// FIXME style
const List: React.FC<ListProps> = ({ reservations, total }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { onCreateNextPath } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const reservationPath = useReservationPath()

  return (
    <React.Fragment>
      <Sort
        count={total}
        onChange={(e) => {
          const path = isHistoryPage(location)
            ? reservationPath.history
            : reservationPath.root
          const nextPath = onCreateNextPath(path, {
            queryParams: { order: e.target.value },
          })
          navigate(nextPath)
        }}
        defaultValue={queryParams.order ?? 'check_in_date'}
      >
        <option value="check_in_date">
          {t(`components.Sort.list.ascCheckInDate`)}
        </option>
        <option value="-check_in_date">
          {t(`components.Sort.list.desCheckInDate`)}
        </option>
      </Sort>
      <ul>
        {Array.isArray(reservations) && reservations.length > 0 ? (
          reservations.map((reservation) => (
            <li className={styles.item} key={reservation.id}>
              <ReservationItem {...reservation} />
            </li>
          ))
        ) : (
          <NotFound
            title={t(`empty.reservationNotFound.attention`)}
            description1={t(`empty.reservationNotFound.direction`)}
          />
        )}
      </ul>
    </React.Fragment>
  )
}

export type ReservationIndexProps = {
  reservationType: (typeof reservationRouteType)[keyof typeof reservationRouteType]
}

export const ReservationIndex: React.FC<ReservationIndexProps> = ({
  reservationType,
}) => {
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const { token } = useAuthProvider()
  const { params, offset } = useSearchQueryParams()
  const { validateToken } = useLogin()
  const [isOpenLoginModal, setLoginModal] = useState(false)
  const reservationParams = useMemo(() => {
    const order = isOrder(params.order) ? params.order : 'check_in_date'
    if (isHistoryPage(location)) {
      const checkOutDateTo = formatJstDate(subDays(getCurrentJstDateTime(), 1))

      return {
        offset,
        checkOutDateTo,
        token,
        order,
      }
    }
    const checkOutDateFrom = formatJstDate(getCurrentJstDateTime())
    return {
      offset,
      checkOutDateFrom,
      token,
      order,
    }
  }, [location, offset, token, params.order])
  const { reservations, total, isLoading, error } = useReservationsApi({
    ...reservationParams,
    isHotel: reservationType === 'hotels',
    isSpa: reservationType === 'spas',
    isActivity: reservationType === 'activities',
  })
  const reservationPath = useReservationPath()

  useEffect(() => {
    const validateTokenFunction = async () => {
      const valid = await validateToken()

      if (!valid) {
        setLoginModal(true)
      }
    }
    validateTokenFunction()
  }, [validateToken])

  if (isLoading || error) {
    return (
      <React.Fragment>
        <Loader />
        <LoginModal
          isOpen={isOpenLoginModal}
          onClose={() => {
            setLoginModal(false)
            if (
              reservationType === 'spas' ||
              reservationType === 'activities'
            ) {
              window.location.href = EXTERNAL_PATH[reservationType]
              return
            }
            navigate(PATH.root)
          }}
          nextPath={window.location.pathname}
        />
      </React.Fragment>
    )
  }

  const tabItems = [
    {
      label: t(`${translateNamespace}.reservations`),
      key: reservationPath.root,
      children: <List reservations={reservations} total={total} />,
    },
    {
      label: t(`${translateNamespace}.histories`),
      key: reservationPath.history,
      children: <List reservations={reservations} total={total} />,
    },
  ]

  return (
    <ReservationHistoryMainContent>
      <Tabs
        items={tabItems}
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          navigate(onCreateNextPath(key, { queryParams: { page: 1 } }))
        }}
        optionalClass={styles.tabs}
      />
    </ReservationHistoryMainContent>
  )
}
