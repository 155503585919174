import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { zodResolver } from '@hookform/resolvers/zod'

import * as styles from './OptionPlanIndex.module.scss'

import {
  DEFAULT_TRANSACTION_ID,
  PATH,
  REGISTRATION_PATH,
} from '../../../constants'
import {
  useCreateNextPath,
  useLogin,
  useOptions,
  useOptionsApi,
  useSearchQueryParams,
} from '../../../hooks'
import { OptionItem, optionItemSchema } from '../../../schemas'
import { Button } from '../../basics'
import { Loader } from '../../contents'
import { MultiColumn } from '../../layouts'
import { LoginModal } from '../../modals'
import { OptionPlanItem } from '../OptionPlanItem'

const translateNamespace = 'components.OptionPlanIndex'

export const OptionPlanIndex: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath, params } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { onGet: onGetOptions, onSet: onSetOptions } = useOptions()
  const { data, isLoading } = useOptionsApi({
    property: params.property,
    room: params.room,
    plan: params.plan,
    checkInDate: queryParams.checkInDate,
    checkOutDate: queryParams.checkOutDate,
    numberOfAdults: queryParams.numberOfAdults,
    numberOfChildren: queryParams.numberOfChildren,
    numberOfInfants: queryParams.numberOfInfants,
    roomCount: queryParams.roomCount,
  })
  const [isOpenLoginModal, setLoginModal] = useState(false)
  const { validateToken } = useLogin()

  const initialValues = useMemo(
    () =>
      data?.map((option) => ({
        option: option?.id?.toString() ?? '',
        count: '0',
      })),
    [data]
  )

  const formMethods = useForm<OptionItem>({
    defaultValues: { items: initialValues },
    resolver: zodResolver(optionItemSchema),
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods
  const isError = Object.keys(errors).length > 0

  const { fields } = useFieldArray({
    name: 'items',
    control,
  })

  useEffect(() => {
    const options = onGetOptions()
    if (Array.isArray(options) && options.length > 0) {
      const items = options.map((item) => ({
        option: item?.option?.toString() ?? '',
        count: item?.count?.toString() ?? '0',
      }))
      reset({ items })
      return
    }
    reset({ items: initialValues })
  }, [initialValues, reset, onGetOptions])

  const nextPath = onCreateNextPath(REGISTRATION_PATH.registrations, {
    queryParams: { transaction: DEFAULT_TRANSACTION_ID },
    pathParams: { plan: params.plan },
  })

  const onSubmit = handleSubmit(async (formValues) => {
    const options = formValues.items.map((value) => ({
      option: Number(value.option),
      count: Number(value.count),
    }))
    onSetOptions(options)

    const validToken = await validateToken()
    if (!validToken) {
      setLoginModal(true)
      return
    }

    navigate(nextPath)
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit} className={styles.options}>
        {data &&
          fields
            .filter((field, index) => !!data[index])
            .map((field, index) => {
              const option = data[index]
              return (
                <OptionPlanItem
                  key={`option-plan-item-${option.id}`}
                  option={option}
                  index={index}
                />
              )
            })}
        <MultiColumn isSpColumnReverse>
          <Button
            htmlType="button"
            variant="tertiary"
            size="xmedium"
            outline
            onClick={() => {
              navigate(onCreateNextPath(PATH.plans))
            }}
          >
            {t(`${translateNamespace}.back`)}
          </Button>

          <Button
            htmlType="submit"
            variant="primary"
            size="xmedium"
            disabled={isError}
          >
            {t(`${translateNamespace}.next`)}
          </Button>
          <LoginModal
            isOpen={isOpenLoginModal}
            onClose={() => setLoginModal(false)}
            nextPath={nextPath.pathname}
          />
        </MultiColumn>
      </form>
    </FormProvider>
  )
}
