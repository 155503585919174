import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  ButtonWrapper,
  CancellationStepper,
  PageMessage,
  ReservationLayout,
} from '../../components'
import {
  useAuthProvider,
  useCreateNextPath,
  useReservationPath,
} from '../../hooks'

const translateNamespace = `frames.CancellationCompletionFrame`

export const CancellationCompletionFrame = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const { name } = useAuthProvider()
  const reservationPath = useReservationPath()

  return (
    <ReservationLayout
      pageTitle={t(`${translateNamespace}.pageTitle`)}
      reservationName={name}
    >
      <CancellationStepper
        current={3}
        stepName={t(`${translateNamespace}.stepName`)}
      />
      <PageMessage>{t(`${translateNamespace}.pageMessage`)}</PageMessage>
      <ButtonWrapper
        position="center"
        size="large"
        htmlType="button"
        variant="primary"
        onClick={() => {
          navigate(onCreateNextPath(reservationPath.root))
        }}
      >
        {t(`${translateNamespace}.next`)}
      </ButtonWrapper>
    </ReservationLayout>
  )
}
