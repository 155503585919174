import React from 'react'
import { useTranslation } from 'react-i18next'

import { ItemText } from './ItemText'

import { OverViewOption } from '../../../apis/fvillage'
import { usePublication } from '../../../hooks'
import { Col, Container, Row } from '../../basics'

type OptionItemProps = {
  item: OverViewOption
}

const translateNamespace = `components.ReservationDetail`

export const OptionItem: React.FC<OptionItemProps> = ({ item }) => {
  const { option } = item

  const { t, i18n } = useTranslation()
  const publication = usePublication(i18n.language, option?.publications)

  if (!publication) {
    return null
  }

  return (
    <Container>
      <Row>
        <Col pc={8} sp={12}>
          <ItemText>
            {publication.name} x {item.count}
          </ItemText>
        </Col>
        <Col pc={4} sp={12}>
          <ItemText pcAlign="left" spAlign="left">
            {t(`${translateNamespace}.optionPrice`, {
              price: item.salesPrice?.toLocaleString() ?? 0,
            })}
          </ItemText>
        </Col>
      </Row>
    </Container>
  )
}
