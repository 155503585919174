import * as styles from './Panel.module.scss'
import { PropertyCover } from './PropertyCover'

import { List } from '../../basics/List'
import { BaseText } from '../../basics/Text'

type PanelProps = {
  cover?: {
    name: string
    image: string
    desc: React.ReactNode
  }
  label?: React.ReactNode
  items: React.ReactNode[]
}

export const Panel: React.FC<PanelProps> = ({ cover, label, items }) => (
  <div className={styles.panel}>
    {cover && (
      <PropertyCover name={cover.name} image={cover.image} desc={cover.desc} />
    )}
    {label && (
      <BaseText align="center" background="blue1" weight="bold" color="white">
        <div className={styles.label}>{label}</div>
      </BaseText>
    )}
    <List
      items={items}
      renderItem={(item, i) => <List.Item key={i}>{item}</List.Item>}
    />
  </div>
)
