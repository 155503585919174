import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UseRoomsApiProps = {
  property?: string
  room?: string
  plan?: string
}

export const usePlanApi = ({ property, room, plan }: UseRoomsApiProps) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property && room && plan
      ? `/hotels/${property}/rooms/${room}/plans/${plan}`
      : null,
    property && room && plan
      ? () =>
          propertyApi
            .getPlan(property, room, plan, options)
            .then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
