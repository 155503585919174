import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { authApi, options } from '../../apis/suitebookClient'

export const useTokenCheckApi = (token: string) => {
  const check = useCallback(
    async () =>
      authApi.authTokenCheck({ token }, options).then((res) => res.data),
    [token]
  )
  const { trigger } = useSWRMutation('/token/check', check)

  return { trigger }
}
