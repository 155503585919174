import React from 'react'

import clsx from 'clsx'

import * as styles from './PropertyList.module.scss'

import { PropertyCard } from '../../../components'
import { useProperties } from '../../../hooks'

type CardProps = {
  name: string
  description: string
  image: string
  className: string
  access?: string
  pricing?: string
  officialUrl?: string
  insideFvillage?: boolean
}

const Card: React.FC<CardProps> = ({
  name,
  description,
  image,
  access,
  pricing,
  className,
  officialUrl,
  insideFvillage,
}) => (
  <div className={clsx(className, styles.card)}>
    <PropertyCard
      img={image}
      title={name}
      access={access}
      pricing={pricing}
      className={className}
      officialUrl={officialUrl}
      insideFvillage={insideFvillage}
    >
      <p className={styles.desc}>{description}</p>
    </PropertyCard>
  </div>
)

export const PropertyList: React.FC = () => {
  const properties = useProperties()
  const fvillageProperties = properties.filter(
    (property) => property.insideFvillage
  )
  const partnerProperties = properties.filter(
    (property) => !property.insideFvillage
  )

  return (
    <React.Fragment>
      <div className={styles.properties}>
        {fvillageProperties.map(
          ({
            id,
            slug,
            name,
            description,
            image,
            access,
            pricing,
            officialUrl,
            insideFvillage,
          }) =>
            name &&
            image &&
            slug && (
              <Card
                name={name}
                description={description ?? ''}
                image={image}
                access={access}
                pricing={pricing}
                officialUrl={officialUrl ?? ''}
                insideFvillage={insideFvillage}
                className={styles.property}
                key={id}
              />
            )
        )}
      </div>
      <div className={styles.partners}>
        {partnerProperties.map(
          ({
            id,
            slug,
            name,
            description,
            image,
            access,
            pricing,
            officialUrl,
            insideFvillage,
          }) =>
            name &&
            image &&
            slug && (
              <Card
                name={name}
                description={description ?? ''}
                image={image}
                access={access}
                pricing={pricing}
                officialUrl={officialUrl ?? ''}
                insideFvillage={insideFvillage}
                className={styles.partner}
                key={id}
              />
            )
        )}
      </div>
    </React.Fragment>
  )
}
