import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './LanguageNav.module.scss'
import { LanguageNavImage } from './LanguageNavImage'

import { TranslateModal } from '../../../modals'

const translateNamespace = 'components.TranslateModal.sp'

type LanguageNavProps = {
  isMenuOpen: boolean
}

export const LanguageNav: React.FC<LanguageNavProps> = ({ isMenuOpen }) => {
  const [translateModalIsOpen, setTranslateModalIsOpen] = useState(false)

  const { t } = useTranslation()

  return (
    <button
      className={clsx(isMenuOpen && styles.opened, styles.translateWrapper)}
      type="button"
      onClick={() => {
        setTranslateModalIsOpen(true)
      }}
    >
      <div className={styles.centerSvg}>
        <LanguageNavImage
          color={isMenuOpen ? styles.textWhite : styles.textBlack}
        />
        <TranslateModal
          isOpen={translateModalIsOpen}
          onClose={() => setTranslateModalIsOpen(false)}
        />
      </div>

      <div className={styles.translateLabel}>
        <div className={styles.translateTextClose}>
          {t(`${translateNamespace}.label`)}
        </div>
      </div>
    </button>
  )
}
