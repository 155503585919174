import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { format } from 'date-fns'

import * as styles from './PaymentForm.module.scss'

import { Registration } from '../../../../schemas'
import {
  Col,
  Container,
  ErrorMessage,
  Flex,
  FlexBox,
  FormInput,
  FormSelect,
  Icon,
  Option,
  Row,
} from '../../../basics'

const translateNamespace = `components.PaymentForm`

export const PaymentForm = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<Registration>()

  const { years } = useMemo(
    () => ({
      years: Array.from({ length: 21 }, (_, i) => i).map((i) => {
        const year = new Date().getFullYear() + i
        return {
          value: year,
          label: format(new Date(year, 0, 1), `yyyy`),
        }
      }),
    }),
    []
  )

  return (
    <div className={styles.paymentForm}>
      <Container>
        <Flex optionClass={styles.cards}>
          <FlexBox>
            <Icon mark="visa" />
          </FlexBox>
          <FlexBox>
            <Icon mark="master" />
          </FlexBox>
          <FlexBox>
            <Icon mark="jcb" />
          </FlexBox>
          <FlexBox>
            <Icon mark="amex" />
          </FlexBox>
          <FlexBox>
            <Icon mark="diners" />
          </FlexBox>
          <FlexBox>
            <Icon mark="discover" />
          </FlexBox>
        </Flex>
        {/* FIXME: Remove spacerSp if possible */}
        <Row gutterWidth={10} gutterHeight={30}>
          <Col pc={6} sp={12}>
            <FormInput
              control={control}
              name="cardNumber"
              inputMode="numeric"
              required
              label={t(`${translateNamespace}.label.cardNumber`)}
              format="#### #### #### ####"
            />
            <ErrorMessage errors={errors} name="cardNumber" />
          </Col>
          <Col pc={6} sp={12}>
            <div className={styles.spacerSp} />
            <FormInput
              control={control}
              name="holderName"
              inputMode="text"
              required
              label={t(`${translateNamespace}.label.holderName`)}
            />
            <ErrorMessage errors={errors} name="holderName" />
          </Col>
        </Row>
        <Row gutterWidth={10} gutterHeight={30}>
          <Col pc={3} sp={6}>
            <FormSelect
              required
              control={control}
              name="expirationMonth"
              label={t(`${translateNamespace}.label.expirationMonth`)}
            >
              <Option key="01" value="01">
                01
              </Option>
              <Option key="02" value="02">
                02
              </Option>
              <Option key="03" value="03">
                03
              </Option>
              <Option key="04" value="04">
                04
              </Option>
              <Option key="05" value="05">
                05
              </Option>
              <Option key="06" value="06">
                06
              </Option>
              <Option key="07" value="07">
                07
              </Option>
              <Option key="08" value="08">
                08
              </Option>
              <Option key="09" value="09">
                09
              </Option>
              <Option key="10" value="10">
                10
              </Option>
              <Option key="11" value="11">
                11
              </Option>
              <Option key="12" value="12">
                12
              </Option>
            </FormSelect>
          </Col>
          <Col pc={3} sp={6}>
            <FormSelect
              required
              control={control}
              name="expirationYear"
              label={t(`${translateNamespace}.label.expirationYear`)}
            >
              {years.map((year) => (
                <Option key={year.value} value={year.value}>
                  {year.label}
                </Option>
              ))}
            </FormSelect>
          </Col>
          <Col pc={6} sp={12}>
            <div className={styles.spacerSp} />
            <div>
              <FormInput
                control={control}
                name="securityCode"
                required
                inputMode="numeric"
                label={t(`${translateNamespace}.label.securityCode`)}
                maxLength={4}
              />
              <span className={styles.iconWrapper}>
                <Icon mark="security" />
              </span>
            </div>
            <ErrorMessage errors={errors} name="securityCode" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
