import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  DEFAULT_ADULTS,
  DEFAULT_CHILDREN,
  DEFAULT_INFANTS,
  DEFAULT_ROOMS,
  DEFAULT_TRANSACTION_ID,
  PAGE_SIZE,
} from '../../constants'
import { addJstDate, getDefaultCheckInDate } from '../../utils'

export type SearchParam = {
  checkInDate?: string
  checkOutDate?: string
  roomCount?: number
  numberOfAdults?: number
  numberOfChildren?: number
  numberOfInfants?: number
  page?: number
  order?: string // reservation api's order
  roomOrder?: string // room api's order
  planOrder?: string // plan api's order
  showAll?: boolean
  facility?: boolean
  transaction?: number
}

type Required<T> = {
  [P in keyof T]-?: T[P]
}

export const updateSearchParams = (
  searchParams: URLSearchParams,
  {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
    page,
    order,
    roomOrder,
    planOrder,
    showAll,
    facility,
    transaction,
  }: SearchParam
) => {
  if (checkInDate) {
    searchParams.set('check_in', checkInDate)
  }
  if (checkOutDate) {
    searchParams.set('check_out', checkOutDate)
  }
  if (typeof numberOfAdults !== 'undefined') {
    searchParams.set('adults', numberOfAdults.toString())
  }
  if (typeof numberOfChildren !== 'undefined') {
    searchParams.set('children', numberOfChildren.toString())
  }
  if (typeof numberOfInfants !== 'undefined') {
    searchParams.set('infants', numberOfInfants.toString())
  }
  if (typeof roomCount !== 'undefined') {
    searchParams.set('rooms', roomCount.toString())
  }
  if (typeof page !== 'undefined') {
    searchParams.set('page', page.toString())
  }
  if (order) {
    searchParams.set('order', order)
  }
  if (roomOrder) {
    searchParams.set('room_order', roomOrder)
  }
  if (planOrder) {
    searchParams.set('plan_order', planOrder)
  }
  if (typeof showAll !== 'undefined') {
    searchParams.set('show_all', showAll.toString())
  }
  if (typeof facility !== 'undefined') {
    searchParams.set('facility', facility.toString())
  }
  if (typeof transaction !== 'undefined') {
    searchParams.set('transaction', transaction.toString())
  }

  return searchParams
}

const useQueryParams = (searchParams: URLSearchParams): Required<SearchParam> =>
  useMemo(() => {
    const checkInDate = getDefaultCheckInDate()

    const checkOutDate =
      searchParams.get('facility') === 'true'
        ? checkInDate
        : addJstDate(checkInDate, 1)

    return {
      checkInDate: searchParams.get('check_in') ?? checkInDate,
      checkOutDate: searchParams.get('check_out') ?? checkOutDate,
      // Default room number
      roomCount: searchParams.get('rooms')
        ? Number(searchParams.get('rooms'))
        : DEFAULT_ROOMS,
      // Default number of adults
      numberOfAdults: searchParams.get('adults')
        ? Number(searchParams.get('adults'))
        : DEFAULT_ADULTS,
      // Default number of children
      numberOfChildren: searchParams.get('children')
        ? Number(searchParams.get('children'))
        : DEFAULT_CHILDREN,
      // Default number of infants
      numberOfInfants: searchParams.get('infants')
        ? Number(searchParams.get('infants'))
        : DEFAULT_INFANTS,
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      order: searchParams.get('order') ?? '',
      roomOrder: searchParams.get('room_order') ?? '',
      planOrder: searchParams.get('plan_order') ?? '',
      showAll: searchParams.get('show_all') === 'true',
      facility: searchParams.get('facility') === 'true',
      transaction: searchParams.get('transaction')
        ? Number(searchParams.get('transaction'))
        : DEFAULT_TRANSACTION_ID,
    }
  }, [searchParams])

export const useSearchQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const queryParams = useQueryParams(searchParams)

  const onUpdateSearchParams = useCallback(
    (params: SearchParam, replace = false) => {
      setSearchParams(updateSearchParams(searchParams, params), {
        replace,
      })
    },
    [searchParams, setSearchParams]
  )

  const offset = useMemo(
    () => (queryParams.page <= 0 ? 0 : queryParams.page - 1) * PAGE_SIZE,
    [queryParams.page]
  )

  return { params: queryParams, onUpdateSearchParams, offset }
}
