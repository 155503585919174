import { useMemo } from 'react'

import { ImageResponse } from '../../apis/fvillage'

export const useImages = (images: ImageResponse[] | undefined) =>
  useMemo(() => {
    if (!images) {
      return [
        {
          pc: { src: '' },
          sp: { src: '' },
          alt: '',
        },
      ]
    }
    return images.map((image) => ({
      pc: { src: image.url },
      sp: { src: image.url },
      alt: image.title,
    }))
  }, [images])
