import { Trans, useTranslation } from 'react-i18next'

import * as styles from './LoginModal.module.scss'

import loginModalImage from '../../../assets/login-modal.png'
import { useLogin, useStorageEnabled } from '../../../hooks'
import { BaseText, ButtonWrapper, Modal, ModalProps, Text } from '../../basics'

const translateNamespace = 'components.LoginModal'

type TranslateModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  nextPath: string
}

export const LoginModal: React.FC<TranslateModalProps> = ({
  nextPath,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  const { onLogin } = useLogin()
  const storageEnabled = useStorageEnabled()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Text>{t(`${translateNamespace}.title`)}</Text>}
    >
      <div className={styles.wrapper}>
        <img
          src={loginModalImage}
          alt="F VILLAGE BALLPARK"
          className={styles.image}
        />
        {!storageEnabled && (
          <div className={styles.error}>
            <BaseText color="red0" size={12}>
              <Trans
                i18nKey={`${translateNamespace}.storageError`}
                t={t}
                components={{
                  sitePolicy: (
                    <a // eslint-disable-line jsx-a11y/anchor-has-content
                      className={styles.link}
                      href="https://www.hkdballpark.com/sitepolicy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="site policy"
                    />
                  ),
                }}
              />
            </BaseText>
          </div>
        )}
        <div className={styles.button}>
          <ButtonWrapper
            htmlType="button"
            position="center"
            size="medium"
            disabled={!storageEnabled}
            onClick={() => onLogin(nextPath)}
          >
            {t(`${translateNamespace}.login`)}
          </ButtonWrapper>
        </div>
        <div className={styles.links}>
          <div className={styles.linkWrapper}>
            <BaseText color="gray1" align="center" size={12}>
              <a
                className={styles.link}
                href="https://fanclub.fighters.co.jp/register/"
                target="_blank"
                rel="noreferrer"
              >
                {t(`${translateNamespace}.registerFanClub`)}
              </a>
            </BaseText>
          </div>
          <div className={styles.linkWrapper}>
            <BaseText color="gray1" align="center" size={12}>
              <a
                className={styles.link}
                href="https://fid.hkdballpark.com/register"
                target="_blank"
                rel="noreferrer"
              >
                {t(`${translateNamespace}.registerFvillage`)}
              </a>
            </BaseText>
          </div>
        </div>
      </div>
    </Modal>
  )
}
