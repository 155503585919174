import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { CreateTransactionRequest } from '../../apis/fvillage'
import { tokenOptions, transactionApi } from '../../apis/suitebookClient'

export const useCreateTransactionApi = (token: string) => {
  const options = tokenOptions(token)

  const createTransaction = useCallback(
    async (key: string, { arg }: { arg: CreateTransactionRequest['input'] }) =>
      transactionApi.createTransaction({ input: arg }, options),
    [options]
  )

  const { trigger } = useSWRMutation('/transaction/create', createTransaction)

  return {
    trigger,
  }
}
