import { useMemo } from 'react'

import i18n from '../../i18n'
import { usePropertiesApi } from '../usePropertiesApi'
import { getPublication } from '../usePublication'

export const useProperties = () => {
  const { data: properties } = usePropertiesApi({ isHotel: true })
  const { language } = i18n

  return useMemo(() => {
    if (!Array.isArray(properties)) {
      return []
    }

    return properties.map((property) => {
      const publication = getPublication(language, property.publications ?? [])
      const image =
        Array.isArray(property.images) && property.images.length > 0
          ? property.images[0].url
          : '/img/hotel01.jpg'

      return {
        ...publication,
        image,
        id: property.id,
        slug: property.slug,
        officialUrl: property.officialUrl,
        insideFvillage: property.insideFvillage,
      }
    })
  }, [properties, language])
}
