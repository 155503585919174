import React from 'react'
import { useTranslation } from 'react-i18next'

import { CancelPolicy } from './CancelPolicy'
import * as styles from './CancelPolicySection.module.scss'

import { CancelPolicyResponse } from '../../../apis/fvillage'
import { Section } from '../../basics'
import { Note } from '../Note'

type CancelPolicySectionProps = {
  cancelPolicy?: CancelPolicyResponse
  canCancel?: boolean
  isCancelled?: boolean
}

const translateNamespace = 'components.CancelPolicy'

export const CancelPolicySection: React.FC<CancelPolicySectionProps> = ({
  cancelPolicy,
  canCancel,
  isCancelled,
}) => {
  const { t } = useTranslation()
  return (
    <Section title={t(`${translateNamespace}.cancelTitle`)}>
      <div className={styles.buttonWrapper}>
        <CancelPolicy {...cancelPolicy} />
      </div>
      <Note>{t(`${translateNamespace}.cancelNote`)}</Note>
      {isCancelled !== true && (
        <Note>
          {t(
            canCancel !== false
              ? `${translateNamespace}.aboutDate`
              : `${translateNamespace}.cannotCancel`
          )}
        </Note>
      )}
    </Section>
  )
}
