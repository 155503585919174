import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './ReservationStatus.module.scss'

import { GuestStatusEnum } from '../../../constants'

type ReservationStatusProps = {
  guestStatus?: GuestStatusEnum
}

const translateNamespace = 'components.ReservationStatus'

export const ReservationStatus: React.FC<ReservationStatusProps> = ({
  guestStatus,
}) => {
  const { t } = useTranslation()
  let status: 'beforeStaying' | 'hasStayed' | 'canceled' | 'staying'
  switch (guestStatus) {
    case GuestStatusEnum.PreArrival:
      status = 'beforeStaying'
      break
    case GuestStatusEnum.CheckInDay:
      status = 'staying'
      break
    case GuestStatusEnum.Stay:
      status = 'staying'
      break
    case GuestStatusEnum.CheckOutDay:
      status = 'staying'
      break
    case GuestStatusEnum.Departure:
      status = 'hasStayed'
      break
    case GuestStatusEnum.NoShow:
      status = 'canceled'
      break
    case GuestStatusEnum.CanceledByProperty:
      status = 'canceled'
      break
    case GuestStatusEnum.CanceledByGuest:
      status = 'canceled'
      break
    default:
      status = 'beforeStaying'
      break
  }
  return (
    <span className={clsx(styles.reservationStatusBase, styles[status])}>
      <span className={styles.check} />
      {t(`${translateNamespace}.${status}`)}
    </span>
  )
}
