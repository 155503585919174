/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { isValid, parseISO } from 'date-fns'

import * as styles from './PersonalForm.module.scss'

import { Registration } from '../../../../schemas'
import {
  Col,
  Container,
  ErrorMessage,
  Flex,
  FlexBox,
  FormInput,
  FormSelect,
  Option,
} from '../../../basics'
import { FormRow } from '../FormRow'

const translateNamespace = 'components.PersonalForm'

const yearRange = 100
const startYear = new Date().getFullYear() - yearRange

const months = [
  { value: '01', jp: '1月', en: 'Jan.' },
  { value: '02', jp: '2月', en: 'Feb.' },
  { value: '03', jp: '3月', en: 'Mar.' },
  { value: '04', jp: '4月', en: 'Apr.' },
  { value: '05', jp: '5月', en: 'May.' },
  { value: '06', jp: '6月', en: 'Jun.' },
  { value: '07', jp: '7月', en: 'Jul.' },
  { value: '08', jp: '8月', en: 'Aug.' },
  { value: '09', jp: '9月', en: 'Sep.' },
  { value: '10', jp: '10月', en: 'Oct.' },
  { value: '11', jp: '11月', en: 'Nov.' },
  { value: '12', jp: '12月', en: 'Dec.' },
]

export const PersonalForm = () => {
  const { t, i18n } = useTranslation()
  const {
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useFormContext<Registration>()

  const validateDate = useCallback(() => {
    const { birthYear, birthMonth, birthDay } = getValues()
    if (isValid(parseISO(`${birthYear}-${birthMonth}-${birthDay}`))) {
      clearErrors('birthDay')
    } else {
      setError('birthDay', { message: t(`${translateNamespace}.invalidDate`) })
    }
  }, [clearErrors, setError, getValues, t])

  useEffect(() => {
    validateDate()
  }, [validateDate])

  return (
    <Container>
      <FormRow>
        <Col>
          <FormInput
            control={control}
            name="name"
            required
            inputMode="text"
            label={t(`${translateNamespace}.label.nameLabel`)}
            placeholder={t(`${translateNamespace}.placeholder.name`)}
          />
          <ErrorMessage errors={errors} name="name" />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormInput
            control={control}
            name="kana"
            inputMode="text"
            label={t(`${translateNamespace}.label.nameKanaLabel`)}
            placeholder={t(`${translateNamespace}.placeholder.kana`)}
          />
          <ErrorMessage errors={errors} name="kana" />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormInput
            control={control}
            name="phone"
            required
            inputMode="tel"
            label={t(`${translateNamespace}.label.phone`)}
            placeholder={t(`${translateNamespace}.label.phone`)}
          />
          <ErrorMessage errors={errors} name="phone" />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Flex optionClass={styles.birthDay}>
            <FlexBox>
              <FormSelect
                required
                control={control}
                name="birthYear"
                label={t(`${translateNamespace}.label.birthDay`)}
                onChange={validateDate}
              >
                {[...Array(yearRange)].map((_, i) => {
                  const year = startYear + i
                  return (
                    <Option key={`birth-year-${i}`} value={year}>
                      {year + t(`${translateNamespace}.year`)}
                    </Option>
                  )
                })}
              </FormSelect>
            </FlexBox>
            <FlexBox>
              <FormSelect
                control={control}
                name="birthMonth"
                label=""
                onChange={validateDate}
              >
                {months.map((month) => (
                  <Option
                    key={`birth-month-${month.value}`}
                    value={month.value}
                  >
                    {i18n.language === 'ja' ? month.jp : month.en}
                  </Option>
                ))}
              </FormSelect>
            </FlexBox>
            <FlexBox>
              <FormSelect
                control={control}
                name="birthDay"
                label=""
                onChange={validateDate}
              >
                {[...Array(31)].map((_, i) => {
                  const day = (i + 1).toString()
                  return (
                    <Option key={`birth-day-${i}`} value={day.padStart(2, '0')}>
                      {day + t(`${translateNamespace}.day`)}
                    </Option>
                  )
                })}
              </FormSelect>
            </FlexBox>
          </Flex>
          <ErrorMessage errors={errors} name="birthDay" />
        </Col>
      </FormRow>
    </Container>
  )
}
