import React from 'react'

import * as styles from './Loader.module.scss'

type LoaderProps = {
  children?: React.ReactNode
}

export const Loader: React.FC<LoaderProps> = ({ children }) => (
  <React.Fragment>
    <div className={styles.loaderWrapper}>
      <div className={styles.loader}>Loading...</div>
    </div>
    {children && <div className={styles.children}>{children}</div>}
  </React.Fragment>
)
