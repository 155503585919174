import { z } from 'zod'

import i18n from '../i18n'

const regex = /^[0-9]+$/

export const optionItemSchema = z.object({
  items: z.array(
    z.object({
      option: z.string(), // Option id
      count: z.string().regex(regex, i18n.t('validations.number')).nullish(),
    })
  ),
})

export type OptionItem = z.infer<typeof optionItemSchema>
