import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './Menu.module.scss'

import account from '../../../../assets/account.svg'
import fighters from '../../../../assets/fighters.svg'
import language from '../../../../assets/language.svg'
import reservation from '../../../../assets/reservation.svg'
import returnTop from '../../../../assets/return-top.svg'
import search from '../../../../assets/search.svg'
import { PATH } from '../../../../constants'
import { useLogin, useLogout, useReservationPath } from '../../../../hooks'
import { LoginModal, SearchModal, TranslateModal } from '../../../modals'

const translateNamespace = 'components.Header.icon'

type MenuProps = {
  isHotel: boolean
}

export const Menu: React.FC<MenuProps> = ({ isHotel }) => {
  const { t } = useTranslation()
  const { onLogin, validateToken } = useLogin()
  const { onLogout } = useLogout()
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const isTopPage = useMemo(
    () => location.pathname === PATH.root,
    [location.pathname]
  )
  const [isTranslateModalOpen, setTranslateModalOpen] = useState(false)
  const reservationPath = useReservationPath()
  const [isLoginModalOpen, setLoginModalOpen] = useState(false)
  const [validToken, setValidToken] = useState(false)

  useEffect(() => {
    const validateTokenFunction = async () => {
      const valid = await validateToken()
      setValidToken(valid)
    }
    validateTokenFunction()
  }, [validateToken])

  return (
    <nav className={styles.headerNav}>
      <ul>
        <li>
          <button
            type="button"
            className={styles.headerNavItem}
            onClick={() => (validToken ? onLogout() : onLogin())}
          >
            <span className={styles.imgWrapper}>
              <img
                alt="reservation icon"
                src={account}
                className={styles.svg}
              />
            </span>
            {validToken ? (
              <span className={styles.textWrapper}>
                <span className={styles.pcMainNav}>
                  {t(`${translateNamespace}.pc.mainnav.logout`)}
                </span>
                <span className={styles.spMainNav}>
                  {t(`${translateNamespace}.sp.mainnav.logout`)}
                </span>

                <div className={styles.spSubNav}>
                  {t(`${translateNamespace}.sp.subnav.logout`)}
                </div>
              </span>
            ) : (
              <span className={styles.textWrapper}>
                <span className={styles.pcMainNav}>
                  {t(`${translateNamespace}.pc.mainnav.login`)}
                </span>
                <span className={styles.spMainNav}>
                  {t(`${translateNamespace}.sp.mainnav.login`)}
                </span>

                <div className={styles.spSubNav}>
                  {t(`${translateNamespace}.sp.subnav.login`)}
                </div>
              </span>
            )}
          </button>
        </li>
        <li>
          <button
            type="button"
            className={styles.headerNavItem}
            onClick={async () => {
              const valid = await validateToken()
              if (!valid) {
                setLoginModalOpen(true)
                return
              }
              navigate(reservationPath.root)
            }}
          >
            <span className={styles.imgWrapper}>
              <img
                alt="reservation icon"
                src={reservation}
                className={clsx(styles.svg, styles.svgBooking)}
              />
            </span>
            <span className={styles.textWrapper}>
              <span className={styles.pcMainNav}>
                {t(`${translateNamespace}.pc.mainnav.booking`)}
              </span>
              <span className={styles.spMainNav}>
                {t(`${translateNamespace}.sp.mainnav.booking`)}
              </span>

              <div className={styles.spSubNav}>
                {t(`${translateNamespace}.sp.subnav.booking`)}
              </div>
            </span>
            <LoginModal
              isOpen={isLoginModalOpen}
              onClose={() => setLoginModalOpen(false)}
              nextPath={reservationPath.root}
            />
          </button>
        </li>

        {isHotel || isTopPage ? (
          // If the property kind is an onsen (not a hotel), hide all links related to hotels.
          // The flow for an onsen page only has a link from the mother site.
          <li>
            <Link
              to="#search"
              className={styles.headerNavItem}
              onClick={(e) => {
                e.preventDefault()
                setSearchModalIsOpen(true)
              }}
            >
              <span className={styles.imgWrapper}>
                <img
                  alt="search icon"
                  src={search}
                  className={clsx(styles.svg, styles.svgSearch)}
                />
              </span>
              <span className={styles.textWrapper}>
                <span className={styles.pcMainNav}>
                  {t(`${translateNamespace}.pc.mainnav.search`)}
                </span>

                <span className={styles.spMainNav}>
                  {t(`${translateNamespace}.sp.mainnav.search`)}
                </span>

                <div className={styles.spSubNav}>
                  {t(`${translateNamespace}.sp.subnav.search`)}
                </div>
              </span>
            </Link>
            <SearchModal
              isOpen={searchModalIsOpen}
              onClose={() => setSearchModalIsOpen(false)}
            />
          </li>
        ) : null}

        {isHotel || isTopPage ? (
          // If the user is currently on a hotel reservation page, display an icon in the header navigation
          // that allows them to return to the top page. This is provided because users may want to
          // start a new hotel reservation and this provides a convenient way to do so.
          <li className="pc">
            <Link to="/" className={styles.headerNavItem}>
              <span className={styles.imgWrapper}>
                <img
                  alt="return to top"
                  src={returnTop}
                  className={styles.svgTop}
                />
              </span>
              <span className={styles.textWrapper}>
                <span className={styles.pcMainNav}>
                  {t(`${translateNamespace}.pc.mainnav.returnTop`)}
                </span>
                <span className={styles.spMainNav}>
                  {t(`${translateNamespace}.sp.mainnav.returnTop`)}
                </span>

                <div className={styles.spSubNav}>
                  {t(`${translateNamespace}.sp.subnav.returnTop`)}
                </div>
              </span>
            </Link>
          </li>
        ) : null}

        <li className="pc">
          <button
            type="button"
            className={styles.headerNavItem}
            onClick={() => {
              setTranslateModalOpen(true)
            }}
          >
            <span className={styles.imgWrapper}>
              <img alt="language icon" src={language} className={styles.svg} />
            </span>
            <span className={styles.textWrapper}>
              <span className={styles.pcMainNav}>
                {t(`${translateNamespace}.pc.mainnav.language`)}
              </span>
              <span className={styles.spMainNav}>
                {t(`${translateNamespace}.sp.mainnav.language`)}
              </span>

              <div className={styles.spSubNav}>
                {t(`${translateNamespace}.sp.subnav.language`)}
              </div>
            </span>
          </button>
          <TranslateModal
            isOpen={isTranslateModalOpen}
            onClose={() => setTranslateModalOpen(false)}
          />
        </li>

        <li>
          <a
            href="https://www.fighters.co.jp/"
            className={styles.headerNavItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.imgWrapper}>
              <img alt="fighters logo" src={fighters} className={styles.svg} />
            </span>
            <span className={styles.textWrapper}>
              <span className={styles.pcMainNav}>
                {t(`${translateNamespace}.pc.mainnav.fighters`)}
              </span>
              <span className={styles.spMainNav}>
                {t(`${translateNamespace}.sp.mainnav.fighters`)}
              </span>
              <div className={styles.spSubNav}>
                {t(`${translateNamespace}.sp.subnav.fighters`)}
              </div>
            </span>
          </a>
        </li>
      </ul>
    </nav>
  )
}
