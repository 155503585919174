import { z } from 'zod'

import i18n from '../i18n'
import { formatPhoneNumber, isValidPhoneNumber } from '../utils'

// Ref: https://github.com/angular/angular/blob/87b8aba238ac92f4dbc02dd66be187fc863bdba7/packages/forms/src/validators.ts#LL125C1-L126C1
const emailRegex =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const registrationSchema = z.object({
  name: z.string().min(1, { message: i18n.t('validations.required') }),
  kana: z.string().optional(),
  phone: z
    .string()
    .refine((v) => isValidPhoneNumber(v), {
      message: i18n.t('validations.phone'),
    })
    .transform((v) => formatPhoneNumber(v)),
  postalCode: z.string().min(1, { message: i18n.t('validations.required') }),
  prefecture: z.string().min(1, { message: i18n.t('validations.required') }),
  city: z.string().min(1, { message: i18n.t('validations.required') }),
  street: z.string().min(1, { message: i18n.t('validations.required') }),
  email: z.string().regex(emailRegex, i18n.t('validations.email')),
  cardNumber: z
    .string()
    .refine((v) => v.replaceAll(' ', '').match(/^\d{14,16}$/), {
      message: i18n.t('validations.cardNumber'),
    })
    .transform((v) => v.replaceAll(' ', '')),
  holderName: z.string().min(1, { message: i18n.t('validations.required') }),
  expirationMonth: z
    .string()
    .min(1, { message: i18n.t('validations.required') }),
  expirationYear: z
    .string()
    .min(1, { message: i18n.t('validations.required') }),
  securityCode: z
    .string()
    .regex(/^[0-9]+$/, i18n.t('validations.number'))
    .min(1, { message: i18n.t('validations.required') })
    .max(4, { message: i18n.t('validations.maxlength', { maximum: 4 }) }),
  specialServiceRequest: z
    .string()
    .max(200, { message: i18n.t('validations.maxlength', { maximum: 200 }) })
    .optional(),
  birthYear: z.string().regex(/^\d{4}$/, i18n.t('validations.number')),
  birthMonth: z.string().regex(/^\d{2}$/, i18n.t('validations.number')),
  birthDay: z.string().regex(/^\d{2}$/, i18n.t('validations.number')),
})

export const discountCodeSchema = z.object({
  discountCode: z.string().optional(),
})

export const agreementSchema = z.object({
  agreement: z.boolean(),
})

export type Registration = z.infer<typeof registrationSchema>

export type DiscountCode = z.infer<typeof discountCodeSchema>

export type Agreement = z.infer<typeof agreementSchema>
