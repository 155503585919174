import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { PATH } from '../../constants'
import { useAuthTokenApi } from '../useAuthTokenApi'
import { useCreateNextPath } from '../useCreateNextPath'

const REDIRECT_URL = process.env.VITE_OAUTH_REDIRECT_URL || ''

const reservationUrlPattern = /^\/hotels\/reservations|^\/spas\/reservations/

const sessionStorageQueryParamKeys = [
  'checkInDate',
  'checkOutDate',
  'numberOfAdults',
  'numberOfChildren',
  'numberOfInfants',
  'page',
  'roomCount',
  'showAll',
  'roomOrder',
  'planOrder',
  'order',
]

const clearSessionStorage = () => {
  try {
    sessionStorageQueryParamKeys.forEach((key) => {
      sessionStorage.removeItem(`query.${key}`)
    })
    sessionStorage.removeItem(`query.next`)
  } catch (e) {
    // Do nothing
  }
}

const restoreQueryParams = () => {
  const params: { [key: string]: string } = {}
  sessionStorageQueryParamKeys.forEach((key) => {
    try {
      const value = sessionStorage.getItem(`query.${key}`) || ''
      if (key === 'page' && value === '1') {
        return
      }
      params[key] = value
    } catch (e) {
      // Do nothing
    }
  })
  return params
}

export const useLoginCallback = (code: string | null) => {
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  // Sending raw url blocks AWS WAF(response 403),
  // so we encode Base64 encoded value.
  const { trigger } = useAuthTokenApi(code, btoa(REDIRECT_URL))
  // For avoid React.StrictMode render twice at development mode
  const ref = useRef(true)
  useEffect(() => {
    if (code) {
      if (process.env.NODE_ENV === 'development') {
        if (ref.current) {
          ref.current = false
          return
        }
      }
      try {
        trigger().then((data) => {
          sessionStorage.setItem('access_token', data?.token ?? '')
          sessionStorage.setItem('name', data?.name ?? '')
          sessionStorage.setItem('kana', data?.kana ?? '')
          sessionStorage.setItem('email', data?.email ?? '')
          sessionStorage.setItem('phone', data?.phone ?? '')
          sessionStorage.setItem('postalCode', data?.postalCode ?? '')
          sessionStorage.setItem('prefecture', data?.prefecture ?? '')
          sessionStorage.setItem('address1', data?.address1 ?? '')
          sessionStorage.setItem('address2', data?.address2 ?? '')
          sessionStorage.setItem('address3', data?.address3 ?? '')
          sessionStorage.setItem('dateOfBirth', data?.dateOfBirth ?? '')

          const next = sessionStorage.getItem('query.next') ?? '/'
          if (next === '/') {
            clearSessionStorage()
            navigate(next)
            return
          }
          const queryParams = restoreQueryParams()
          clearSessionStorage()

          // If path is reservations, ignore query params.
          const params = reservationUrlPattern.exec(next)
            ? { clearQueryParams: true }
            : { queryParams }
          const nextPath = onCreateNextPath(next, params)

          navigate(nextPath)
        })
      } catch (e) {
        // Login failed, always redirect to login error
        navigate(PATH.errors.loginError)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
