import DOMPurify from 'isomorphic-dompurify'
import { marked } from 'marked'

import './style.scss'

export const Markdown = ({ content }: { content: string }) => (
  <div
    className="markdown"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(
        marked.parse(content, {
          gfm: true,
          breaks: true,
        }) as string
      ),
    }}
  />
)
