import React from 'react'
import { FieldErrors } from 'react-hook-form'

import {
  ErrorMessage as HookFormErrorMessage,
  Props as HookFormErrorMessageProps,
} from '@hookform/error-message'

import * as styles from './ErrorMessage.module.scss'

type Element =
  | undefined
  | React.ReactElement
  | React.ComponentType<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  | keyof JSX.IntrinsicElements

type ErrorMessageProps<
  TFieldErrors extends FieldErrors,
  TAs extends Element = undefined
> = HookFormErrorMessageProps<TFieldErrors, TAs>

export const ReactHookFormErrorMessage = <
  TFieldErrors extends FieldErrors,
  TAs extends Element = undefined
>(
  props: ErrorMessageProps<TFieldErrors, TAs>
) => {
  const { errors, name } = props
  // Don't memorlize since we need evaluate if name of error exists.
  const error = errors
    ? Object.keys(errors).find((value) => name.startsWith(value))
    : undefined

  if (error) {
    return (
      <div className={styles.error}>
        <HookFormErrorMessage {...props} />
      </div>
    )
  }

  return null
}

/**
 * Wrapped hookform/error-message with styling.
 */
export const ErrorMessage = <
  TFieldErrors extends FieldErrors,
  TAs extends Element = undefined
>(
  props: ErrorMessageProps<TFieldErrors, TAs>
) => {
  const { errors, name } = props
  // Don't memorlize since we need evaluate if name of error exists.
  const error = errors
    ? Object.keys(errors).find((value) => name.startsWith(value))
    : undefined

  if (error) {
    return (
      <div className={styles.errorWrappter}>
        <ReactHookFormErrorMessage {...props} />
      </div>
    )
  }
  return null
}
