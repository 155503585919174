import { forwardRef, useMemo } from 'react'

import clsx from 'clsx'

import * as styles from './Radio.module.scss'
import {
  RadioContext,
  RadioGroupContextValue,
  useRadioProvider,
} from './RadioContext'

export type RadioGroupProps = RadioGroupContextValue & {
  disabled?: boolean
  children: React.ReactNode
}

type RadioProps = {
  value: string | number
  disabled?: boolean
  children: React.ReactNode
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ name, value, onChange, children, disabled }, ref) => {
    const context = useMemo(
      () => ({
        name,
        value,
        onChange,
        disabled,
      }),
      [name, value, onChange, disabled]
    )
    return (
      <div ref={ref}>
        <RadioContext.Provider value={context}>
          {children}
        </RadioContext.Provider>
      </div>
    )
  }
)

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { name, value, onChange, disabled } = useRadioProvider()
  const checked = useMemo(() => props.value === value, [props.value, value])
  const id = `radio-${props.value.toString()}`

  return (
    <label htmlFor={id} className={clsx(styles.radioWrapper)}>
      <span className={clsx(styles.radio)}>
        <input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          value={props.value}
          ref={ref}
          disabled={disabled ?? props.disabled}
          onChange={onChange}
        />
        <span className={clsx(styles.radioDummy)} />
      </span>
      {props.children}
    </label>
  )
})
