import React from 'react'
import { useTranslation } from 'react-i18next'

import { ItemText } from './ItemText'

import { formatJstDate } from '../../../utils'
import { Col, Container, Row } from '../../basics'

type ConfirmationCodeProps = {
  confirmationCode?: string
  bookedAt?: string
}

const translateNamespace = `components.ReservationDetail`

export const ConfirmationCode: React.FC<ConfirmationCodeProps> = ({
  confirmationCode,
  bookedAt,
}) => {
  const { t, i18n } = useTranslation()

  return (
    <Container>
      <Row>
        <Col pc={3} sp={6}>
          <ItemText>{t(`${translateNamespace}.reservationCode`)}</ItemText>
        </Col>
        <Col pc={3} sp={6}>
          <ItemText>{confirmationCode}</ItemText>
        </Col>
      </Row>
      <Row>
        <Col pc={3} sp={6}>
          <ItemText>{t(`${translateNamespace}.reservationDate`)}</ItemText>
        </Col>
        <Col pc={3} sp={6}>
          <ItemText>
            {bookedAt &&
              formatJstDate(
                bookedAt,
                i18n.language === 'ja'
                  ? 'yyyy/MM/dd (E) HH:mm'
                  : 'MM/dd/yyyy HH:mm',
                i18n.language
              )}
          </ItemText>
        </Col>
      </Row>
    </Container>
  )
}
