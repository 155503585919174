import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { isAxiosError } from 'axios'

import { useSearchQueryParams } from '../useSearchParams'
import { useTokenCheckApi } from '../useTokenCheckApi'

const LOGIN_URL = process.env.VITE_OPENID_CONNECT_URL

export const useLogin = () => {
  const { params } = useSearchQueryParams()
  const location = useLocation()

  const token = useMemo(() => {
    try {
      return sessionStorage.getItem(`access_token`) ?? ''
    } catch (e) {
      return ''
    }
  }, [])
  const { trigger: tokenCheck } = useTokenCheckApi(token)

  const validateToken = useCallback(async () => {
    if (token === '') {
      return false
    }

    try {
      const result = await tokenCheck()
      return result !== undefined && result.valid !== undefined && result.valid
    } catch (e) {
      return false
    }
  }, [token, tokenCheck])

  const onLogin = useCallback(
    (next?: string) => {
      try {
        sessionStorage.setItem(`query.next`, next || location.pathname)
        Object.entries(params).forEach(([key, value]) => {
          sessionStorage.setItem(`query.${key}`, value.toLocaleString())
        })
      } catch (e) {
        // TODO: Error handling
      }

      if (LOGIN_URL) {
        window.location.assign(LOGIN_URL)
      }
    },
    [params, location]
  )

  const onValidateTokenExpired = useCallback(
    (error: unknown) => {
      if (isAxiosError(error)) {
        if (error.response?.status && error.response.status === 401) {
          // API response token expired
          onLogin()
        }
      }
    },
    [onLogin]
  )

  return {
    onLogin,
    onValidateTokenExpired,
    validateToken,
  }
}
