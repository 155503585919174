import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './ReservationDetailFrame.module.scss'

import {
  ButtonWrapper,
  CancelPolicyDetailList,
  ContactDetailList,
  GuestDetailList,
  Loader,
  MainColumn,
  MultiColumn,
  NoteDetailList,
  ReservationDetailList,
  ReservationLayout,
  ReservationPanel,
  SideColumn,
} from '../../components'
import {
  useAuthProvider,
  useCreateNextPath,
  useLogin,
  usePublication,
  useReservationApi,
  useReservationPath,
} from '../../hooks'

const translateNamespace = `frames.ReservationDetailFrame`

export const ReservationDetailFrame = () => {
  const { t, i18n } = useTranslation()
  const { name, token } = useAuthProvider()
  const navigate = useNavigate()
  const { onValidateTokenExpired } = useLogin()
  const { params } = useCreateNextPath()
  const reservationId = params.reservation
  const { reservation, error, isLoading } = useReservationApi({
    id: reservationId ?? '',
    token,
  })
  const hotelPublication = usePublication(
    i18n.language,
    reservation?.transaction?.input?.bookingEngineHotel?.publications
  )
  const reservationPath = useReservationPath()

  useEffect(() => {
    onValidateTokenExpired(error)
  }, [error, onValidateTokenExpired])

  if (isLoading) {
    return <Loader />
  }

  if (!reservation) {
    navigate(reservationPath.root)
    return null
  }

  const cancelPolicy =
    reservation?.transaction?.input?.cancelPolicy ?? undefined
  const isHotel = reservation?.transaction?.input?.bookingEngineHotel?.isHotel

  return (
    <ReservationLayout
      pageTitle={t(`${translateNamespace}.pageTitle`)}
      reservationName={name}
    >
      <MultiColumn isSpColumnReverse>
        <MainColumn>
          <ReservationDetailList {...reservation} />
          <GuestDetailList {...reservation.transaction?.input} />
          {hotelPublication?.notice && (
            <NoteDetailList note={hotelPublication.notice} />
          )}
          {hotelPublication?.contact && (
            <ContactDetailList contact={hotelPublication.contact} />
          )}
          <CancelPolicyDetailList
            cancelPolicy={cancelPolicy}
            isCancelled={reservation.isCancelled}
          />
        </MainColumn>
        <SideColumn isSticky>
          <ReservationPanel
            isHotel={isHotel}
            reservationId={reservationId}
            checkInDate={reservation?.transaction?.input?.checkInDate}
            checkOutDate={reservation?.transaction?.input?.checkOutDate}
            numberOfAdults={reservation?.transaction?.input?.numberOfAdults}
            numberOfChildren={reservation?.transaction?.input?.numberOfChildren}
            numberOfInfants={reservation?.transaction?.input?.numberOfInfants}
            plan={reservation?.transaction?.input?.bookingEnginePlan?.slug}
            property={reservation?.transaction?.input?.bookingEngineHotel?.slug}
            room={reservation?.transaction?.input?.bookingEngineRoom?.slug}
            roomCount={reservation?.transaction?.input?.roomCount}
            qrLink={reservation?.qrCodeListUrl}
            isCancelled={reservation?.isCancelled}
            advancedCheckedInGuestUrl={reservation?.advancedCheckedInGuestUrl}
            transactionId={reservation.transaction?.id}
          />
        </SideColumn>
      </MultiColumn>
      <div className={styles.backButton}>
        <ButtonWrapper
          position="center"
          htmlType="button"
          size="large"
          variant="primary"
          onClick={() => {
            navigate(reservationPath.root)
          }}
        >
          {t(`${translateNamespace}.backToReservations`)}
        </ButtonWrapper>
      </div>
    </ReservationLayout>
  )
}
