import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UseRoomsApiProps = {
  property?: string
  room?: string
}

export const useRoomApi = ({ property, room }: UseRoomsApiProps) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property && room ? `/hotels/${property}/rooms?/${room}` : null,
    property && room
      ? () =>
          propertyApi.getRoom(property, room, options).then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
