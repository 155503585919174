import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { authApi, options } from '../../apis/suitebookClient'

const OAUTH_PROVIDER = process.env.VITE_OAUTH_PROVIDER || 'fid'

export const useAuthTokenApi = (
  code: string | null,
  redirectUri: string | null
) => {
  const { authTokenApi, key } =
    OAUTH_PROVIDER === 'google'
      ? { authTokenApi: authApi.authTokenGoogle, key: '/token/google' }
      : { authTokenApi: authApi.authTokenFid, key: '/token/fid' }

  const getToken = useCallback(
    async () =>
      authTokenApi(
        { code: code ?? '', redirectUri: redirectUri ?? '' },
        options
      ).then((res) => res.data),
    [code, authTokenApi, redirectUri]
  )

  const { trigger } = useSWRMutation(code ? key : null, getToken)

  return {
    trigger,
  }
}
