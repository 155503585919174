import { ReactElement, Ref, forwardRef } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'

import { Select, SelectProps } from './Select'

type FormSelectProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & SelectProps

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _FormSelect = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormSelectProps<TFieldValues, TName>,
  ref?: Ref<HTMLSelectElement>
) => {
  const {
    field: { name, onChange, value },
  } = useController(props)
  const { children, label, disabled, required } = props

  return (
    <Select
      required={required}
      value={value}
      onChange={(e) => {
        onChange(e)
        props.onChange?.(e)
      }}
      name={name}
      ref={ref}
      label={label}
      disabled={disabled}
    >
      {children}
    </Select>
  )
}

export const FormSelect = forwardRef(_FormSelect) as <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormSelectProps<TFieldValues, TName> & {
    ref?: Ref<HTMLSelectElement>
  }
) => ReactElement
