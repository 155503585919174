import * as styles from './Stepper.module.scss'

import { Stepper } from '../../basics'

type StepperProps = {
  current: number
  stepName: string
}

export const RegistrationStepper: React.FC<StepperProps> = ({
  current,
  stepName,
}) => (
  <div className={styles.registration}>
    <Stepper steps={3} current={current} color="blue" stepName={stepName} />
  </div>
)

export const CancellationStepper: React.FC<StepperProps> = ({
  current,
  stepName,
}) => (
  <div className={styles.cancellation}>
    <Stepper steps={2} current={current} color="red" stepName={stepName} />
  </div>
)
