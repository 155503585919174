import React from 'react'

import clsx from 'clsx'

import { BaseLayoutProps, Layout } from './Layout'
import * as styles from './Layout.module.scss'

import { useNotificationsApi } from '../../../hooks'
import { Notification, PageTitle } from '../../contents'

export type ReservationLayoutProps = BaseLayoutProps & {
  pageTitle: string
  reservationName?: string
}

export const ReservationLayout: React.FC<ReservationLayoutProps> = ({
  pageTitle,
  reservationName,
  children,
}) => {
  const { data: notifications } = useNotificationsApi()

  return (
    <Layout>
      <div>
        <PageTitle title={pageTitle} reservationName={reservationName} />
      </div>
      {notifications && (
        <div
          className={clsx(styles.notificationWrapper, styles.reservationLayout)}
        >
          <Notification notifications={notifications} />
        </div>
      )}
      <div className={clsx(styles.reservation)}>{children}</div>
    </Layout>
  )
}
