import { useTranslation } from 'react-i18next'

import { Layout, NotFound } from '../../components'

const translateNamespace = 'frames.ErrorFrame.500'

export const ServerErrorFrame = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <NotFound
        title="500 Internal Server Error"
        description1={t(`${translateNamespace}.description1`)}
        description2={t(`${translateNamespace}.description2`)}
      />
    </Layout>
  )
}
