import { stringify } from 'querystring'

import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UseRoomsApiProps = {
  property?: string
  checkInDate: string
  checkOutDate: string
  numberOfAdults: number
  numberOfChildren: number
  numberOfInfants: number
  roomCount: number
  roomOrder?: string
  showAllRooms?: boolean
}

type OrderProps = 'min_price' | '-min_price'

const isOrder = (value?: string): value is OrderProps => {
  switch (value) {
    case 'min_price':
    case '-min_price':
      return true
    default:
      return false
  }
}

export const useRoomsApi = ({
  property,
  checkInDate,
  checkOutDate,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  roomCount,
  roomOrder = 'min_price',
  showAllRooms = false,
}: UseRoomsApiProps) => {
  const params = {
    checkInDate,
    checkOutDate,
    roomCount,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomOrder,
    showAllRooms,
  }
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property ? `/hotels/${property}/rooms?${stringify(params)}` : null,
    property
      ? () =>
          propertyApi
            .listRooms(
              property,
              checkInDate,
              checkOutDate,
              roomCount,
              numberOfAdults,
              numberOfChildren,
              numberOfInfants,
              isOrder(roomOrder) ? roomOrder : 'min_price',
              showAllRooms,
              options
            )
            .then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
