import useSWR from 'swr'

import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

type UseReservationsRecentApiProps = {
  token: string
  isHotel: boolean
  isSpa: boolean
  isActivity: boolean
}

export const useReservationsRecentApi = ({
  token,
  isHotel,
  isSpa,
  isActivity,
}: UseReservationsRecentApiProps) => {
  const options = tokenOptions(token)
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    token ? `/reservations/recent` : undefined,
    token
      ? () =>
          reservationApi
            .getReservationsRecent(
              undefined, // offset
              undefined, // limit
              undefined, // checkInDateFrom
              undefined, // checkInDateTo
              undefined, // checkOutDateFrom
              undefined, // checkOutDateTo
              false, // isCanceled
              undefined, // order
              isHotel,
              isSpa,
              isActivity,
              options // options,
            )
            .then((res) => res.data)
      : null,
    {
      revalidateOnFocus: false,
    }
  )
  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
