import React from 'react'

import clsx from 'clsx'

import { Layout } from './Layout'
import * as styles from './Layout.module.scss'
import { ReservationLayoutProps } from './ReservationLayout'

import { useNotificationsApi } from '../../../hooks'
import { Notification, PageTitle } from '../../contents'

type ReservationHistoryLayoutProps = ReservationLayoutProps

export const ReservationHistoryLayout: React.FC<
  ReservationHistoryLayoutProps
> = ({ pageTitle, reservationName, children }) => {
  const { data: notifications } = useNotificationsApi()
  return (
    <Layout isPcBgGray>
      {notifications && (
        <div
          className={clsx(
            styles.notificationWrapper,
            styles.reservationHistoryLayout
          )}
        >
          <Notification notifications={notifications} />
        </div>
      )}
      <div className={styles.largePageTitle}>
        <PageTitle title={pageTitle} reservationName={reservationName} />
      </div>
      <div className={clsx(styles.reservationHistory)}>{children}</div>
    </Layout>
  )
}

type ReservationHistoryMainContentProps = {
  children: React.ReactNode
}

export const ReservationHistoryMainContent: React.FC<
  ReservationHistoryMainContentProps
> = ({ children }) => <div className={styles.main}>{children}</div>

type ReservationHistoryBottomContentProps = {
  children: React.ReactNode
}

export const ReservationHistoryBottomContent: React.FC<
  ReservationHistoryBottomContentProps
> = ({ children }) => <div className={styles.bottom}>{children}</div>
