import React from 'react'

import { LogoImage } from './LogoImage'
import * as styles from './LogoNav.module.scss'

type LogoNavProps = {
  isMenuOpen: boolean
}

export const LogoNav: React.FC<LogoNavProps> = ({ isMenuOpen }) => (
  <div className={styles.headerLogoWrapper}>
    <figure className={styles.headerLogo}>
      <a href="https://www.hkdballpark.com/">
        <LogoImage color={isMenuOpen ? styles.textWhite : styles.textBlack} />
      </a>
    </figure>
  </div>
)
