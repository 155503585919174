import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './TranslateModal.module.scss'

import { SUPPORTED_LANGUAGES } from '../../../constants'
import { Col, Container, Modal, ModalProps, Row, Text } from '../../basics'

const translateNamespace = 'components.TranslateModal'

type TranslateModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>

export const TranslateModal: React.FC<TranslateModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t, i18n } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Text>{t(`${translateNamespace}.selectLanguage`)}</Text>}
      subTitle={<Text>{t(`${translateNamespace}.selectLanguageSub`)}</Text>}
    >
      <div className={styles.languages}>
        <Container>
          <Row>
            {SUPPORTED_LANGUAGES.map((language) => (
              <Col
                sp={12}
                pc={6}
                className={styles.translateCol}
                key={`translateModal-${language}`}
              >
                <button
                  className={clsx(
                    styles.langButton,
                    i18n.language === language && styles.langButtonSelected
                  )}
                  type="button"
                  onClick={async () => {
                    await i18n.changeLanguage(language)
                  }}
                >
                  <Text inline weight="bold" size={18}>
                    <div className={styles.languageName}>
                      {t(`${translateNamespace}.language.${language}`)}
                    </div>
                  </Text>
                </button>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Modal>
  )
}
