import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './Header.module.scss'
import * as animationStyles from './HeaderAnimation.module.scss'
import { LanguageNav } from './LanguageNav'
import { LogoNav } from './LogoNav'
import { Menu } from './Menu'
import { MenuNav } from './MenuNav'
import { TopNav } from './TopNav'

import { EXTERNAL_PATH, PATH } from '../../../constants'
import { usePropertyApi, useRouteParams } from '../../../hooks'

export const useHeaderAnimationClass = () => {
  const [lastPosition, setLastPosition] = useState(0)
  const [animationClass, setAnimationClass] = useState('')

  const scrollEvent = useCallback(() => {
    if (window.scrollY > 0) {
      setAnimationClass(animationStyles.hide)
    }

    setLastPosition(window.scrollY)

    if (window.scrollY < lastPosition) {
      setAnimationClass(animationStyles.showFilled)
    }
  }, [lastPosition])

  return useMemo(
    () => ({
      animationClass,
      scrollEvent,
    }),
    [animationClass, scrollEvent]
  )
}

const usePathRoot = () => {
  const location = useLocation()
  const { property: propertySlug } = useRouteParams()
  const { data: property } = usePropertyApi(propertySlug)

  return useMemo(() => {
    if (location.pathname.match(/^\/spas\//)) {
      return { path: EXTERNAL_PATH.spas, isHotel: false }
    }
    if (location.pathname.match(/^\/activities\//)) {
      return { path: EXTERNAL_PATH.activities, isHotel: false }
    }

    if (!property) {
      return { path: PATH.root, isHotel: true }
    }

    if (property.isHotel === false) {
      return property?.officialUrl
        ? { path: property.officialUrl, isHotel: false }
        : { path: PATH.root, isHotel: true }
    }

    return { path: PATH.root, isHotel: true }
  }, [location.pathname, property])
}

export const Header: React.FC = () => {
  const { animationClass, scrollEvent } = useHeaderAnimationClass()

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent)

    return () => {
      window.removeEventListener('scroll', scrollEvent)
    }
  }, [scrollEvent])

  const [isMenuOpen, setMenuOpen] = useState(false)

  const { isHotel } = usePathRoot()

  // Hack for Safari.
  // If set `.headerContents { visibility: hidden }` to css,
  // Safari hide menu item after menu was opened.
  // If remove visibility: hidden, overlayed menu still remains
  // and won't click any other content.
  // Force visible when menu was opened.
  const visibility: React.CSSProperties | undefined = isMenuOpen
    ? { visibility: 'visible' }
    : undefined

  return (
    <header className={clsx(styles.header, animationClass)}>
      <div className={clsx(styles.headerTopInner)}>
        <LogoNav isMenuOpen={isMenuOpen} />
        <div className={isMenuOpen ? styles.menuContents : undefined}>
          <div className="sp">
            <LanguageNav isMenuOpen={isMenuOpen} />
            {isHotel && <TopNav isMenuOpen={isMenuOpen} />}
            <MenuNav onToggle={(isOpen) => setMenuOpen(!isOpen)} />
          </div>

          <div className={styles.headerContents} style={visibility}>
            <div
              className={clsx(
                styles.headerContentsInner,
                styles.hiddenContentInner
              )}
            >
              <div className={styles.headerOrderNav}>
                <Menu isHotel={isHotel} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
