import { useTranslation } from 'react-i18next'

import { Layout, NotFound } from '../../components'

const translateNamespace = 'frames.ErrorFrame.login'

export const LoginErrorFrame = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <NotFound
        title="Login failed"
        description1={t(`${translateNamespace}.description1`)}
        description2={t(`${translateNamespace}.description2`)}
      />
    </Layout>
  )
}
