import * as styles from './PropertyCover.module.scss'

import { WhiteText } from '../../../basics'

type PropertyCoverProps = {
  image: string
  name: string
  desc: React.ReactNode
}

export const PropertyCover: React.FC<PropertyCoverProps> = ({
  image,
  name,
  desc,
}) => (
  <div
    className={styles.propertyCover}
    style={{ backgroundImage: `url(${image})` }}
  >
    <div className={styles.text}>
      <WhiteText size={22} weight="bold" align="center">
        {name}
      </WhiteText>
      <div className={styles.desc}>
        <WhiteText size={14} align="center">
          {desc}
        </WhiteText>
      </div>
    </div>
  </div>
)
