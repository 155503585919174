import { useMemo } from 'react'

const availabilities = {
  few: process.env.VITE_SUITEBOOK_AVAILABILITY_FEW ?? 50,
  occupied: process.env.VITE_SUITEBOOK_AVAILABILITY_OCCUPIED ?? 0,
}

type Availability = 'vacant' | 'few' | 'occupied' | 'notAvailable'

export const useAvailability = (availableRate: number | null): Availability =>
  useMemo(() => {
    if (availableRate == null) {
      return 'notAvailable'
    }

    if (availableRate > Number(availabilities.few)) {
      return 'vacant'
    }

    if (availableRate > Number(availabilities.occupied)) {
      return 'few'
    }

    return 'occupied'
  }, [availableRate])
