import { useTranslation } from 'react-i18next'

import { clsx } from 'clsx'

import * as styles from './PageTitle.module.scss'

import { Flex, FlexBox, WhiteText as Text } from '../../basics'

const translateNamespace = `components.PageTitle`

type PageTitleProps = {
  title: string
  reservationName?: string
  optionClass?: string
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  reservationName,
  optionClass,
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.pageTitle, optionClass)}>
      <Text align="center" size={22} weight="bold">
        {title}
      </Text>
      {reservationName && (
        <Flex optionClass={styles.reservationName}>
          <FlexBox>
            <div className={styles.circleIcon} />
          </FlexBox>
          <FlexBox>
            <Text align="center">
              {reservationName} {t(`${translateNamespace}.honorific`)}
            </Text>
          </FlexBox>
        </Flex>
      )}
    </div>
  )
}
