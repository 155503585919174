import { useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { isAxiosError } from 'axios'

import { Loader, Text } from '../../components'
import { PATH, REGISTRATION_PATH } from '../../constants'
import {
  useAuthProvider,
  useCreateNextPath,
  useLogin,
  usePropertyProvider,
  useRegistrationState,
  useReservationState,
  useReservationsApi,
  useSearchQueryParams,
} from '../../hooks'

const translateNamespace = 'frames.RegistrationLoadingFrame'

const retryDuration = 1000 * 10
const retryLimit = 6

export const RegistrationLoadingFrame = () => {
  const retryCount = useRef(0)
  const { token } = useAuthProvider()
  const { onValidateTokenExpired } = useLogin()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const { onClearRegistrationStateByKey } = useRegistrationState()
  const { onUpdateReservationState } = useReservationState()
  const { params } = useSearchQueryParams()
  const { isHotel } = usePropertyProvider()
  const { reservations, error } = useReservationsApi({
    token,
    transaction: params.transaction,
    isHotel,
    isSpa: !isHotel,
    swrOptions: {
      refreshInterval: (data) => {
        if (retryCount.current >= retryLimit || (data?.count ?? 0) > 0) {
          return 0
        }
        return retryDuration
      },
      onSuccess: () => (retryCount.current += 1),
    },
  })

  useEffect(() => {
    if (isAxiosError(error)) {
      onValidateTokenExpired(error)
      navigate(PATH.root)
    }
  }, [error, navigate, onValidateTokenExpired])

  useEffect(() => {
    if (reservations.length) {
      const reservation = reservations[0]
      onClearRegistrationStateByKey('specialServiceRequest')
      onUpdateReservationState({
        confirmationCode: reservation.confirmationCode,
        reservationId: reservation.id?.toString(),
      })
      navigate(onCreateNextPath(REGISTRATION_PATH.completion))
    }

    if (retryCount.current >= retryLimit) {
      navigate(onCreateNextPath(REGISTRATION_PATH.registrations))
    }
  }, [
    navigate,
    onClearRegistrationStateByKey,
    onCreateNextPath,
    onUpdateReservationState,
    reservations,
  ])

  return (
    <Loader>
      <Text align="center">
        <Trans i18nKey={`${translateNamespace}.loading`} />
      </Text>
    </Loader>
  )
}
