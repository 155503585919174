import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './ErrorCard.module.scss'

import { ModelErrorResponseErrorCodeEnum as ErrorEnum } from '../../../apis/fvillage/api'
import { Icon, Text } from '../../basics'

type ErrorCardProps = {
  errorMessage?: string
  statusCode?: ErrorEnum | 'default'
  warning?: boolean
  children?: React.ReactNode
}

const translateNamespace = `apiError`

const useErrorMessage = (statusCode: ErrorCardProps['statusCode']) => {
  const { t } = useTranslation()
  if (statusCode && statusCode !== 'default') {
    // prettier-ignore
    switch (Number(statusCode)) {
      case ErrorEnum.AuthError:                                 // 1001
      case ErrorEnum.OperationNotAllowedError:                  // 1002
      case ErrorEnum.BadRequestError:                           // 1003
      case ErrorEnum.NotFoundError:                             // 1004
      case ErrorEnum.InternalServerError:                       // 1005
      case ErrorEnum.MethodNotAllowedError:                     // 1006
      case ErrorEnum.RoomNotForSaleError:                       // 2001
      case ErrorEnum.PlanNotForSaleError:                       // 2002
      case ErrorEnum.NoStockError:                              // 2003
      case ErrorEnum.DiscountCodeInvalidError:                  // 2004
      case ErrorEnum.OptionItemNotForSaleError:                 // 2005
      case ErrorEnum.CannotCancelError:                         // 2006
      case ErrorEnum.TicketNotForSaleError:                     // 2007
      case ErrorEnum.SoldOutError:                              // 2008
      case ErrorEnum.KitahiroshimaDiscountCodeInvalidError:     // 2009
      case ErrorEnum.NeedToContactTheStoreError:                // 2101
      case ErrorEnum.AuthorizationErrorCausedByCreditCardError: // 2102
      case ErrorEnum.RetryAfterCheckingInputError:              // 2103
      case ErrorEnum.RequiringRetryError:                       // 2104
      case ErrorEnum.ResponseTimeoutError:                      // 2105
        return {
          description: t(`${translateNamespace}.${statusCode}.description`),
          direction: t(`${translateNamespace}.${statusCode}.direction`),
        }
      default:
      // Fall through
    }
  }
  return {
    description: t(`${translateNamespace}.default.description`),
    direction: t(`${translateNamespace}.default.direction`),
  }
}

export const ErrorCard: React.FC<ErrorCardProps> = ({
  errorMessage,
  statusCode,
  warning = false,
  children,
}) => {
  const { t } = useTranslation()
  const { description, direction } = useErrorMessage(statusCode)

  return (
    <div className={clsx(styles.cards, warning && styles.warning)}>
      {statusCode && (
        <React.Fragment>
          <span className={styles.errorTitle}>
            <span className={styles.iconWrapper}>
              <Icon mark="error" />
            </span>
            <span>
              <Text weight="bold" size={18}>
                {errorMessage}{' '}
                {t(`${translateNamespace}.statusCode`, { statusCode })}
              </Text>
            </span>
          </span>
          <Text size={16}>{description}</Text>
          <Text size={16}>{direction}</Text>
        </React.Fragment>
      )}
      {children}
    </div>
  )
}
