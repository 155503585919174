import useSWR from 'swr'

import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

type UseReservationApiProps = {
  id: string | number
  token: string
}

export const useReservationApi = ({ id, token }: UseReservationApiProps) => {
  const options = tokenOptions(token)

  const { data, isLoading, isValidating, error, mutate } = useSWR(
    `/reservations/${id}`,
    () =>
      reservationApi
        .getReservation(Number(id), options)
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    reservation: data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
