import React from 'react'
import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './PlanDetailModal.module.scss'

import { ImageResponse } from '../../../apis/fvillage'
import { useImages } from '../../../hooks'
import { Icon, Modal, SlideShow } from '../../basics'

type PlanDetailModalProps = {
  description: string
  content: string
  checkIn: string
  checkOut: string
  images: ImageResponse[] | undefined
  isOpen: boolean
  onClose: () => void
  planName: string
  meal: string | null
  withMeal: string
  isHotel: boolean
}

const translateNamespace = 'components.PlanDetailModal'

export const PlanDetailModal: React.FC<PlanDetailModalProps> = ({
  checkIn,
  checkOut,
  description,
  content,
  images,
  isOpen,
  onClose,
  planName,
  meal,
  withMeal,
  isHotel,
}) => {
  const items = useImages(images)
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        content: {
          maxWidth: 'calc(1100px + 8rem)',
        },
      }}
    >
      <div style={{ padding: '1rem' }}>
        <div className={styles.informationHead}>
          <div className={styles.informationCol}>
            <SlideShow
              items={items}
              renderItem={(item) => (
                <figure>
                  <img
                    src={item.pc.src}
                    alt={item.alt}
                    className="pc"
                    width="600"
                    height="400"
                  />
                  <img
                    src={item.sp.src}
                    alt={item.alt}
                    className="sp"
                    width="600"
                    height="400"
                  />
                </figure>
              )}
            />
            <div className={styles.informationHeadContent}>
              <h4 className={styles.informationName}>{planName}</h4>
              {isHotel && (
                <div className={styles.informationSummary}>
                  <Icon mark="time">
                    {t(`${translateNamespace}.checkIn`)}: {checkIn}
                  </Icon>
                  <Icon mark="time">
                    {t(`${translateNamespace}.checkOut`)}: ~ {checkOut}
                  </Icon>
                  <Icon mark="meal">
                    {withMeal} {withMeal && meal && <span> （{meal}）</span>}
                  </Icon>
                </div>
              )}
              <div className={styles.informationText}>{description}</div>
            </div>
          </div>
        </div>
        <div
          className={styles.informationBody}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
        />
      </div>
    </Modal>
  )
}
