import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MealEnum } from '../../constants'

export const useMeal = (meals: number | null) => {
  const { t } = useTranslation()

  const meal = useMemo(() => {
    switch (meals) {
      case MealEnum.Breakfast:
        return t(`hooks.useMeal.breakfast`)
      case MealEnum.Lunch:
        return t(`hooks.useMeal.lunch`)
      case MealEnum.BreakfastLunch:
        return t(`hooks.useMeal.breakfastLunch`)
      case MealEnum.Supper:
        return t(`hooks.useMeal.supper`)
      case MealEnum.BreakfastSupper:
        return t(`hooks.useMeal.breakfastSupper`)
      case MealEnum.LunchSupper:
        return t(`hooks.useMeal.lunchSupper`)
      case MealEnum.BreakfastLunchSupper:
        return t(`hooks.useMeal.breakfastLunchSupper`)
      default:
        return null
    }
  }, [meals, t])

  if (meal) {
    return {
      meal,
      withMeal: t(`hooks.useMeal.withMeal`),
    }
  }

  return {
    meal: null,
    withMeal: t(`hooks.useMeal.withoutMeal`),
  }
}
