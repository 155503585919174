import { useMemo } from 'react'

import {
  BookingEngineHotelPublication,
  BookingEngineRoomPublication,
  PlanPublication,
  PublicationResponse,
} from '../../apis/fvillage'

const pattern = /^en+/

export const getPublication = <
  T extends
    | PublicationResponse
    | BookingEngineHotelPublication
    | BookingEngineRoomPublication
    | PlanPublication
>(
  language: string,
  publications: Array<T>
) => {
  const publication = publications.find(
    (item) => item?.locale?.toLowerCase() === language.toLowerCase()
  )
  if (publication) {
    return publication
  }

  // Fallback to english publication if current locale's publication not found.
  const englishPublication = publications.find((item) =>
    item?.locale ? pattern.test(item?.locale?.toLowerCase()) : false
  )
  if (englishPublication) {
    return englishPublication
  }

  // If current language and english publication not found,
  // fallback to first one.
  if (publications.length > 0) {
    return publications[0]
  }

  return undefined
}

export const usePublication = <
  T extends
    | PublicationResponse
    | BookingEngineHotelPublication
    | BookingEngineRoomPublication
    | PlanPublication
>(
  language: string,
  publications?: Array<T> | undefined
) =>
  useMemo(
    () => (publications ? getPublication(language, publications) : undefined),
    [language, publications]
  )
