import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { authApi, tokenOptions } from '../../apis/suitebookClient'

export const useLogoutApi = (token: string) => {
  const options = tokenOptions(token)
  const logout = useCallback(
    async () => authApi.logout({ token }, options).then((res) => res.data),
    [token, options]
  )
  const { trigger } = useSWRMutation('/logout', logout)

  return { trigger }
}
