import { stringify } from 'querystring'

import useSWR from 'swr'

import {
  propertyApi,
  reservationApi,
  tokenOptions,
} from '../../apis/suitebookClient'
import { DEFAULT_TRANSACTION_ID } from '../../constants'

type UsePriceApiProps = {
  token: string
  propertySlug: string
  roomSlug: string
  planSlug: string
  checkInDate: string
  checkOutDate: string
  roomCount: number
  numberOfAdults: number
  numberOfChildren: number
  numberOfInfants: number
  transactionId: number
  reservationId?: string | number | null
}

export const usePriceApi = ({
  token,
  propertySlug,
  roomSlug,
  planSlug,
  checkInDate,
  checkOutDate,
  roomCount,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  transactionId,
  reservationId,
}: UsePriceApiProps) => {
  const options = tokenOptions(token)
  const params = {
    checkInDate,
    checkOutDate,
    roomCount,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    transactionId,
  }

  let key: string | null = reservationId
    ? `/reservations/${reservationId}/price`
    : `/hotels/${propertySlug}/rooms/${roomSlug}/plans/${planSlug}/price?${stringify(
        params
      )}`

  if (transactionId === DEFAULT_TRANSACTION_ID) {
    key = null
  }

  const { data, isLoading, isValidating, error, mutate } = useSWR(
    key,
    reservationId
      ? () =>
          reservationApi
            .getReservationPrice(Number(reservationId), options)
            .then((res) => res.data)
      : () =>
          propertyApi
            .getPrice(
              propertySlug,
              roomSlug,
              planSlug,
              transactionId,
              checkInDate,
              checkOutDate,
              roomCount,
              numberOfAdults,
              numberOfChildren,
              numberOfInfants,
              options
            )
            .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
