import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { DetailList } from './DetailList'
import * as styles from './ReservationDetail.module.scss'

import { Text } from '../../basics'

const translateNamespace = `components.ReservationDetail`

export const ContactDetailList: React.FC<{ contact: string }> = ({
  contact,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.detailList}>
      <div className={clsx(styles.spacer, styles.contact)}>
        <DetailList
          header={t(`${translateNamespace}.contact`)}
          listItems={[
            {
              key: contact,
              items: [<Text key={contact}>{contact}</Text>],
            },
          ]}
        />
      </div>
    </div>
  )
}
