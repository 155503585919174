import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import * as styles from './AddressForm.module.scss'

import { useKenallApi } from '../../../../hooks'
import { Registration } from '../../../../schemas'
import {
  Button,
  Col,
  Container,
  ErrorMessage,
  FormInput,
  FormSelect,
  Option,
} from '../../../basics'
import { FormRow } from '../FormRow'

const translateNamespace = `components.AddressForm.prefectures`

export const AddressForm = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<Registration>()

  const { data, onTrigger } = useKenallApi()

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const { prefecture, city, town, koaza } = data[0]
      const street = koaza ? `${town}${koaza}` : town
      setValue('prefecture', prefecture, { shouldValidate: true })
      setValue('city', city, { shouldValidate: true })

      // If the inputted value is incorrect, the street could empty.
      if (street !== '') {
        setValue('street', street, { shouldValidate: true })
      }
    }
  }, [data, setValue])

  return (
    <Container>
      <FormRow align="center">
        <Col sp={9} pc={9}>
          <FormInput
            control={control}
            name="postalCode"
            required
            inputMode="numeric"
            label={t(`components.AddressForm.label.postalCode`)}
            placeholder={t(`components.AddressForm.label.postalCode`)}
            onChange={(e) => {
              onTrigger(e.target.value)
            }}
            maxLength={8}
          />
          <ErrorMessage errors={errors} name="postalCode" />
        </Col>
        <Col sp={3} pc={3} className={styles.btn}>
          <Button
            htmlType="button"
            size="small"
            variant="primary"
            onClick={() => {
              onTrigger(getValues('postalCode'))
            }}
          >
            {t(`components.AddressForm.label.search`)}
          </Button>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormSelect
            control={control}
            name="prefecture"
            required
            label={t(`components.AddressForm.label.prefecture`)}
          >
            <Option key="hokkaido" value="北海道">
              {t(`${translateNamespace}.hokkaido`)}
            </Option>
            <Option key="aomori" value="青森県">
              {t(`${translateNamespace}.aomori`)}
            </Option>
            <Option key="iwate" value="岩手県">
              {t(`${translateNamespace}.iwate`)}
            </Option>
            <Option key="miyagi" value="宮城県">
              {t(`${translateNamespace}.miyagi`)}
            </Option>
            <Option key="akita" value="秋田県">
              {t(`${translateNamespace}.akita`)}
            </Option>
            <Option key="yamagata" value="山形県">
              {t(`${translateNamespace}.yamagata`)}
            </Option>
            <Option key="fukushima" value="福島県">
              {t(`${translateNamespace}.fukushima`)}
            </Option>
            <Option key="ibaraki" value="茨城県">
              {t(`${translateNamespace}.ibaraki`)}
            </Option>
            <Option key="tochigi" value="栃木県">
              {t(`${translateNamespace}.tochigi`)}
            </Option>
            <Option key="gunma" value="群馬県">
              {t(`${translateNamespace}.gunma`)}
            </Option>
            <Option key="saitama" value="埼玉県">
              {t(`${translateNamespace}.saitama`)}
            </Option>
            <Option key="chiba" value="千葉県">
              {t(`${translateNamespace}.chiba`)}
            </Option>
            <Option key="tokyo" value="東京都">
              {t(`${translateNamespace}.tokyo`)}
            </Option>
            <Option key="kanagawa" value="神奈川県">
              {t(`${translateNamespace}.kanagawa`)}
            </Option>
            <Option key="niigata" value="新潟県">
              {t(`${translateNamespace}.niigata`)}
            </Option>
            <Option key="toyama" value="富山県">
              {t(`${translateNamespace}.toyama`)}
            </Option>
            <Option key="ishikawa" value="石川県">
              {t(`${translateNamespace}.ishikawa`)}
            </Option>
            <Option key="fukui" value="福井県">
              {t(`${translateNamespace}.fukui`)}
            </Option>
            <Option key="yamanashi" value="山梨県">
              {t(`${translateNamespace}.yamanashi`)}
            </Option>
            <Option key="nagano" value="長野県">
              {t(`${translateNamespace}.nagano`)}
            </Option>
            <Option key="gifu" value="岐阜県">
              {t(`${translateNamespace}.gifu`)}
            </Option>
            <Option key="shizuoka" value="静岡県">
              {t(`${translateNamespace}.shizuoka`)}
            </Option>
            <Option key="aichi" value="愛知県">
              {t(`${translateNamespace}.aichi`)}
            </Option>
            <Option key="mie" value="三重県">
              {t(`${translateNamespace}.mie`)}
            </Option>
            <Option key="shiga" value="滋賀県">
              {t(`${translateNamespace}.shiga`)}
            </Option>
            <Option key="kyoto" value="京都府">
              {t(`${translateNamespace}.kyoto`)}
            </Option>
            <Option key="osaka" value="大阪府">
              {t(`${translateNamespace}.osaka`)}
            </Option>
            <Option key="hyogo" value="兵庫県">
              {t(`${translateNamespace}.hyogo`)}
            </Option>
            <Option key="nara" value="奈良県">
              {t(`${translateNamespace}.nara`)}
            </Option>
            <Option key="wakayama" value="和歌山県">
              {t(`${translateNamespace}.wakayama`)}
            </Option>
            <Option key="totori" value="鳥取県">
              {t(`${translateNamespace}.totori`)}
            </Option>
            <Option key="shimane" value="島根県">
              {t(`${translateNamespace}.shimane`)}
            </Option>
            <Option key="okayama" value="岡山県">
              {t(`${translateNamespace}.okayama`)}
            </Option>
            <Option key="hiroshima" value="広島県">
              {t(`${translateNamespace}.hiroshima`)}
            </Option>
            <Option key="yamaguchi" value="山口県">
              {t(`${translateNamespace}.yamaguchi`)}
            </Option>
            <Option key="tokushima" value="徳島県">
              {t(`${translateNamespace}.tokushima`)}
            </Option>
            <Option key="kagawa" value="香川県">
              {t(`${translateNamespace}.kagawa`)}
            </Option>
            <Option key="ehime" value="愛媛県">
              {t(`${translateNamespace}.ehime`)}
            </Option>
            <Option key="kochi" value="高知県">
              {t(`${translateNamespace}.kochi`)}
            </Option>
            <Option key="fukuoka" value="福岡県">
              {t(`${translateNamespace}.fukuoka`)}
            </Option>
            <Option key="saga" value="佐賀県">
              {t(`${translateNamespace}.saga`)}
            </Option>
            <Option key="nagasaki" value="長崎県">
              {t(`${translateNamespace}.nagasaki`)}
            </Option>
            <Option key="kumamoto" value="熊本県">
              {t(`${translateNamespace}.kumamoto`)}
            </Option>
            <Option key="oita" value="大分県">
              {t(`${translateNamespace}.oita`)}
            </Option>
            <Option key="miyazaki" value="宮崎県">
              {t(`${translateNamespace}.miyazaki`)}
            </Option>
            <Option key="kagoshima" value="鹿児島県">
              {t(`${translateNamespace}.kagoshima`)}
            </Option>
            <Option key="okinawa" value="沖縄県">
              {t(`${translateNamespace}.okinawa`)}
            </Option>
            <Option key="other" value="その他">
              {t(`${translateNamespace}.other`)}
            </Option>
          </FormSelect>
          <ErrorMessage errors={errors} name="prefecture" />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormInput
            control={control}
            name="city"
            required
            inputMode="text"
            label={t(`components.AddressForm.label.city`)}
            placeholder={t(`components.AddressForm.label.city`)}
          />
          <ErrorMessage errors={errors} name="city" />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormInput
            control={control}
            name="street"
            required
            inputMode="text"
            label={t(`components.AddressForm.label.street`)}
            placeholder={t(`components.AddressForm.label.street`)}
          />
          <ErrorMessage errors={errors} name="street" />
        </Col>
      </FormRow>
    </Container>
  )
}
