import { createContext, useContext } from 'react'

export type RadioGroupContextValue = {
  name?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const RadioContext = createContext<RadioGroupContextValue | null>(null)

export const useRadioProvider = () => {
  const context = useContext(RadioContext)
  if (!context) {
    throw new Error(
      `Radio compound components cannot be rendered outside the Radio component`
    )
  }
  return context
}
