import { stringify } from 'querystring'

import useSWR, { SWRConfiguration } from 'swr'

import { ReservationsResponse } from '../../apis/fvillage'
import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

type UseReservationsApiProps = {
  offset?: number
  checkOutDateFrom?: string
  checkOutDateTo?: string
  token: string
  order?:
    | 'created_at'
    | '-created_at'
    | 'check_in_date'
    | '-check_in_date'
    | 'check_out_date'
    | '-check_out_date'
  isHotel?: boolean
  isSpa?: boolean
  isActivity?: boolean
  transaction?: number
  swrOptions?: SWRConfiguration<ReservationsResponse>
}

export const useReservationsApi = ({
  offset = 0,
  checkOutDateTo,
  checkOutDateFrom,
  token,
  order,
  isHotel = false,
  isSpa = false,
  isActivity = false,
  transaction,
  swrOptions = {},
}: UseReservationsApiProps) => {
  const params = { offset, checkOutDateFrom, checkOutDateTo, order }
  const key = checkOutDateTo
    ? `/reservations/history?${stringify(params)}`
    : `/reservations/?${stringify(params)}`

  const options = tokenOptions(token)

  const { data, isLoading, isValidating, error, mutate } = useSWR(
    key,
    () =>
      // prettier-ignore
      reservationApi.getReservations(
        offset,
        undefined, // limit
        undefined, // checkInDateFrom
        undefined, // checkInDateTo
        checkOutDateFrom,
        checkOutDateTo,
        undefined, // isCanceled
        order,
        isHotel,
        isSpa,
        isActivity,
        transaction,
        options // options,
      ).then((res) => res.data),
    {
      revalidateOnFocus: false,
      ...swrOptions,
    }
  )

  return {
    reservations: data?.results ?? [],
    total: data?.count ?? 0,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
