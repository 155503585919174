import { useCallback } from 'react'

import axios from 'axios'
import useSWRMutation from 'swr/mutation'

import { UpdateTransactionInputRequest } from '../../apis/fvillage'
import { tokenOptions, transactionApi } from '../../apis/suitebookClient'

export const useUpdateTransactionInputApi = (token: string) => {
  const options = tokenOptions(token)

  const updateTransactionInput = useCallback(
    async (
      key: string,
      {
        arg,
      }: {
        arg: {
          transactionId: number
          input: UpdateTransactionInputRequest
        }
      }
    ) => {
      try {
        const { status } = await transactionApi
          .updateTransactionInput(arg.transactionId, arg.input, options)
          .then((res) => ({ status: res.status }))
        return { status }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          return { status: e?.response?.status ?? 500 }
        }
        return { status: 500 }
      }
    },
    [options]
  )

  const { trigger } = useSWRMutation(
    '/transactions/input',
    updateTransactionInput
  )

  return {
    trigger,
  }
}
