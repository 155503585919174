import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import * as styles from './AgreementForm.module.scss'

import { usePropertyProvider } from '../../../hooks'
import { Agreement } from '../../../schemas'
import { FormCheckbox } from '../../basics'

export const AgreementForm = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<Agreement>()
  const { isActivity } = usePropertyProvider()

  const links = isActivity ? (
    <Trans
      i18nKey="components.AgreementForm.agreementWithGame"
      t={t}
      components={{
        term: (
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            href="https://www.hkdballpark.com/terms/"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="term"
          />
        ),
        personal: (
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            href="https://www.fighters.co.jp/company/privacy/"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="privacy policy"
          />
        ),
        game: (
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            href="https://www.fighters.co.jp/gamelive/agreement/"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="privacy policy"
          />
        ),
      }}
    />
  ) : (
    <Trans
      i18nKey="components.AgreementForm.agreement"
      t={t}
      components={{
        term: (
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            href="https://www.hkdballpark.com/terms/"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="term"
          />
        ),
        personal: (
          <a // eslint-disable-line jsx-a11y/anchor-has-content
            href="https://www.fighters.co.jp/company/privacy/"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="privacy policy"
          />
        ),
      }}
    />
  )
  return <FormCheckbox control={control} name="agreement" label={links} />
}
