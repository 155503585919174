import { ReactElement, Ref, forwardRef } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'

import { Checkbox, CheckboxProps } from './Checkbox'

type FormCheckboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  Omit<CheckboxProps, 'checked'> & {
    checked?: boolean
  }

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _FormCheckbox = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormCheckboxProps<TFieldValues, TName>,
  ref?: Ref<HTMLInputElement>
) => {
  const {
    field: { name, onChange, value: checked = false },
  } = useController(props)
  const { label, disabled } = props

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      name={name}
      ref={ref}
      label={label}
      disabled={disabled}
    />
  )
}

export const FormCheckbox = forwardRef(_FormCheckbox) as <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormCheckboxProps<TFieldValues, TName> & {
    ref?: Ref<HTMLInputElement>
  }
) => ReactElement
