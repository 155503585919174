import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './SortResult.module.scss'

const translateNamespace = 'components.Sort'

type SortResultProps = {
  count: number
}

export const SortResult: React.FC<SortResultProps> = ({ count }) => {
  const { t } = useTranslation()
  return (
    <section className={styles.sortText}>
      <div className={styles.sortTextTop}>
        <p>
          <span className={styles.sortTextEn}>
            {t(`${translateNamespace}.label`)}：
          </span>
          {/* The strong tag isn't the b tag. */}
          {/* The strong tag is used for attach importance to a part of sentence */}
          <strong className={styles.sortTextStrong}>{count}</strong>
          <span className={styles.sortTextJp}>
            {t(`${translateNamespace}.items`)}
          </span>
        </p>
      </div>
    </section>
  )
}
