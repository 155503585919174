import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UsePropertiesApiProps = {
  isHotel?: boolean
}

export const usePropertiesApi = ({ isHotel = true }: UsePropertiesApiProps) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    `/hotels`,
    () => propertyApi.listProperties(isHotel, options).then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
