import { Trans } from 'react-i18next'

import { Section } from '../../basics'

const translateNamespace = 'frames.RegistrationCompletionFrame'

export const ThanksSection = () => (
  <Section>
    <Trans i18nKey={`${translateNamespace}.thanks`}>
      Your reservation application has been completed.
      <br />
      A confirmation email has been sent to your registered email address.
      <br />
      Please be sure to check your reservation details.
      <br />
      You can check or cancel your reservation from the URL in the email.
    </Trans>
  </Section>
)
