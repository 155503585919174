import React, { forwardRef, useMemo } from 'react'

import clsx from 'clsx'

import * as styles from './Button.module.scss'

type ButtonProps = {
  variant?: 'cta' | 'primary' | 'secondary' | 'tertiary' | 'danger'
  outline?: boolean
  bold?: boolean
  size?:
    | 'small'
    | 'smallWide'
    | 'medium'
    | 'footer'
    | 'xmedium'
    | 'large'
    | 'full'
  htmlType: 'button' | 'submit' | 'reset'
  display?: 'block' | 'inlineBlock'
  disabled?: boolean
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

type ButtonWrapperProps = Omit<ButtonProps, 'display'> & {
  position: 'right' | 'center' | 'left'
  style?: React.CSSProperties
}

type Outlined = 'cta' | 'primary' | 'secondary' | 'tertiary' | 'danger'

const availableOutline = {
  cta: 'outlinedCta',
  primary: 'outlinedPrimary',
  secondary: 'outlinedSecondary',
  tertiary: 'outlinedTertiary',
  danger: 'outlinedDanger',
}

const isOutlined = (variant: string): variant is Outlined => {
  switch (variant) {
    case 'cta':
    case 'primary':
    case 'secondary':
    case 'tertiary':
    case 'danger':
      return true
    default:
      return false
  }
}

const useStyle = ({
  outline,
  variant,
}: {
  outline?: boolean
  variant: string
}) =>
  useMemo(() => {
    if (outline && variant && isOutlined(variant)) {
      return availableOutline[variant]
    }
    return variant
  }, [outline, variant])

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'cta',
      bold = false,
      size = 'full',
      htmlType = 'button',
      disabled = false,
      children,
      onClick,
      outline,
      display = 'block',
    },
    ref
  ) => {
    const buttonType = useStyle({ outline, variant })
    return (
      <button
        /* eslint-disable-next-line react/button-has-type */
        type={htmlType}
        ref={ref}
        className={clsx(
          styles.btn,
          bold && styles.bold,
          styles[buttonType as keyof typeof styles],
          styles[size],
          disabled && styles.disabled,
          styles[display]
        )}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }
)

export const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  position,
  style,
  ...args
}) => (
  <section className={clsx(styles[position])} style={style}>
    <Button {...args} display="block" />
  </section>
)
