import { createContext } from 'react'

export type AuthContextValue = {
  token: string
  name: string
  kana: string
  email: string
  phone: string
  postalCode: string
  prefecture: string
  address1: string
  address2: string
  address3: string
  dateOfBirth: string
}

export const AuthContext = createContext<AuthContextValue | null>(null)
