import React from 'react'

import { Row, RowProps } from '../../../basics'

export const FormRow: React.FC<RowProps> = (props) => {
  const { children } = props
  return (
    <Row
      {...props}
      justify="between"
      gutterWidth={React.Children.count(children) === 2 ? 25 : 0}
      gutterHeight={40}
    >
      {children}
    </Row>
  )
}
