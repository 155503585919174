import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'

import * as styles from './RegistrationConfirmationFrame.module.scss'

import {
  ModelErrorResponse,
  ModelErrorResponseErrorCodeEnum,
} from '../../apis/fvillage'
import {
  AgreementForm,
  Button,
  CancelPolicySection,
  ErrorCard,
  FormNote,
  Loader,
  MainColumn,
  MultiColumn,
  NotesSection,
  RegistrationStepper,
  ReservationDetailButton,
  ReservationLayout,
  ReservationPanel,
  SideColumn,
  Text,
} from '../../components'
import { PATH, REGISTRATION_PATH } from '../../constants'
import {
  useAuthProvider,
  useCreateNextPath,
  useCreateReservationApi,
  useLogin,
  usePublication,
  useRegistrationState,
  useReservationState,
  useReservationsRecentApi,
  useSearchQueryParams,
  useTransactionApi,
} from '../../hooks'
import { Agreement, agreementSchema } from '../../schemas'

const translateNamespace = 'frames.RegistrationConfirmationFrame'

export const RegistrationConfirmationFrame = () => {
  const { token } = useAuthProvider()
  const {
    params: { transaction: transactionId },
  } = useSearchQueryParams()
  const {
    data: transaction,
    error,
    isLoading: isTransactionLoading,
  } = useTransactionApi(transactionId, token)
  const { t, i18n } = useTranslation()
  const { onCreateNextPath } = useCreateNextPath()
  const { onUpdateReservationState } = useReservationState()
  const { trigger: createReservation, error: apiError } =
    useCreateReservationApi(token, transactionId)
  const { onClearRegistrationStateByKey } = useRegistrationState()
  const publication = usePublication(
    i18n.language,
    transaction?.input?.bookingEngineHotel?.publications
  )
  const [isPageError, setPageError] = useState(false)

  const navigate = useNavigate()
  const { onValidateTokenExpired } = useLogin()
  const [isPriceLoaded, setPriceLoaded] = useState(false)
  const [isReservationLoading, setReservationLoading] = useState(false)

  const formMethods = useForm<Agreement>({
    defaultValues: {
      agreement: false,
    },
    resolver: zodResolver(agreementSchema),
  })

  const [errorCord, setErrorCode] = useState<
    ModelErrorResponseErrorCodeEnum | 'default' | undefined
  >(undefined)

  useEffect(() => {
    // Handle api error response.
    if (apiError || error) {
      if (isAxiosError(apiError)) {
        // If token expired, redirect to login page.
        onValidateTokenExpired(apiError)
      }
      if (isAxiosError(error)) {
        onValidateTokenExpired(error)
      }

      setReservationLoading(false)
      if (
        isAxiosError(apiError) &&
        apiError.response &&
        apiError.response.data
      ) {
        const modelError: ModelErrorResponse = apiError.response.data
        setErrorCode(modelError.errorCode ?? 'default')
      }
    }
  }, [error, apiError, onValidateTokenExpired])

  const { handleSubmit, watch } = formMethods
  const isAgreed = watch('agreement', false)

  const onSubmit = handleSubmit(async () => {
    if (!isPriceLoaded) {
      return
    }
    setReservationLoading(true)
    try {
      const res = await createReservation()
      if (res && res.data.confirmationCode && res.data.id) {
        onClearRegistrationStateByKey('specialServiceRequest')
        onUpdateReservationState({
          confirmationCode: res.data.confirmationCode,
          reservationId: res.data.id.toString(),
        })
        navigate(onCreateNextPath(REGISTRATION_PATH.completion))
      }
    } catch (e) {
      // timeout error
      if (isAxiosError(e) && e.code === 'ECONNABORTED') {
        navigate(
          onCreateNextPath(REGISTRATION_PATH.loading, {
            queryParams: { transaction: transaction?.id },
          })
        )
      }
    }
    setReservationLoading(false)
  })

  const cancelPolicy = transaction?.input?.cancelPolicy ?? undefined
  const {
    isHotel = false,
    isSpa = false,
    isActivity = false,
  } = transaction?.input?.bookingEngineHotel ?? {
    isHotel: false,
    isSpa: false,
    isActivity: false,
  }
  const isReserved = Number.isFinite(transaction?.reservationId)

  const [existsRecent, setExistsRecent] = useState(false)
  const { data, isLoading: isRecentLoading } = useReservationsRecentApi({
    token,
    isHotel,
    isSpa,
    isActivity,
  })
  useEffect(() => {
    if ((data?.count ?? 0) > 0) {
      setExistsRecent(true)
    }
  }, [data])
  const reservationPath = useMemo(() => {
    if (isSpa) {
      return PATH.reservations.spas
    }
    if (isActivity) {
      return PATH.reservations.activities
    }
    return PATH.reservations.hotels
  }, [isSpa, isActivity])

  if (isReservationLoading || isTransactionLoading || isRecentLoading) {
    return (
      <Loader>
        <Text align="center">
          <Trans
            i18nKey={
              isReservationLoading
                ? `${translateNamespace}.reserving`
                : `${translateNamespace}.loading`
            }
          />
        </Text>
      </Loader>
    )
  }

  return (
    <ReservationLayout pageTitle={t(`${translateNamespace}.pageTitle`)}>
      <RegistrationStepper
        current={2}
        stepName={t(`${translateNamespace}.stepper`)}
      />
      <FormProvider {...formMethods}>
        <MultiColumn isSpColumnReverse>
          <MainColumn>
            {isPageError && (
              <ErrorCard>
                <Text>
                  <Trans i18nKey="frames.RegistrationNewFrame.error.confirmationError">
                    Sorry, An error has occurred We apologize for the
                    inconvenience.
                    <br />
                    Please change your search conditions and try again.
                  </Trans>
                </Text>
              </ErrorCard>
            )}
            {isReserved && transaction?.reservationId && (
              <ErrorCard>
                <Text>
                  <Trans
                    i18nKey={`${translateNamespace}.reserved`}
                    t={t}
                    components={{
                      reservationLink: (
                        <Link
                          className={styles.link}
                          to={onCreateNextPath(reservationPath.reservation, {
                            pathParams: {
                              reservation: transaction.reservationId.toString(),
                            },
                            clearQueryParams: true,
                          })}
                        />
                      ),
                    }}
                  />
                </Text>
              </ErrorCard>
            )}
            {!isReserved && existsRecent && (
              <ErrorCard warning>
                <Text>
                  <Trans
                    i18nKey={`${translateNamespace}.exists`}
                    t={t}
                    components={{
                      reservationsLink: (
                        <Link
                          className={styles.link}
                          to={reservationPath.root}
                        />
                      ),
                    }}
                  />
                </Text>
              </ErrorCard>
            )}
            <FormNote>
              <Text>{t(`${translateNamespace}.note`)}</Text>
            </FormNote>
            <form onSubmit={onSubmit}>
              <CancelPolicySection cancelPolicy={cancelPolicy} />
              {publication?.notice && (
                <NotesSection note={publication.notice} />
              )}
              <div className={styles.agreementFormWrapper}>
                <AgreementForm />
              </div>
              {errorCord && (
                <ErrorCard
                  statusCode={errorCord}
                  errorMessage={t(`${translateNamespace}.error`)}
                />
              )}
              <MultiColumn isSpColumnReverse>
                <Button
                  htmlType="button"
                  outline
                  variant="tertiary"
                  size="xmedium"
                  onClick={() => {
                    navigate(onCreateNextPath(REGISTRATION_PATH.registrations))
                  }}
                >
                  {t(`${translateNamespace}.button.gotoRegistration`)}
                </Button>
                <Button
                  htmlType="submit"
                  disabled={!isAgreed || !isPriceLoaded || isReserved}
                  variant="cta"
                  size="xmedium"
                >
                  {t(`${translateNamespace}.button.register`)}
                </Button>
              </MultiColumn>
            </form>
          </MainColumn>
          <SideColumn>
            <ReservationPanel
              isHotel={isHotel}
              reservationModal={
                <ReservationDetailButton transaction={transaction} />
              }
              checkInDate={transaction?.input?.checkInDate}
              checkOutDate={transaction?.input?.checkOutDate}
              numberOfAdults={transaction?.input?.numberOfAdults}
              numberOfChildren={transaction?.input?.numberOfChildren}
              numberOfInfants={transaction?.input?.numberOfInfants}
              roomCount={transaction?.input?.roomCount}
              property={transaction?.input?.bookingEngineHotel?.slug}
              room={transaction?.input?.bookingEngineRoom?.slug}
              plan={transaction?.input?.bookingEnginePlan?.slug}
              onApiError={(_error) => {
                setPageError(true)
                window.scrollTo(0, 0)
              }}
              onPriceDidLoad={() => {
                setPriceLoaded(true)
              }}
              transactionId={transactionId}
              reservationId={transaction?.reservationId}
            />
          </SideColumn>
        </MultiColumn>
      </FormProvider>
    </ReservationLayout>
  )
}
