import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './ContactSection.module.scss'

import { Section, Text } from '../../basics'

type ContactSectionProps = {
  contact: string
}

const translateNamespace = 'frames.RegistrationCompletionFrame'

export const ContactSection: React.FC<ContactSectionProps> = ({ contact }) => {
  const { t } = useTranslation()
  return (
    <Section title={t(`${translateNamespace}.contactTitle`)}>
      <div className={clsx(styles.contact)}>
        <Text>{contact}</Text>
      </div>
    </Section>
  )
}
