import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Textarea.module.scss'

const translateNamespace = 'components.Input'

export type TextareaProps = {
  name: string
  label?: React.ReactNode
  value?: string | undefined
  placeholder?: string
  required?: boolean
  helpMessage?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  minLength?: number
  maxLength?: number
  disabled?: boolean
  rows?: number
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      value,
      name,
      placeholder,
      required = false,
      label = null,
      helpMessage,
      onChange,
      onBlur,
      minLength,
      maxLength,
      disabled,
      rows = 3,
    },
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <section>
        <label htmlFor={name}>
          <span className={styles.labelText}>{label}</span>
          {required && (
            <span className={styles.requiredAsterisk}>
              {t(`${translateNamespace}.required`)}
            </span>
          )}
          <textarea
            className={clsx(styles.infoElm)}
            ref={ref}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            id={name}
            minLength={minLength}
            maxLength={maxLength}
            disabled={disabled}
            rows={rows}
          />
          {helpMessage && <p className={styles.help}>{helpMessage}</p>}
        </label>
      </section>
    )
  }
)
