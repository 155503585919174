import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './Layout.module.scss'

import { PATH } from '../../../constants'
import { Footer, Header } from '../../contents'

export type BaseLayoutProps = {
  children: React.ReactNode
}

type LayoutProps = BaseLayoutProps & {
  isPcBgGray?: boolean
}

export const Layout: React.FC<LayoutProps> = ({ isPcBgGray, children }) => {
  const location = useLocation()
  const isTopPage = useMemo(
    () => location.pathname === PATH.root,
    [location.pathname]
  )

  return (
    <React.Fragment>
      <Header />
      <div
        className={clsx(
          styles.layoutWrapper,
          isTopPage && styles.topPageLayoutWrapper,
          isPcBgGray && styles.pcBgGray
        )}
      >
        {children}
      </div>
      <Footer />
    </React.Fragment>
  )
}
