import React from 'react'

import { Arrow } from './Arrow'
import { Circle } from './Circle'
import * as styles from './ToTop.module.scss'

export const ToTop = () => (
  <div className={styles.footerToTop}>
    <a href="#" className={styles.footerToTopArrow}>
      <span className={styles.arrowElm}>
        <Circle />
        <Arrow />
      </span>
    </a>
  </div>
)
