import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

export const useCancelApi = (id: string | number, token: string) => {
  const options = tokenOptions(token)
  const cancel = useCallback(
    async () =>
      reservationApi
        .cancelReservation(Number(id), options)
        .then((res) => res.data),
    [id, options]
  )
  const { trigger, error } = useSWRMutation(
    { method: 'delete', url: `/reservations/${id}` },
    cancel
  )

  return { trigger, error }
}
