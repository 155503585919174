import { useCallback } from 'react'

import { hookstate, useHookstate } from '@hookstate/core'

export type CreditCardState = {
  cardno: string
  holderName: string
  expirationMonth: string
  expirationYear: string
}

const initialState = {
  cardno: '',
  holderName: '',
  expirationMonth: '',
  expirationYear: '',
  // We don't save security code even if memory.
}

export const creditCardStore = hookstate(initialState)

export const useCreditCardState = () => {
  const state = useHookstate(creditCardStore)

  const creditCardState = state.get()

  const onUpdateCreditCardState = useCallback(
    (card: CreditCardState) => state.merge(card),
    [state]
  )

  const onClearCreditCardState = useCallback(
    () =>
      state.set({
        cardno: '',
        holderName: '',
        expirationMonth: '',
        expirationYear: '',
      }),
    [state]
  )

  return {
    creditCardState,
    onUpdateCreditCardState,
    onClearCreditCardState,
  }
}
