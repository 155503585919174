import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './MenuNav.module.scss'

const translateNamespace = 'components.Header.hamburger'

type MenuNavProps = {
  onToggle: (isMenuOpen: boolean) => void
}

export const MenuNav: React.FC<MenuNavProps> = ({ onToggle }) => {
  const { t } = useTranslation()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [menuAnimationClass, setMenuAnimationClass] = useState(styles.initial)

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }

    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [isMenuOpen])

  return (
    <button
      className={clsx(menuAnimationClass, styles.headerHamburgerWrapper)}
      type="button"
      onClick={() => {
        setMenuOpen(!isMenuOpen)
        setMenuAnimationClass(isMenuOpen ? styles.closed : styles.opened)
        onToggle(isMenuOpen)
      }}
    >
      <div className={styles.headerHamburgerBars}>
        {/* The blank span tag creates each line of the hamburger icon. */}
        {/* The hamburger icon consists of three blank span tags. */}
        <span />
        <span />
        <span />
      </div>

      <div className={styles.headerHamburgerText}>
        <div className={styles.hamburgerTextMenu}>
          {t(`${translateNamespace}.menu`)}
        </div>
        <div className={styles.hamburgerTextClose}>
          {t(`${translateNamespace}.close`)}
        </div>
      </div>
    </button>
  )
}
