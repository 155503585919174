import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Footer.module.scss'
import { FooterListAbove } from './FooterListAbove'
import { ToTop } from './ToTop'

import { Flex, FlexBox } from '../../basics'

const translateNamespace = 'components.Footer'

export const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <footer>
      <div className={clsx(styles.footerContent, styles.footerContentType1)}>
        <ToTop />

        <div className={styles.footerContentBody}>
          <Flex optionClass={styles.footerLayoutCol2}>
            <FlexBox optionClass={styles.footerLayoutLeft}>
              {/*   <FooterLogo /> */}
            </FlexBox>
            <FlexBox optionClass={styles.footerLayoutRight}>
              <FooterListAbove />
            </FlexBox>
          </Flex>
        </div>
      </div>

      <div className={clsx(styles.footerContent2, styles.footerContentType2)}>
        <div className={styles.footerLayoutBetweenPc}>
          <div className={styles.footerLinklist1Wrapper}>
            <ul className={styles.footerLinklist1}>
              <li className={styles.footerLinklist1Item}>
                <a
                  href="https://www.hkdballpark.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  F VILLAGE
                </a>
              </li>
            </ul>
            <ul className={styles.footerLinklist1}>
              <li className={styles.footerLinklist1Item}>
                <a
                  href="https://www.hkdballpark.com/sitepolicy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(`${translateNamespace}.list.sitePolicy`)}
                </a>
              </li>
            </ul>
            <ul className={styles.footerLinklist1}>
              <li className={styles.footerLinklist1Item}>
                <a
                  href="https://www.fighters.co.jp/company/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(`${translateNamespace}.list.privacyFull`)}
                </a>
              </li>
            </ul>
            <ul className={styles.footerLinklist1}>
              <li className={styles.footerLinklist1Item}>
                <a
                  href="https://www.hkdballpark.com/travel/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(`${translateNamespace}.list.travel`)}
                </a>
              </li>
            </ul>
            <ul className={styles.footerLinklist1}>
              <li className={styles.footerLinklist1Item}>
                <a
                  href="https://www.hkdballpark.com/contact/customer/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(`${translateNamespace}.list.contact`)}
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.footerCopyright}>
            <p>
              © Fighters Sports & Entertainment Co., Ltd. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
