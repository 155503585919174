import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import clsx from 'clsx'

import * as styles from './DiscountCodeForm.module.scss'

import {
  useAuthProvider,
  usePatchDiscountApi,
  useRouteParams,
} from '../../../hooks'
import { DiscountCode, discountCodeSchema } from '../../../schemas'
import {
  ButtonWrapper as Button,
  Col,
  Container,
  ErrorMessage,
  FormInput,
  Row,
} from '../../basics'

type DiscountCodeFormProps = {
  discountCode: string
  onApplyFinish: () => void
  transactionId?: number
}

const translateNamespace = 'components.DiscountCodeForm'

export const DiscountCodeForm: React.FC<DiscountCodeFormProps> = ({
  discountCode = '',
  onApplyFinish,
  transactionId,
}) => {
  const { t } = useTranslation()
  const { token } = useAuthProvider()
  const { property, room, plan } = useRouteParams()
  const { trigger } = usePatchDiscountApi({
    token,
    property,
    room,
    plan,
  })
  const formMethods = useForm<DiscountCode>({
    defaultValues: {
      discountCode: discountCode ?? '',
    },
    resolver: zodResolver(discountCodeSchema),
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods

  const onApply = handleSubmit(async (formValues) => {
    try {
      await trigger({
        discountCode: formValues.discountCode ?? '',
        transactionId,
      })
    } catch (e) {
      if (isAxiosError(e)) {
        if (e?.response?.status === 400) {
          setError('discountCode', {
            message: t(`${translateNamespace}.error.invalid`),
          })
        } else if (e?.response?.status) {
          setError('discountCode', {
            message: t(`${translateNamespace}.error.auth`),
          })
        }
        return
      }
      setError('discountCode', {
        message: t(`${translateNamespace}.error.unknown`),
      })
    } finally {
      onApplyFinish()
    }
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onApply} className={styles.coupon}>
        <Container>
          <Row>
            <Col pc={9} sp={9}>
              <div
                className={clsx(
                  discountCode && !errors.discountCode && styles.check
                )}
              >
                <FormInput
                  control={control}
                  name="discountCode"
                  inputMode="text"
                  label={t(`${translateNamespace}.title`)}
                  onBlur={onApply}
                />
              </div>
              <ErrorMessage errors={errors} name="discountCode" />
            </Col>
            <Col pc={3} sp={3}>
              <Button
                htmlType="submit"
                variant="primary"
                position="center"
                size="small"
                style={{ marginLeft: '9px', paddingTop: '3.6rem' }} /* FIXME */
              >
                {t(`${translateNamespace}.apply`)}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </FormProvider>
  )
}
