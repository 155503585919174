import React, { useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import {
  addDays,
  addMonths,
  getMonth,
  isSaturday,
  isSunday,
  lastDayOfMonth,
  parse,
} from 'date-fns'
import ja from 'date-fns/locale/ja'
import { format } from 'date-fns-tz'
import SimpleBar from 'simplebar-react'

import 'simplebar-react/dist/simplebar.min.css'

import './CalendarTable.scss'
import './Simplebar.scss'
import * as styles from './CalendarTable.module.scss'

import { PublicationResponse } from '../../../apis/fvillage'
import { PATH } from '../../../constants'
import {
  useAvailability,
  useCreateNextPath,
  useGameDays,
  usePropertyAvailabilitiesApi,
  usePublication,
} from '../../../hooks'
import { getCurrentJstDateTime, getDefaultCheckInDate } from '../../../utils'
import {
  Flex,
  FlexBox,
  Option,
  Select,
  BaseText as Text,
  fontColor,
} from '../../basics'
import { Loader } from '../Loader'

type AvailabilityProps = {
  rate: number | null
  date: string
  property: string
}

const translateNamespace = 'components.CalendarTable'

// FIXME style
const NotAvailable: React.FC<{ slug?: string; days?: number }> = ({
  slug = '',
  days = 16,
}) => (
  <React.Fragment>
    {[...Array(days).keys()].map((v) => (
      <td key={`${slug}-${v}`}>
        <div className="availabilityStatus one" />
      </td>
    ))}
  </React.Fragment>
)

// FIXME style
const Availability: React.FC<AvailabilityProps> = ({
  rate,
  date,
  property,
}) => {
  const { onCreateNextPath } = useCreateNextPath()
  const availability = useAvailability(rate)
  const datetime = parse(date, 'yyyy-MM-dd', getCurrentJstDateTime())
  const checkOut = format(addDays(datetime, 1), 'yyyy-MM-dd')
  const nextPath = onCreateNextPath(PATH.rooms, {
    pathParams: { property },
    queryParams: {
      checkInDate: date,
      checkOutDate: checkOut,
    },
  })
  if (availability === 'notAvailable') {
    return <div className="availabilityStatus one" />
  }

  if (availability === 'occupied') {
    return <div className="availabilityStatus four" />
  }

  if (availability === 'few') {
    return (
      <Link to={nextPath}>
        <div className="availabilityStatus three" />
      </Link>
    )
  }

  return (
    <Link to={nextPath}>
      <div className="availabilityStatus two" />
    </Link>
  )
}

// FIXME: Merge basics/Icon component
const BaseballIcon: React.FC = () => <div className={styles.baseball} />

const CalendarDate: React.FC<{ date: string }> = ({ date }) => {
  const { isGameDay } = useGameDays()
  const datetime = parse(date, 'yyyy-MM-dd', getCurrentJstDateTime())
  let color: (typeof fontColor)[keyof typeof fontColor] = 'white'
  if (isSaturday(datetime)) {
    color = 'blue0'
  } else if (isSunday(datetime)) {
    color = 'red0'
  }

  return (
    <td key={date}>
      <div className={styles.date}>
        <Text size={20} align="center" color={color}>
          {format(datetime, 'MM/dd')}
        </Text>
        <Text align="center" color={color} size={14}>
          {format(datetime, 'E').toUpperCase()}
          {isGameDay(datetime) && <BaseballIcon />}
        </Text>
      </div>
    </td>
  )
}

const PropertyName: React.FC<{
  publications?: PublicationResponse[]
}> = ({ publications }) => {
  const { i18n } = useTranslation()
  const publication = usePublication(i18n.language, publications)
  if (!publication) {
    return null
  }

  return (
    <div className={styles.propertyName}>
      <Text color="black1" pcSize={14} spSize={12}>
        {publication.shortName}
      </Text>
      <Text color="gray3" pcSize={12} spSize={10} weight="normal">
        {publication.access}
      </Text>
    </div>
  )
}

// FIXME style
const PropertyCalendar: React.FC<{
  fromDate: string
  toDate: string
  isPending: boolean
}> = ({ fromDate, toDate, isPending }) => {
  const { t } = useTranslation()
  const { data: properties, isLoading } = usePropertyAvailabilitiesApi({
    fromDate,
    toDate,
  })

  if (!properties || !Array.isArray(properties) || isLoading || isPending) {
    return <Loader />
  }

  const sortedProperties = [
    ...properties.filter((p) => p.insideFvillage),
    ...properties.filter((p) => !p.insideFvillage),
  ]

  return (
    <SimpleBar autoHide={false} scrollbarMaxSize={20} className="scrollbar">
      <table className={styles.availability}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', zIndex: 10 }}>
              {t(`${translateNamespace}.availability`)}
            </th>
            {properties[0]?.availabilities?.map(({ date }) => {
              if (!date) {
                return null
              }
              return <CalendarDate date={date} key={date} />
            })}
          </tr>
        </thead>
        <tbody>
          {sortedProperties.map(
            ({ id, slug, availabilities, publications }) => (
              <tr key={id}>
                <th>
                  <PropertyName publications={publications} />
                </th>
                {Array.isArray(availabilities) && availabilities.length > 0 ? (
                  availabilities.map(({ date, availableRate }) => (
                    <td key={date}>
                      {date && slug && (
                        <Availability
                          date={date}
                          property={slug}
                          rate={availableRate ?? null}
                        />
                      )}
                    </td>
                  ))
                ) : (
                  <NotAvailable slug={slug} />
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </SimpleBar>
  )
}

export const CalendarTable: React.FC = () => {
  const { i18n, t } = useTranslation()
  const [isPending, startTransition] = useTransition()

  const current = getCurrentJstDateTime()
  const startDate = parse(getDefaultCheckInDate(), 'yyyy-MM-dd', current)
  const startMonth = getMonth(startDate)
  const [fromDateString, setFromDate] = useState(
    format(startDate, 'yyyy-MM-dd')
  )
  const fromDate = parse(fromDateString, 'yyyy-MM-dd', current)
  const toDate =
    getMonth(fromDate) === startMonth
      ? addDays(fromDate, 31)
      : lastDayOfMonth(fromDate)
  const toDateString = format(toDate, 'yyyy-MM-dd')

  const monthCount = 7
  const options = [...Array(monthCount)].map((_, i) => {
    const date = addMonths(startDate, i)
    const month = getMonth(date)

    return {
      value: format(date, month === startMonth ? 'yyyy-MM-dd' : 'yyyy-MM-01'),
      display:
        i18n.language === 'ja'
          ? format(date, 'yo MMM', { locale: ja })
          : format(date, 'MMM. yyyy'),
    }
  })

  return (
    <React.Fragment>
      <Flex optionClass={styles.dateSelect}>
        <FlexBox optionClass={styles.select}>
          <Select
            isBorderBottom
            size="small"
            color="black"
            isBold
            onChange={(event) => {
              startTransition(() => {
                setFromDate(event.target.value)
              })
            }}
          >
            {options.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
        <FlexBox optionClass={styles.note}>
          <div className={styles.noteIcon}>
            <BaseballIcon />
          </div>
          <Text weight="bold">{t(`${translateNamespace}.gameDay`)}</Text>
        </FlexBox>
      </Flex>
      <PropertyCalendar
        fromDate={fromDateString}
        toDate={toDateString}
        isPending={isPending}
      />
      <div className="availabilityDesc">
        <div className="availabilityStatus two" />
        <Text size={12} pcSize={14}>
          {t(`${translateNamespace}.available`)} /
        </Text>
        <div className="availabilityStatus three" />
        <Text size={12} pcSize={14}>
          {t(`${translateNamespace}.runningLow`)} /
        </Text>
        <div className="availabilityStatus four" />
        <Text size={12} pcSize={14}>
          {t(`${translateNamespace}.soldOut`)} /
        </Text>
        <div className="availabilityStatus one" />
        <Text size={12} pcSize={14}>
          {t(`${translateNamespace}.notAvailable`)}
        </Text>
      </div>
      <div className={clsx('sp', styles.description)}>
        <Text size={12} color="gray1">
          {t(`${translateNamespace}.description`)}
        </Text>
      </div>
    </React.Fragment>
  )
}
