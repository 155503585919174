import React, { useEffect } from 'react'

import {
  ReservationResponse,
  TransactionResponse,
} from '../../../apis/fvillage'
import { useAuthProvider, useLogin, useTransactionApi } from '../../../hooks'
import { Modal } from '../../basics'
// Import each component for prevent Dependency cycle.
import { GuestDetailList } from '../../reservations/ReservationDetail/GuestDetailList'
import { ReservationDetailList } from '../../reservations/ReservationDetail/ReservationDetailList'

type ReservationModalProps = {
  isOpen: boolean
  onClose: () => void
  showGuest?: boolean
  transaction: TransactionResponse
  reservationId?: number
}

export const ReservationModal: React.FC<ReservationModalProps> = ({
  isOpen,
  onClose,
  showGuest = true,
  transaction,
}) => {
  const { token } = useAuthProvider()
  const { onValidateTokenExpired } = useLogin()
  const { data, error } = useTransactionApi(transaction.id, token)

  useEffect(() => {
    onValidateTokenExpired(error)
  }, [error, onValidateTokenExpired])

  if (!data) {
    return null
  }

  const reservation: ReservationResponse = transaction.input
    ? {
        transaction: {
          input: transaction.input,
        },
      }
    : {
        transaction: {
          input: data?.input,
        },
      }
  const guest = transaction.input ?? data?.input

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        content: {
          maxWidth: 'calc(1100px + 8rem)',
        },
      }}
    >
      <ReservationDetailList {...reservation} />
      {showGuest && <GuestDetailList {...guest} />}
    </Modal>
  )
}
