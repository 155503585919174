import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './TopNav.module.scss'
import { TopNavImage } from './TopNavImage'

import { PATH } from '../../../../constants'

const translateNamespace = 'components.Header'

type TopNavProps = {
  isMenuOpen: boolean
}

export const TopNav: React.FC<TopNavProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button
      className={clsx(isMenuOpen && styles.isOpen, styles.translateWrapper)}
      type="button"
      onClick={() => {
        navigate(PATH.root)
      }}
    >
      <div className={styles.centerSvg}>
        <TopNavImage color={isMenuOpen ? styles.textWhite : styles.textBlack} />
      </div>

      <div className={styles.translateLabel}>
        <div className={styles.translateTextClose}>
          {t(`${translateNamespace}.top`)}
        </div>
      </div>
    </button>
  )
}
