import axios from 'axios'
import { isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Component, Event, parse as parseIcal } from 'ical.js'
import useSWR from 'swr'

import { TIMEZONE } from '../../constants'

export const useGameDays = () => {
  const { data, isLoading, error } = useSWR(
    `/game-day.ics`,
    async () => {
      const res = await axios.get<string>('/game-day.ics')
      return res.data
    },
    {
      revalidateOnFocus: false,
    }
  )

  const gameDays: Date[] = []
  const isGameDay = (date: Date) =>
    gameDays.some((gameDay) => isSameDay(gameDay, date))

  if (isLoading || !data || error) {
    return { gameDays, isGameDay }
  }

  const ical = parseIcal(data)
  const vcalendar = new Component(ical)
  const vevents = vcalendar.getAllSubcomponents()

  vevents.forEach((vevent) => {
    const event = new Event(vevent)

    if (
      event.location &&
      event.location.match(
        /(ES CON FIELD HOKKAIDO)|(エスコンフィールド北海道)|(エスコンフィールドHOKKAIDO)/
      )
    ) {
      const time = event.startDate
      gameDays.push(utcToZonedTime(time.toJSDate(), TIMEZONE))
    }
  })

  return {
    gameDays,
    isGameDay,
  }
}
