import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Registration } from '../../../../schemas'
import { Col, Container, ErrorMessage, FormTextarea } from '../../../basics'
import { FormRow } from '../FormRow'

export const InquiryForm = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<Registration>()
  return (
    <Container>
      <FormRow>
        <Col>
          <FormTextarea
            control={control}
            name="specialServiceRequest"
            label={t(`components.InquiryForm.label`)}
            helpMessage={t(`components.InquiryForm.help`)}
          />
          <ErrorMessage errors={errors} name="specialServiceRequest" />
        </Col>
      </FormRow>
    </Container>
  )
}
