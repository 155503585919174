import React from 'react'

import clsx from 'clsx'

import * as styles from './Note.module.scss'

import { BaseText } from '../../basics'

type NoteProps = {
  children: React.ReactNode
}

export const Note: React.FC<NoteProps> = ({ children }) => (
  <div className={clsx(styles.note)}>
    <BaseText size={14} color="gray3">
      {children}
    </BaseText>
  </div>
)

type FormNoteProps = {
  children: React.ReactNode
}

export const FormNote: React.FC<FormNoteProps> = ({ children }) => (
  <div className={styles.formNote}>{children}</div>
)
