import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { utcToZonedTime } from 'date-fns-tz'

import * as styles from './RoomSearchModal.module.scss'

import { TIMEZONE } from '../../../constants'
import { formatJstDate } from '../../../utils'
import {
  Button,
  Flex,
  FlexBox,
  List,
  Modal,
  ModalProps,
  Option,
  Select,
  Text,
} from '../../basics'

const translateNamespace = 'components.SearchModal'

type RoomSearchModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  propertyName: string
  officialUrl: string
  onSearch: (
    rooms: number,
    adults: number,
    children: number,
    infant: number
  ) => void
  roomParam?: number
  adultParam?: number
  childParam?: number
  infantParam?: number
  checkIn?: string
  checkOut?: string
}

export const RoomSearchModal: React.FC<RoomSearchModalProps> = ({
  isOpen,
  onClose,
  propertyName,
  officialUrl,
  onSearch,
  roomParam = 1,
  adultParam = 2,
  childParam = 0,
  infantParam = 0,
  checkIn = '',
  checkOut = '',
}) => {
  const { t, i18n } = useTranslation()
  const [room, setRoom] = useState(roomParam)
  const [adult, setAdult] = useState(adultParam)
  const [child, setChild] = useState(childParam)
  const [infant, setInfant] = useState(infantParam)
  const checkInDate = utcToZonedTime(checkIn, TIMEZONE)
  const checkOutDate = utcToZonedTime(checkOut, TIMEZONE)

  const items = [
    {
      label: `${t(`${translateNamespace}.property`)}`,
      text: `${propertyName}`,
    },
    {
      label: `${t(`${translateNamespace}.checkIn`)}`,
      text: `${formatJstDate(
        checkInDate,
        i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
      )}`,
    },
    {
      label: `${t(`${translateNamespace}.checkOut`)}`,
      text: `${formatJstDate(
        checkOutDate,
        i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
      )}`,
    },
    {
      label: `${t(`${translateNamespace}.roomAmount`)}`,
      text: `${room} ${t(`${translateNamespace}.roomUnit`)}`,
    },
    {
      label: `${t(`${translateNamespace}.guestAmount`)}`,
      text: (
        <Trans
          i18nKey={`${translateNamespace}.guests`}
          value={{
            adult,
            child,
            infant,
          }}
        >
          {{ adult }} adults
          <br />
          {{ child }} children
          <br />
          {{ infant }} infants
        </Trans>
      ),
    },
  ]

  const disabledSearch = useMemo(() => room < 1 || adult < 1, [room, adult])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setRoom(roomParam)
        setAdult(adultParam)
        setChild(childParam)
        setInfant(infantParam)
        onClose()
      }}
      title={t(`${translateNamespace}.selectRoom`)}
      subTitle={<Text>{t(`${translateNamespace}.selectRoomDesc`)}</Text>}
      leftButton={
        <Button
          htmlType="button"
          variant="secondary"
          onClick={() => {
            setRoom(roomParam)
            setAdult(adultParam)
            setChild(childParam)
            setInfant(infantParam)
            onClose()
          }}
        >
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={disabledSearch}
          onClick={() => onSearch(room, adult, child, infant)}
        >
          {t(`${translateNamespace}.search`)}
        </Button>
      }
    >
      <Flex optionClass={styles.contents}>
        <FlexBox>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span className={styles.box}>
                {t(`${translateNamespace}.roomAmount`)}
              </span>
            </FlexBox>
            <FlexBox>
              <Select
                onChange={(e) => setRoom(Number(e.target.value))}
                value={room}
              >
                {[...Array(5)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i + 1} key={`room-modal-room${i}`}>
                    {i + 1} {t(`${translateNamespace}.roomUnit`)}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          </Flex>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>{t(`${translateNamespace}.adultWithAge`)} </span>
            </FlexBox>
            <FlexBox>
              <Select
                onChange={(e) => setAdult(Number(e.target.value))}
                value={adult}
              >
                {[...Array(7)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i + 1} key={`room-modal-adult${i}`}>
                    {i + 1} {t(`${translateNamespace}.guestUnit`)}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          </Flex>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>
                {t(`${translateNamespace}.childWithAge`)}
                {'　'}
              </span>
            </FlexBox>
            <FlexBox>
              <Select
                onChange={(e) => setChild(Number(e.target.value))}
                value={child}
              >
                {[...Array(6)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i} key={`room-modal-child${i}`}>
                    {i} {t(`${translateNamespace}.guestUnit`)}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          </Flex>
          <Flex optionClass={styles.forms}>
            <FlexBox>
              <span>
                {t(`${translateNamespace}.infantWithAge`)}
                {'　'}
              </span>
            </FlexBox>
            <FlexBox>
              <Select
                onChange={(e) => setInfant(Number(e.target.value))}
                value={infant}
              >
                {[...Array(6)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Option value={i} key={`room-modal-infant${i}`}>
                    {i} {t(`${translateNamespace}.guestUnit`)}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          </Flex>
          {officialUrl && (
            <div className={styles.notice}>
              <Text size={12}>
                {t(`${translateNamespace}.notice`)}
                <br />
                <a href={officialUrl} target="_blank" rel="noreferrer">
                  {t(`${translateNamespace}.propertyDetail`, {
                    name: propertyName,
                  })}
                </a>
              </Text>
            </div>
          )}
        </FlexBox>
        <FlexBox>
          <div className={styles.list}>
            <List
              items={items}
              renderItem={(item) => (
                <List.Item key={item.label}>
                  <div className={styles.listItem}>
                    <Flex optionClass={styles.listItemContents}>
                      <FlexBox>
                        <Text size={10}>{item.label}</Text>
                      </FlexBox>
                      <FlexBox>
                        <Text weight="bold" size={14}>
                          {item.text}
                        </Text>
                      </FlexBox>
                    </Flex>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </FlexBox>
      </Flex>
    </Modal>
  )
}
