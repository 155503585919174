import React from 'react'

import clsx from 'clsx'

import * as styles from './PropertyCard.module.scss'

import Bed from '../../../assets/bed.svg'
import Commute from '../../../assets/commute.svg'
import { BaseText } from '../../basics'

export type PropertyCardProps = {
  img: string
  title: string
  subTitle?: string
  access?: string
  pricing?: string
  children?: React.ReactNode
  className: string
  officialUrl?: string
  insideFvillage?: boolean
}

export const PropertyCard: React.FC<PropertyCardProps> = ({
  title,
  subTitle,
  img,
  access,
  pricing,
  children,
  className,
  officialUrl,
  insideFvillage,
}) => (
  <React.Fragment>
    {officialUrl && insideFvillage ? (
      <a href={officialUrl}>
        <img className={styles.img} src={img} alt="" />
      </a>
    ) : (
      <img className={styles.img} src={img} alt="" />
    )}
    {officialUrl && insideFvillage ? (
      <a
        href={officialUrl}
        className={clsx(
          styles.title,
          className.includes('property')
            ? styles.propertyTitle
            : styles.partnerTitle
        )}
      >
        {title}
        {subTitle && <small className={styles.subTitle}>{subTitle}</small>}
      </a>
    ) : (
      <h2
        className={clsx(
          styles.title,
          className.includes('property')
            ? styles.propertyTitle
            : styles.partnerTitle
        )}
      >
        {title}
        {subTitle && <small className={styles.subTitle}>{subTitle}</small>}
      </h2>
    )}
    {children && <div className={styles.desc}>{children}</div>}
    <div className={styles.items}>
      <img src={Commute} alt="" />
      <BaseText color="gray1" size={10}>
        {access}
      </BaseText>
    </div>
    {pricing && (
      <div className={styles.items}>
        <img src={Bed} alt="" />
        <BaseText color="gray1" size={10}>
          {pricing}
        </BaseText>
      </div>
    )}
  </React.Fragment>
)
