import {
  AuthApiFactory,
  NewsApiFactory,
  NotificationApiFactory,
  PropertyApiFactory,
  ReservationApiFactory,
  TransactionApiFactory,
} from './fvillage/api'
import { Configuration } from './fvillage/configuration'

const fvillageConf = new Configuration({
  basePath: process.env.VITE_SUITEBOOK_API_V2_URL,
})

const SUITEBOOK_ACCESS_TOKEN = process.env.VITE_SUITEBOOK_ACCESS_TOKEN || ''

export const options = {
  headers: {
    Authorization: `suitebook ${SUITEBOOK_ACCESS_TOKEN}`,
    'Content-Type': 'application/json',
  },
}

// TODO migrate to options
export const tokenOptions = (token: string) => ({
  headers: {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  },
})

export const reservationApi = ReservationApiFactory(fvillageConf)
export const propertyApi = PropertyApiFactory(fvillageConf)
export const authApi = AuthApiFactory(fvillageConf)
export const transactionApi = TransactionApiFactory(fvillageConf)
export const notificationApi = NotificationApiFactory(fvillageConf)
export const newsApi = NewsApiFactory(fvillageConf)
