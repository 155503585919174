import { useParams } from 'react-router-dom'

export type PathParams = {
  property?: string
  room?: string
  reservation?: string
  plan?: string
}

export const useRouteParams = () => {
  const routeParams = useParams<PathParams>()
  return routeParams
}
