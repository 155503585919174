import { useCallback, useMemo } from 'react'

import { useLogoutApi } from '../useLogoutApi'

export const useLogout = () => {
  const token = useMemo(() => {
    try {
      return sessionStorage.getItem(`access_token`) ?? ''
    } catch (e) {
      return ''
    }
  }, [])
  const { trigger } = useLogoutApi(token)

  const onLogout = useCallback(async () => {
    try {
      sessionStorage.removeItem('access_token')
      sessionStorage.removeItem('name')
      sessionStorage.removeItem('email')

      await trigger()
    } catch (e) {
      // Token maybe expired
      // Do nothing
    } finally {
      // TODO When logout at root, does not re-render.
      // So currently use location.assign('/')
      window.location.assign('/')
    }
  }, [trigger])

  return { onLogout }
}
