import { useTranslation } from 'react-i18next'

import { Layout, NotFound } from '../../components'

const translateNamespace = 'frames.ErrorFrame.404'

export const NotFoundErrorFrame = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <NotFound
        title="404 Not Found"
        description1={t(`${translateNamespace}.description1`)}
        description2={t(`${translateNamespace}.description2`)}
      />
    </Layout>
  )
}
