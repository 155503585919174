import React from 'react'

import * as styles from './Tabs.module.scss'

type ItemProps = {
  label: React.ReactNode
  key: string
  children: React.ReactNode
}

type TabsProps = {
  items: ItemProps[]
  defaultActiveKey: string
  onChange: (key: string) => void
  optionalClass?: string
}

export const Tabs: React.FC<TabsProps> = ({
  defaultActiveKey,
  items,
  onChange,
  optionalClass,
}) => (
  <section className={optionalClass}>
    <div className={styles.tabTitles}>
      {items.map(({ key, label }) => (
        <button
          type="button"
          key={key}
          className={defaultActiveKey === key ? styles.active : undefined}
          onClick={() => onChange(key)}
        >
          {label}
        </button>
      ))}
    </div>
    <div>
      {items.map(({ key, children }) => {
        if (defaultActiveKey === key) {
          return (
            <div key={key} className={styles.tabContent}>
              {children}
            </div>
          )
        }
        return null
      })}
    </div>
  </section>
)
