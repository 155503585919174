import React from 'react'

import * as styles from './SortStatus.module.scss'

export const SortIcon: React.FC = () => (
  <div className={styles.sortIcon}>
    <div className={styles.sortIconInner}>
      {/* The blank span tag creates each line of the hamburger menu. */}
      {/* The hamburger menu consists of three blank span tags. */}
      <span className={styles.sortIconElm} />
      <span className={styles.sortIconElm} />
      <span className={styles.sortIconElm} />
    </div>
  </div>
)
