import React from 'react'

type LanguageNavImageProps = {
  color: string
}

export const LanguageNavImage: React.FC<LanguageNavImageProps> = ({
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    className={color}
  >
    <path data-name="\u30D1\u30B9 32" d="M0 0h30v30H0Z" fill="none" />
    <path
      data-name="\u30D1\u30B9 33"
      d="M14.983 3.083A11.917 11.917 0 1 0 26.916 15 11.911 11.911 0 0 0 14.983 3.083Zm8.258 7.15h-3.51a18.649 18.649 0 0 0-1.648-4.242 9.569 9.569 0 0 1 5.163 4.242ZM15 5.514a16.787 16.787 0 0 1 2.276 4.719h-4.552A16.787 16.787 0 0 1 15 5.514ZM5.776 17.383a9.322 9.322 0 0 1 0-4.767h4.028a19.681 19.681 0 0 0-.167 2.383 19.681 19.681 0 0 0 .167 2.384Zm.977 2.383h3.516a18.649 18.649 0 0 0 1.644 4.242 9.518 9.518 0 0 1-5.16-4.242Zm3.516-9.533H6.753a9.518 9.518 0 0 1 5.16-4.242 18.649 18.649 0 0 0-1.644 4.242ZM15 24.483a16.787 16.787 0 0 1-2.276-4.719h4.552A16.787 16.787 0 0 1 15 24.483Zm2.788-7.1h-5.577A17.533 17.533 0 0 1 12.02 15a17.381 17.381 0 0 1 .191-2.383h5.577A17.38 17.38 0 0 1 17.979 15a17.532 17.532 0 0 1-.191 2.383Zm.3 6.626a18.649 18.649 0 0 0 1.645-4.242h3.515a9.569 9.569 0 0 1-5.165 4.242Zm2.107-6.626A19.68 19.68 0 0 0 20.362 15a19.68 19.68 0 0 0-.167-2.383h4.028a9.322 9.322 0 0 1 0 4.767Z"
    />
  </svg>
)
