import React from 'react'

import clsx from 'clsx'

import * as styles from './Section.module.scss'

import { Text } from '../Text'

type SectionProps = {
  title?: React.ReactNode
  children: React.ReactNode
}

export const Section: React.FC<SectionProps> = ({ title, children }) => (
  <section className={clsx(styles.section)}>
    {title && (
      <h6 className={styles.title}>
        <Text size={18} weight="bold">
          {title}
        </Text>
      </h6>
    )}
    {children}
  </section>
)
