export const TIMEZONE = 'Asia/Tokyo'
export const ROOT = '/' as const
export const ERROR = 'error' as const
export const SUPPORTED_LANGUAGES = ['ja', 'en'] as const

export const DEFAULT_ROOMS = 1
export const DEFAULT_ADULTS = 2
export const DEFAULT_CHILDREN = 0
export const DEFAULT_INFANTS = 0
export const DEFAULT_TRANSACTION_ID = 0
export const PAGE_SIZE = 10

export const LATEST_NEWS_URL = 'https://www.hkdballpark.com/news/434/'

export const reservationRouteType = {
  Hotel: 'hotels',
  Spa: 'spas',
  Activity: 'activities',
} as const

export const getReservationPaths = (
  type: (typeof reservationRouteType)[keyof typeof reservationRouteType]
) => ({
  root: `/${type}/reservations`,
  history: `/${type}/reservations/history`,
  reservation: `/${type}/reservations/:reservation`,
  cancel: {
    root: `/${type}/reservations/:reservation/cancel`,
    completion: `/${type}/reservations/:reservation/cancel/completion`,
  },
})

export const PATH = {
  root: ROOT,
  login: {
    root: '/login/callback',
    fid: '/login/callback/fid',
    google: '/login/callback/google',
  },
  registrations: {
    root: '/:property/:room/:plan/registrations',
    confirmation: 'confirmation',
    completion: 'completion',
    loading: 'loading',
  },
  rooms: '/:property/rooms',
  plans: '/:property/:room/plans',
  options: '/:property/:room/:plan/options',
  reservations: {
    hotels: getReservationPaths(reservationRouteType.Hotel),
    spas: getReservationPaths(reservationRouteType.Spa),
    activities: getReservationPaths(reservationRouteType.Activity),
  },
  errors: {
    loginError: '/login/error',
    serverError: '/500',
  },
}

const REGISTRATION_ROOT = PATH.registrations.root

export const REGISTRATION_PATH = {
  registrations: `${REGISTRATION_ROOT}`,
  confirmation: `${REGISTRATION_ROOT}/${PATH.registrations.confirmation}`,
  completion: `${REGISTRATION_ROOT}/${PATH.registrations.completion}`,
  loading: `${REGISTRATION_ROOT}/${PATH.registrations.loading}`,
}

// Spa(onyoku) page's header logo link
// If activity page(e.g. dogrun page) was added,
// add external link page to below.
export const EXTERNAL_PATH = {
  spas: 'https://www.hkdballpark.com/shops/10/',
  activities: 'https://www.hkdballpark.com/news/425/',
}

export enum EquipmentEnum {
  Bath = '0',
  GuestRoomWithOpenairbath = '1',
  Shower = '2',
  Toilet = '3',
  ToiletWithWashingMachine = '4',
  AirConditioner = '5',
  AirPurifier = '6',
  Humidifier = '7',
  Refrigerator = '8',
  Kitchen = '9',
  Minibar = '10',
  Coffee = '11',
  Tea = '12',
  ElectricKettle = '13',
  TV = '14',
  CableTV = '15',
  Telephone = '16',
  SafetyBox = '17',
  WiredInternet = '21',
  WifiInternet = '23',
  HairDryer = '24',
  TrouserPress = '25',
  Pajamas = '26',
  Slippers = '27',
  Towel = '29',
}

export enum AmenityEnum {
  ShampooConditioner = '0',
  BodySoap = '1',
  HandSoap = '2',
  Toothbrush = '3',
  FacialCleanser = '4',
  Cleansers = '5',
  Lotion = '6',
  MilkyLotion = '7',
  Shaver = '8',
  ShavingFoam = '9',
  HairBrush = '10',
  ShowerCap = '11',
  ClothingDeodorizingSpray = '12',
}

export enum FeatureEnum {
  Accessible = '0',
  NonSmoking = '1',
  Smoking = '2',
  PetsAllowed = '3',
}

export enum MealEnum {
  Breakfast = 1,
  Supper = 2,
  BreakfastSupper = 3,
  Lunch = 4,
  BreakfastLunch = 5,
  LunchSupper = 6,
  BreakfastLunchSupper = 7,
}

export enum RoomTypeEnum {
  Single = 'single',
  ThreeQuarter = 'three quarter',
  Double = 'double',
  Twin = 'twin',
  Triple = 'triple',
  FourBeds = 'four beds',
  FiveBeds = 'five beds',
  Others = 'others',
}

export enum GuestStatusEnum {
  PreArrival = 1,
  CheckInDay = 2,
  Stay = 3,
  CheckOutDay = 4,
  Departure = 5,
  NoShow = 6,
  CanceledByProperty = 7,
  CanceledByGuest = 8,
}

export type PathKey = keyof typeof PATH
export type Path = (typeof PATH)[PathKey]
