import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'

import { addYears, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { english } from 'flatpickr/dist/l10n/default'

import { TIMEZONE } from '../../../constants'
import { useGameDays } from '../../../hooks'
import { getCurrentJstDate } from '../../../utils'

import 'flatpickr/dist/flatpickr.min.css'
import './flatpickr.scss'

// Change month labels like January to just numbers
english.months.longhand[0] = '1'
english.months.longhand[1] = '2'
english.months.longhand[2] = '3'
english.months.longhand[3] = '4'
english.months.longhand[4] = '5'
english.months.longhand[5] = '6'
english.months.longhand[6] = '7'
english.months.longhand[7] = '8'
english.months.longhand[8] = '9'
english.months.longhand[9] = '10'
english.months.longhand[10] = '11'
english.months.longhand[11] = '12'

type CalendarProps = {
  onChange: (startDate: Date, endDate: Date) => void
  startDate?: Date
  endDate?: Date
  todayDate?: Date
  mode?: 'range' | 'single'
}

export const Calendar: React.FC<CalendarProps> = ({
  onChange,
  startDate,
  endDate,
  todayDate,
  mode = 'range',
}) => {
  const { i18n } = useTranslation()
  const { isGameDay } = useGameDays()

  const jstTodayDate = todayDate
    ? utcToZonedTime(todayDate, TIMEZONE)
    : parseISO(getCurrentJstDate())

  return (
    <Flatpickr
      className={i18n.language === 'ja' ? 'languageJa' : ''}
      onChange={(dates) => onChange(dates[0], dates[1])}
      onDayCreate={(
        dObj,
        dStr,
        fp,
        dayElem: HTMLSpanElement & { dateObj: Date }
      ) => {
        const isSkipDay = [
          'nextMonthDay',
          'prevMonthDay',
          'flatpickr-disabled',
        ].some((skipClass) => dayElem.classList.contains(skipClass))
        if (isSkipDay) {
          return
        }

        if (isGameDay(utcToZonedTime(dayElem.dateObj, TIMEZONE))) {
          dayElem.classList.add('highlightDay')
        }
      }}
      options={{
        minDate: jstTodayDate,
        maxDate: addYears(jstTodayDate, 1),
        inline: true,
        mode,
        monthSelectorType: 'static',
        locale: english,
        defaultDate: !startDate || !endDate ? [] : [startDate, endDate],
        now: jstTodayDate,
      }}
    />
  )
}
