import { ReactElement, Ref, forwardRef } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'

import { RadioGroup, RadioGroupProps } from './Radio'

type FormRadioProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & RadioGroupProps

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _FormRadioGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormRadioProps<TFieldValues, TName>,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    field: { name, onChange, value },
  } = useController(props)
  const { children, disabled } = props

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      ref={ref}
    >
      {children}
    </RadioGroup>
  )
}

export const FormRadioGroup = forwardRef(_FormRadioGroup) as <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormRadioProps<TFieldValues, TName> & {
    ref?: Ref<HTMLDivElement>
  }
) => ReactElement
