import { useCallback } from 'react'
import { generatePath, useLocation, useSearchParams } from 'react-router-dom'

import { PathParams, useRouteParams } from '../useRouteParams'
import { SearchParam, updateSearchParams } from '../useSearchParams'

type ParamProps = {
  pathParams?: PathParams
  queryParams?: SearchParam
  clearQueryParams?: boolean
}

const allowedQueryParamKeys = [
  'check_in',
  'check_out',
  'rooms',
  'adults',
  'children',
  'infants',
  'page',
  'order',
  'room_order',
  'plan_order',
  'show_all',
  'facility',
  'transaction',
]

export const useCreateNextPath = () => {
  const routeParams = useRouteParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const onCreateNextPath = useCallback(
    (path: string, params?: ParamProps) => {
      const search =
        params && params.queryParams
          ? `?${updateSearchParams(
              searchParams,
              params.queryParams
            ).toString()}`
          : location.search

      const urlSearchParams = new URLSearchParams(
        Array.from(new URLSearchParams(search)).filter(([key]) =>
          allowedQueryParamKeys.includes(key)
        )
      )
      if (params && params.clearQueryParams) {
        return {
          pathname: generatePath(path, {
            ...routeParams,
            ...params?.pathParams,
          }),
          search: '',
        }
      }

      return {
        pathname: generatePath(path, { ...routeParams, ...params?.pathParams }),
        search: urlSearchParams.toString(),
      }
    },
    [routeParams, location, searchParams]
  )

  return { params: routeParams, onCreateNextPath }
}
