import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './RegistrationCompletionFrame.module.scss'

import { ReservationResponse } from '../../apis/fvillage'
import {
  ButtonWrapper as Button,
  CancelPolicySection,
  ConfirmationCode,
  Loader,
  MainColumn,
  MultiColumn,
  NotesSection,
  RegistrationStepper,
  ReservationLayout,
  ReservationPanel,
  SideColumn,
  ThanksSection,
} from '../../components'
import { ContactSection } from '../../components/registrations/ContactSection'
import { PATH } from '../../constants'
import {
  useAuthProvider,
  useCreateNextPath,
  usePublication,
  useReservationApi,
  useReservationPath,
  useReservationState,
} from '../../hooks'

const translateNamespace = 'frames.RegistrationCompletionFrame'

const useDefaultValue = (reservation?: ReservationResponse) =>
  useMemo(() => {
    const isHotel = reservation?.transaction?.input?.bookingEngineHotel?.isHotel
    const cancelPolicy =
      reservation?.transaction?.input?.cancelPolicy ?? undefined

    const {
      checkInDate,
      checkOutDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      roomCount,
    } = reservation?.transaction?.input ?? {}
    const property = reservation?.transaction?.input?.bookingEngineHotel?.slug
    const room = reservation?.transaction?.input?.bookingEngineRoom?.slug
    const plan = reservation?.transaction?.input?.bookingEnginePlan?.slug

    return {
      isHotel,
      cancelPolicy,
      checkInDate,
      checkOutDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      roomCount,
      property,
      room,
      plan,
    }
  }, [reservation])

export const RegistrationCompletionFrame = () => {
  const { t, i18n } = useTranslation()
  const { token } = useAuthProvider()
  const { reservationState } = useReservationState()
  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()

  useEffect(() => {
    // Avoid following rendering warnings.
    // Cannot update a component (`BrowserRouter`) while rendering a different
    // component (`RegistrationConfirmationFrame`).
    // To locate the bad setState() call inside `RegistrationConfirmationFrame`
    if (!reservationState.reservationId || !reservationState.confirmationCode) {
      // If guest reload this page or directly accessed,
      // reservation is not filled, so redirect to root page.
      navigate(onCreateNextPath(PATH.root))
    }
  }, [
    navigate,
    onCreateNextPath,
    reservationState.reservationId,
    reservationState.confirmationCode,
  ])
  const { reservation } = useReservationApi({
    id: reservationState.reservationId,
    token,
  })

  const publication = usePublication(
    i18n.language,
    reservation?.transaction?.input?.bookingEngineHotel?.publications
  )
  const reservationPath = useReservationPath()

  const {
    cancelPolicy,
    checkInDate,
    checkOutDate,
    isHotel,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    plan,
    property,
    room,
    roomCount,
  } = useDefaultValue(reservation)

  if (!reservation) {
    return <Loader />
  }

  return (
    <ReservationLayout pageTitle={t(`${translateNamespace}.pageTitle`)}>
      <RegistrationStepper
        current={3}
        stepName={t(`${translateNamespace}.stepper`)}
      />
      <MultiColumn>
        <MainColumn>
          <div className={styles.main}>
            <ThanksSection />

            <ConfirmationCode
              confirmationCode={reservationState.confirmationCode}
            />

            <Button
              htmlType="button"
              position="center"
              style={{ marginTop: '6rem', marginBottom: '8rem' }}
              size="large"
              onClick={() => {
                const nextPath = onCreateNextPath(reservationPath.reservation, {
                  pathParams: { reservation: reservationState.reservationId },
                  clearQueryParams: true,
                })
                navigate(nextPath)
              }}
            >
              {t(`${translateNamespace}.gotoHistory`)}
            </Button>

            <CancelPolicySection cancelPolicy={cancelPolicy} />
            {publication?.notice && <NotesSection note={publication.notice} />}
            {publication?.contact && (
              <ContactSection contact={publication.contact} />
            )}

            <Button
              htmlType="button"
              variant="primary"
              position="center"
              style={{ marginTop: '4rem' }}
              size="large"
              onClick={() => {
                navigate('/')
              }}
            >
              {t(`${translateNamespace}.backToTop`)}
            </Button>
          </div>
        </MainColumn>
        <SideColumn>
          {reservationState.reservationId ? (
            <ReservationPanel
              isHotel={isHotel}
              reservationId={reservationState.reservationId}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              numberOfAdults={numberOfAdults}
              numberOfChildren={numberOfChildren}
              numberOfInfants={numberOfInfants}
              roomCount={roomCount}
              property={property}
              room={room}
              plan={plan}
              transactionId={reservation.transaction?.id}
            />
          ) : (
            <Loader />
          )}
        </SideColumn>
      </MultiColumn>
    </ReservationLayout>
  )
}
