import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './NotesSection.module.scss'

import { Section, Text } from '../../basics'

type NotesSectionProps = {
  note: string
}

const translateNamespace = 'frames.RegistrationCompletionFrame'

export const NotesSection: React.FC<NotesSectionProps> = ({ note }) => {
  const { t } = useTranslation()
  return (
    <Section title={t(`${translateNamespace}.noteTitle`)}>
      <div className={clsx(styles.notes)}>
        <Text>{note}</Text>
      </div>
    </Section>
  )
}
