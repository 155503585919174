import * as styles from './Banner.module.scss'

import bannerFurusatoNouzeiBig from '../../../assets/banner-hurusato-nouzei-big.jpg'
import bannerFurusatoNouzeiSmall from '../../../assets/banner-hurusato-nouzei-small.jpg'
import bannerSummerFes from '../../../assets/banner-summer-fes.jpg'

type BannerHurusatoNouzeiProps = {
  size: 'big' | 'small'
}

export const BannerHurusatoNouzei: React.FC<BannerHurusatoNouzeiProps> = ({
  size,
}) => (
  <a
    className={styles.link}
    href="https://kita-kita-kita.jp/sp-coupon/#f_village"
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={size === 'big' ? bannerFurusatoNouzeiBig : bannerFurusatoNouzeiSmall}
      alt="『ふるさと納税』で賢く 宿泊・温泉・を楽しもう！"
      width="100%"
    />
  </a>
)

export const BannerHurusatoNouzeiBig: React.FC = () => (
  <BannerHurusatoNouzei size="big" />
)

export const BannerHurusatoNouzeiSmall: React.FC = () => (
  <BannerHurusatoNouzei size="small" />
)

export const BannerSummerFes: React.FC = () => (
  <a
    className={styles.link}
    href="https://www.fighters.co.jp/expansion/2024/summerfes/index.html"
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={bannerSummerFes}
      alt="ファイターズの夏を超楽しもう！"
      width="100%"
    />
  </a>
)
