import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionResponse } from '../../../apis/fvillage'
import { Button } from '../../basics'
import { ReservationModal } from '../../modals'

type ReservationDetailButtonProps = {
  transaction?: TransactionResponse
}

export const ReservationDetailButton: React.FC<
  ReservationDetailButtonProps
> = ({ transaction }) => {
  const { t } = useTranslation()

  const [isOpenModal, toggleModal] = useState(false)

  if (!transaction) {
    return null
  }

  return (
    <React.Fragment>
      <Button
        size="small"
        htmlType="button"
        outline
        variant="secondary"
        onClick={() => toggleModal(true)}
      >
        {t(`components.ReservationPanel.detail`)}
      </Button>
      <ReservationModal
        isOpen={isOpenModal}
        onClose={() => toggleModal(false)}
        showGuest
        transaction={transaction}
      />
    </React.Fragment>
  )
}
