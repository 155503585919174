import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Notification.module.scss'

import { BookingEngineNotification } from '../../../apis/fvillage'
import { BaseText, Flex, FlexBox } from '../../basics'

type NotificationProps = {
  notifications: BookingEngineNotification[]
}

const styleTypes = {
  0: styles.info,
  1: styles.warning,
  2: styles.error,
}

export const Notification: React.FC<NotificationProps> = ({
  notifications,
}) => {
  const { i18n } = useTranslation()

  if (!Array.isArray(notifications)) {
    return null
  }

  return (
    <Flex optionClass={styles.notifications}>
      {notifications.map((notification) => (
        <FlexBox
          key={notification.id}
          optionClass={clsx(
            styles.notification,
            styleTypes[notification.noticeType || 0]
          )}
        >
          <BaseText>
            {i18n.language === 'ja'
              ? notification.contentJa
              : notification.contentEn}
          </BaseText>
        </FlexBox>
      ))}
    </Flex>
  )
}
