import React, { useMemo } from 'react'

import clsx from 'clsx'

import * as styles from './Stepper.module.scss'

import { Text } from '../Text'

type StepperProps = {
  steps: number
  current: number
  stepName: string
  color?: 'blue' | 'red'
}

export const Stepper: React.FC<StepperProps> = ({
  steps,
  current,
  stepName,
  color = 'blue',
}) => {
  const styleColor = useMemo(() => {
    if (color === 'red') {
      return styles.stepperColorRed
    }
    return styles.stepperColorBlue
  }, [color])

  return (
    <React.Fragment>
      <div className={clsx(styles.stepperWrapper)}>
        <div className={clsx(styleColor, styles.stepper)}>
          {[...Array(steps)].map((_, index) => {
            const key = `stepper-${index}`
            return (
              <div
                key={key}
                className={clsx(
                  styles.stepperPoint,
                  index < current - 1 && styles.done,
                  index === current - 1 && styles.current
                )}
              >
                <div className={styles.stepperPointElm} />
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.stepName}>
        <Text align="center" weight="bold" size={18}>
          {stepName}
        </Text>
      </div>
    </React.Fragment>
  )
}
