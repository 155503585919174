import React from 'react'

import clsx from 'clsx'

import * as styles from './List.module.scss'

type ListProps<T> = {
  items: T[]
  renderItem: (item: T, i: number) => React.ReactNode
  backgroundColor?: 'gray' | 'black'
  boundaryColor?: 'white' | 'gray'
}

type ListItemProps = {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const List = <T,>({
  items,
  renderItem,
  backgroundColor = 'gray',
  boundaryColor = 'white',
}: ListProps<T>) => {
  if (items.length === 0) {
    return null
  }

  return (
    <ul
      className={clsx({
        [styles.backgroundBlack]: backgroundColor === 'black',
        [styles.backgroundGray]: backgroundColor === 'gray',
        [styles.boundaryWhite]: boundaryColor === 'white',
        [styles.boundaryGray]: boundaryColor === 'gray',
      })}
    >
      {items.map(renderItem)}
    </ul>
  )
}

List.Item = ({ children }: ListItemProps) => (
  <li className={styles.item}>{children}</li>
)
