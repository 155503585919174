import React, { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import {
  ImageResponse,
  PlanResponse,
  PublicationResponse,
} from '../../../apis/fvillage'
import { DEFAULT_TRANSACTION_ID, PATH } from '../../../constants'
import {
  useCreateNextPath,
  useImages,
  useLogin,
  useMeal,
  useOptions,
  usePropertyProvider,
  usePublication,
  useRegistrationState,
  useSearchQueryParams,
  useStorageEnabled,
} from '../../../hooks'
import {
  BaseText,
  ButtonWrapper as Button,
  Col,
  Container,
  Icon,
  Modal,
  Row,
  SlideShow,
} from '../../basics'
import { CancelPolicy } from '../../contents'
import { GalleryModal, LoginModal, PlanDetailModal } from '../../modals'
import * as styles from '../../rooms/RoomItem/RoomItem.module.scss'

type PlanItemProps = PlanResponse

const translateNamespace = 'components.PlanItem'

type UseDefaultValueProps = {
  publications: PublicationResponse[] | undefined
  images: ImageResponse[] | undefined
  mealType: number | null
}

const useDefaultValues = ({
  publications,
  images,
  mealType,
}: UseDefaultValueProps) => {
  const { i18n } = useTranslation()
  const publication = usePublication(i18n.language, publications)
  const itemImages = useImages(images)
  const { meal, withMeal } = useMeal(mealType)

  return useMemo(
    () => ({
      planName: publication?.name ?? '',
      planDescription: publication?.description ?? '',
      planDescriptionDetail: publication?.descriptionDetail ?? '',
      optionalPlan: '',
      itemImages:
        itemImages.length > 0
          ? itemImages
          : [
              {
                pc: { src: '/img/hotel01.jpg' },
                sp: { src: '/img/hotel01.jpg' },
                alt: publication?.name ?? '',
              },
              {
                pc: { src: '/img/hotel02.jpg' },
                sp: { src: '/img/hotel02.jpg' },
                alt: publication?.name ?? '',
              },
            ],
      meal,
      withMeal,
    }),
    [
      publication?.name,
      publication?.description,
      publication?.descriptionDetail,
      itemImages,
      meal,
      withMeal,
    ]
  )
}

export const PlanItem: React.FC<PlanItemProps> = ({
  meal: mealType,
  publications,
  images,
  price = 0,
  cancelPolicy,
  slug = '',
  canReserve = false,
  checkInTimeStart: checkInTimeStartProp = '15:00',
  checkInTimeEnd: checkInTimeEndProp = '24:00',
  checkOutTimeEnd: checkOutTimeEndProp = '10:00',
  checkInTimeStartNote,
  checkInTimeEndNote,
  checkOutTimeEndNote,
  withOptions = true,
}) => {
  const { t, i18n } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isOpenDetailModal, toggleOpenDetailModal] = useState(false)
  const { params } = useSearchQueryParams()
  const { onClear: onClearOptions } = useOptions()
  const { onClearRegistrationStateByKey } = useRegistrationState()
  const { isHotel, isActivity } = usePropertyProvider()
  const storageEnabled = useStorageEnabled()

  const {
    planName,
    planDescription,
    planDescriptionDetail,
    optionalPlan,
    meal,
    withMeal,
    itemImages,
  } = useDefaultValues({
    publications,
    images,
    mealType,
  })
  const publication = usePublication(i18n.language, publications)
  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()
  const { validateToken } = useLogin()
  const [isOpenLoginModal, setLoginModal] = useState(false)
  const [isOpenGalleryModal, toggleGalleryModal] = useState(false)
  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0)
  // price 0 means "stop selling", so not available
  const isAvailable = !!slug && !!canReserve && price > 0

  const { numberOfAdults, numberOfChildren, numberOfInfants, roomCount } =
    params

  const nextPath = withOptions
    ? onCreateNextPath(PATH.options, {
        queryParams: params,
        pathParams: { plan: slug },
      })
    : onCreateNextPath(PATH.registrations.root, {
        queryParams: {
          ...params,
          transaction: DEFAULT_TRANSACTION_ID,
        },
        pathParams: { plan: slug },
      })

  const checkInTimeStart =
    checkInTimeStartNote || checkInTimeStartProp || '15:00'
  const checkInTimeEnd = checkInTimeEndNote || checkInTimeEndProp || '24:00'
  const checkOutTimeEnd = checkOutTimeEndNote || checkOutTimeEndProp || '10:00'

  return (
    /* FIXME use Flex or Grid? */
    <Fragment>
      {isOpenDetailModal && (
        <PlanDetailModal
          isOpen={isOpenDetailModal}
          images={images}
          onClose={() => toggleOpenDetailModal(false)}
          planName={publication?.name ?? ''}
          description={publication?.description ?? ''}
          content={publication?.descriptionDetail ?? ''}
          withMeal={withMeal}
          meal={meal}
          checkIn={`${checkInTimeStart ?? '15:00'} ~ ${
            checkInTimeEnd ?? '24:00'
          }`}
          checkOut={checkOutTimeEnd ?? '10:00'}
          isHotel={isHotel ?? false}
        />
      )}
      {isOpenGalleryModal && (
        <GalleryModal
          isOpen={isOpenGalleryModal}
          onClose={() => toggleGalleryModal(false)}
          items={itemImages}
          initialSlide={galleryInitialSlide}
          renderItem={(item) => <img src={item.pc.src} alt={item.alt} />}
        />
      )}
      <Container>
        <Row gutterWidth={20} gutterHeight={20}>
          <Col pc={6}>
            <SlideShow
              items={itemImages}
              renderItem={(item) => (
                <figure>
                  <img
                    src={item.pc.src}
                    alt={item.alt}
                    className="pc"
                    width="600"
                    height="400"
                  />
                  <img
                    src={item.sp.src}
                    alt={item.alt}
                    className="sp"
                    width="600"
                    height="400"
                  />
                </figure>
              )}
              onTap={(swiper) => {
                setGalleryInitialSlide(swiper.activeIndex)
                toggleGalleryModal(true)
              }}
            />
          </Col>
          <Col pc={6}>
            <div className={styles.item}>
              <div className={styles.itemName}>
                <h6>{planName}</h6>
              </div>
              <div className={styles.itemInfo}>
                {isHotel ? (
                  <React.Fragment>
                    <Icon mark="time">
                      {t(`${translateNamespace}.checkIn`)}: {checkInTimeStart} ~{' '}
                      {checkInTimeEnd}
                    </Icon>
                    <Icon mark="time">
                      {t(`${translateNamespace}.checkOut`)}: ~ {checkOutTimeEnd}
                    </Icon>
                    <Icon mark="meal">
                      {withMeal} {withMeal && meal && <span> （{meal}）</span>}
                    </Icon>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {isActivity && (
                      <Icon mark="info">
                        {t(`${translateNamespace}.goToPayment`)}
                      </Icon>
                    )}
                    {!isActivity && (
                      <Icon mark="time">
                        {t(`${translateNamespace}.availableTime`)}：
                        {checkInTimeStart} ~ {checkOutTimeEnd}
                      </Icon>
                    )}
                  </React.Fragment>
                )}
                <Icon mark="close">
                  <Link
                    to="#"
                    className={styles.link}
                    onClick={(e) => {
                      e.preventDefault()
                      setModalOpen(true)
                    }}
                  >
                    {t(`${translateNamespace}.cancel`)}
                  </Link>
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                  >
                    <CancelPolicy {...cancelPolicy} planName={planName} />
                  </Modal>
                  {optionalPlan && <Icon mark="plus">{optionalPlan}</Icon>}
                </Icon>
              </div>
              {isAvailable && price > 0 && (
                <div className={styles.itemPrice}>
                  <div className={styles.roomsAndGuests}>
                    <BaseText color="gray3" size={12}>
                      {t(`${translateNamespace}.guests`, {
                        numberOfAdults,
                        numberOfChildren,
                        numberOfInfants,
                      })}
                      {isHotel &&
                        `・${t(`${translateNamespace}.rooms`, {
                          rooms: roomCount,
                        })}`}
                    </BaseText>
                  </div>
                  <div className={styles.price}>
                    {t(`${translateNamespace}.price`)}
                    <b className={styles.num}>{price.toLocaleString()}</b>
                    {t(`${translateNamespace}.yen`)}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.desc}>
              <p>{planDescription}</p>
              {(isHotel || planDescriptionDetail) && (
                <Button
                  htmlType="button"
                  outline
                  size="smallWide"
                  variant="secondary"
                  position="center"
                  onClick={() => toggleOpenDetailModal(true)}
                >
                  {t(`${translateNamespace}.detail`)}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.button}>
              <Button
                htmlType="button"
                variant="primary"
                size="full"
                position="right"
                disabled={!isAvailable || !storageEnabled}
                onClick={async () => {
                  // Initialize inquiry form(remove from localStorage)
                  onClearRegistrationStateByKey('specialServiceRequest')
                  // Initialize options(remove sessionStorage)
                  onClearOptions()

                  if (!withOptions) {
                    const validToken = await validateToken()
                    if (!validToken) {
                      setLoginModal(true)
                      return
                    }
                  }

                  navigate(nextPath)
                }}
              >
                {isAvailable
                  ? t(`${translateNamespace}.selectPlan`)
                  : t(`${translateNamespace}.notAvailable`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <LoginModal
        isOpen={isOpenLoginModal}
        onClose={() => setLoginModal(false)}
        nextPath={nextPath.pathname}
      />
    </Fragment>
  )
}
