import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cancelled } from './Cancelled'
import { ConfirmationCode } from './ConfirmationCode'
import { DetailList } from './DetailList'
import { ItemText } from './ItemText'
import { OptionItem } from './OptionItem'

import { ReservationResponse } from '../../../apis/fvillage'
import {
  useMeal,
  usePublication,
  useRoomApi,
  useRoomFeature,
} from '../../../hooks'
import {
  formatJstDate,
  formatRoomSizeUnit,
  formatRoomType,
} from '../../../utils'
import { Col, Container, Icon, Row } from '../../basics'

type ReservationDetailListProps = ReservationResponse

const translateNamespace = `components.ReservationDetail`

export const ReservationDetailList: React.FC<ReservationDetailListProps> = ({
  transaction,
  confirmationCode,
  bookedAt,
  cancelledAt,
  isCancelled,
}) => {
  const { t, i18n } = useTranslation()
  const meals = transaction?.input?.bookingEnginePlan?.meal
  const checkInDate = transaction?.input?.checkInDate
  const checkOutDate = transaction?.input?.checkOutDate
  const roomCount = transaction?.input?.roomCount ?? 1
  const isHotel = transaction?.input?.bookingEngineHotel?.isHotel
  const isActivity = transaction?.input?.bookingEngineHotel?.isActivity
  const checkInTimeStart =
    transaction?.input?.bookingEnginePlan?.checkInTimeStartNote ||
    transaction?.input?.bookingEnginePlan?.checkInTimeStart ||
    (isHotel ? '15:00' : '10:00')
  const checkInTimeEnd =
    transaction?.input?.bookingEnginePlan?.checkInTimeEndNote ||
    transaction?.input?.bookingEnginePlan?.checkInTimeEnd ||
    (isHotel ? '24:00' : '22:00')
  const checkOutTimeEnd =
    transaction?.input?.bookingEnginePlan?.checkOutTimeEndNote ||
    transaction?.input?.bookingEnginePlan?.checkOutTimeEnd ||
    (isHotel ? '10:00' : '22:00')
  const options = transaction?.input?.options ?? []

  const hotel = usePublication(
    i18n.language,
    transaction?.input?.bookingEngineHotel?.publications
  )
  const plan = usePublication(
    i18n.language,
    transaction?.input?.bookingEnginePlan?.publications
  )
  const { withMeal, meal } = useMeal(meals ?? null)
  const room = usePublication(
    i18n.language,
    transaction?.input?.bookingEngineRoom?.publications
  )
  const { data } = useRoomApi({
    property: transaction?.input?.bookingEngineHotel?.slug,
    room: transaction?.input?.bookingEngineRoom?.slug,
  })
  const { canSmoke, petsAllowed, accessible } = useRoomFeature(
    data?.feature ?? ''
  )

  const properties = [
    <Container>
      <Row>
        <Col>
          <ItemText>{hotel?.name}</ItemText>
        </Col>
      </Row>
    </Container>,
  ]
  if (ConfirmationCode && bookedAt) {
    properties.push(
      <ConfirmationCode
        bookedAt={bookedAt}
        confirmationCode={confirmationCode}
      />
    )
  }
  if (isCancelled) {
    properties.push(<Cancelled cancelledAt={cancelledAt} />)
  }

  const plans = [
    <Container>
      <Row>
        <Col>
          <ItemText>{plan?.name}</ItemText>
        </Col>
      </Row>
    </Container>,
  ]
  if (isHotel) {
    plans.push(
      <Container>
        <Row>
          <Col pc={3} sp={6}>
            <ItemText>{t(`${translateNamespace}.checkIn`)}</ItemText>
          </Col>
          <Col pc={3} sp={6}>
            <ItemText pcAlign="left" spAlign="right">
              {checkInDate &&
                formatJstDate(
                  checkInDate,
                  i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
                )}
              <ItemText spAlign="right">
                {checkInTimeStart} ~ {checkInTimeEnd}
              </ItemText>
            </ItemText>
          </Col>
          <Col pc={3} sp={6}>
            <ItemText>{t(`${translateNamespace}.checkOut`)}</ItemText>
          </Col>
          <Col pc={3} sp={6}>
            <ItemText pcAlign="left" spAlign="right">
              {checkOutDate &&
                formatJstDate(
                  checkOutDate,
                  i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
                )}
              <ItemText spAlign="right">~ {checkOutTimeEnd}</ItemText>
            </ItemText>
          </Col>
        </Row>
        <Row>
          <Col>
            <ItemText pcAlign="left" spAlign="left">
              {withMeal} {withMeal && meal && <span> （{meal}）</span>}
            </ItemText>
          </Col>
        </Row>
      </Container>
    )
  } else {
    plans.push(
      <Container>
        <Row>
          <Col pc={3} sp={6}>
            <ItemText>{t(`${translateNamespace}.dateOfUse`)}</ItemText>
          </Col>
          <Col pc={9} sp={6}>
            <ItemText pcAlign="left" spAlign="right">
              {checkInDate &&
                formatJstDate(
                  checkInDate,
                  i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
                )}
              {!isActivity && (
                <ItemText>
                  {' '}
                  {checkInTimeStart} ~ {checkOutTimeEnd}
                </ItemText>
              )}
            </ItemText>
          </Col>
        </Row>
      </Container>
    )
  }

  const roomSize = data?.roomSize ?? 0
  const roomSizeUnit = data?.roomSizeUnit ?? ''
  const roomType = data?.roomType ?? ''
  const minPax = data?.minPax ?? 0
  const maxPax = data?.maxPax ?? 0

  const rooms = [
    <Container>
      <Row>
        <Col>
          <ItemText pcAlign="left" spAlign="right">
            {room?.name || ''} {roomCount || 1}
            {t(`${translateNamespace}.roomCount`)}
          </ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={2} sp={6}>
          <ItemText>
            <Icon mark="capacity">{t(`${translateNamespace}.capacity`)}</Icon>
          </ItemText>
        </Col>
        <Col pc={2} sp={6}>
          <ItemText pcAlign="left" spAlign="right">
            {minPax} ~ {maxPax}
            {t(`${translateNamespace}.peopleUnit`)}
          </ItemText>
        </Col>
        <Col pc={2} sp={6}>
          <ItemText>
            <Icon mark="size">{t(`${translateNamespace}.size`)}</Icon>
          </ItemText>
        </Col>
        <Col pc={2} sp={6}>
          <ItemText pcAlign="left" spAlign="right">
            {t(`${translateNamespace}.about`, {
              number: roomSize,
            })}
            {formatRoomSizeUnit(roomSizeUnit)}
          </ItemText>
        </Col>
        <Col pc={2} sp={6}>
          <ItemText>
            <Icon mark="bed">{t(`${translateNamespace}.roomType`)}</Icon>
          </ItemText>
        </Col>
        <Col pc={2} sp={6}>
          <ItemText pcAlign="left" spAlign="right">
            {formatRoomType(roomType)}
          </ItemText>
        </Col>
      </Row>
      <Row>
        <Col pc={4} sp={12}>
          <ItemText pcAlign="left" spAlign="right">
            {canSmoke ? (
              <Icon mark="smoke">{t(`${translateNamespace}.smoking`)}</Icon>
            ) : (
              <Icon mark="smokeOff">
                {t(`${translateNamespace}.nonSmoking`)}
              </Icon>
            )}
          </ItemText>
        </Col>
        {petsAllowed && (
          <Col pc={4} sp={12}>
            <ItemText pcAlign="left" spAlign="right">
              <Icon mark="dog">{t(`${translateNamespace}.petsAllowed`)}</Icon>
            </ItemText>
          </Col>
        )}
        {accessible && (
          <Col pc={4} sp={12}>
            <ItemText pcAlign="left" spAlign="right">
              <Icon mark="wheelchair">
                {t(`${translateNamespace}.accessible`)}
              </Icon>
            </ItemText>
          </Col>
        )}
      </Row>
    </Container>,
  ]

  const tickets = [
    <Container>
      <Row>
        <Col>
          <ItemText pcAlign="left" spAlign="right">
            {room?.name || ''}
          </ItemText>
        </Col>
      </Row>
    </Container>,
  ]

  const optionsAndTotal = options.map((option) => <OptionItem item={option} />)
  const detailListItems = [
    {
      subHeader: t(`${translateNamespace}.property`),
      items: properties,
      key: 'reservationDetailProperty',
    },
    {
      subHeader: t(`${translateNamespace}.plan`),
      items: plans,
      key: 'reservationDetailPlan',
    },
  ]
  if (isHotel) {
    detailListItems.push({
      subHeader: t(`${translateNamespace}.room`),
      items: rooms,
      key: 'reservationDetailRoom',
    })
  } else {
    detailListItems.push({
      subHeader: t(`${translateNamespace}.ticket`),
      items: tickets,
      key: 'reservationDetailRoom',
    })
  }
  if (optionsAndTotal.length) {
    detailListItems.push({
      subHeader: t(`${translateNamespace}.option`),
      items: optionsAndTotal,
      key: 'reservationDetailOption',
    })
  }

  return (
    <DetailList
      header={t(`${translateNamespace}.reservationDetail`)}
      listItems={detailListItems}
    />
  )
}
