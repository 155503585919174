import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { PropertyList } from './PropertyList'
import { ReservationButton } from './ReservationButton'
import * as styles from './TopIndex.module.scss'

import {
  BannerHurusatoNouzeiBig,
  BannerHurusatoNouzeiSmall,
  CalendarTable,
  Layout,
  News,
  Notification,
} from '../../components'
import { useNewsApi, useNotificationsApi } from '../../hooks'

const translateNamespace = 'components.TopIndexFrame.sentence'

export const TopIndexFrame = () => {
  const { t } = useTranslation()
  const { data: notifications } = useNotificationsApi()
  const { data: news } = useNewsApi()

  return (
    <Layout>
      <div className={clsx(styles.centerArea, styles.main)}>
        <div className={styles.textInner}>
          <div className={styles.textHeading}>
            {t(`${translateNamespace}.main1`)}
            <br />
            {t(`${translateNamespace}.main2`)}
          </div>
          <div className={styles.textDescription}>
            {t(`${translateNamespace}.sub1`)}
          </div>
        </div>
      </div>

      {((notifications && notifications?.length > 0) ||
        (news && news?.contentJa)) && (
        <div className={styles.notificationArea}>
          {notifications && notifications.length > 0 && (
            <Notification notifications={notifications} />
          )}
          {news && news.contentJa && <News news={news} />}
        </div>
      )}

      <div className={styles.centerArea}>
        <div>
          <PropertyList />
        </div>
        <div className={styles.calendarTableArea}>
          <CalendarTable />
        </div>
        <div className={styles.bannerArea}>
          <div className={styles.pc}>
            <BannerHurusatoNouzeiBig />
          </div>
          <div className={styles.sp}>
            <BannerHurusatoNouzeiSmall />
          </div>
        </div>
      </div>
      <ReservationButton />
    </Layout>
  )
}
