import * as styles from './PageMessage.module.scss'

import { BaseText } from '../../basics'

type PageMessageProps = {
  children: React.ReactNode
}

export const PageMessage: React.FC<PageMessageProps> = ({ children }) => (
  <div className={styles.pageMessage}>
    <BaseText color="red1" align="center" size={14}>
      {children}
    </BaseText>
  </div>
)
