import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './PlanIndex.module.scss'

import { PATH } from '../../../constants'
import {
  useCreateNextPath,
  usePlansApi,
  useSearchQueryParams,
  useStorageEnabled,
} from '../../../hooks'
import { Sort, Text } from '../../basics'
import { ErrorCard, Loader, NotFound, ShowAllToggle } from '../../contents'
import { PlanItem } from '../PlanItem'

const translateNamespace = 'components.Sort'

// FIXME style
export const PlanIndex: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    params: { property, room },
    onCreateNextPath,
  } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { data: plans, isLoading } = usePlansApi({
    property,
    room,
    ...queryParams,
    showAllPlans: queryParams.showAll,
  })
  const storageEnabled = useStorageEnabled()

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      {!storageEnabled && (
        <ErrorCard>
          <Text>
            <Trans
              i18nKey="components.PlanIndex.storageError"
              t={t}
              components={{
                sitePolicy: (
                  <a // eslint-disable-line jsx-a11y/anchor-has-content
                    className={styles.link}
                    href="https://www.hkdballpark.com/sitepolicy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="site policy"
                  />
                ),
              }}
            />
          </Text>
        </ErrorCard>
      )}
      <Sort
        count={plans?.length ?? 0}
        onChange={(e) => {
          const nextPath = onCreateNextPath(PATH.plans, {
            queryParams: { planOrder: e.target.value },
          })
          navigate(nextPath)
        }}
        defaultValue={queryParams.planOrder ?? 'price'}
        noMargin
      >
        <option value="price">
          {t(`${translateNamespace}.list.ascPrice`)}
        </option>
        <option value="-price">
          {t(`${translateNamespace}.list.descPrice`)}
        </option>
      </Sort>
      <ShowAllToggle />
      <div className="contents-results">
        <ul>
          {Array.isArray(plans) && plans.length > 0 ? (
            plans.map((plan) => (
              <li className={styles.item1} key={plan.id}>
                <PlanItem {...plan} />
              </li>
            ))
          ) : (
            <NotFound
              title={t(`empty.planNotFound.attention`)}
              description1={t(`empty.planNotFound.direction`)}
            />
          )}
        </ul>
      </div>
    </React.Fragment>
  )
}
