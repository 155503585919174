import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { DetailList } from './DetailList'
import * as styles from './ReservationDetail.module.scss'

import { Text } from '../../basics'

type NoteProps = {
  note: string
}

const translateNamespace = `components.ReservationDetail`

export const NoteDetailList: React.FC<NoteProps> = ({ note }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.detailList}>
      <div className={clsx(styles.spacer, styles.noteWrapper)}>
        <DetailList
          header={t(`${translateNamespace}.notes`)}
          listItems={[
            {
              key: note,
              items: [
                <div className={styles.note}>
                  <Text key={note}>{note}</Text>
                </div>,
              ],
            },
          ]}
        />
      </div>
    </div>
  )
}
