import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, UseFormSetError, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { getYear } from 'date-fns'

import styles from './ReservationNewFrame.module.scss'

import {
  Button,
  ErrorCard,
  FormNote,
  Loader,
  MainColumn,
  MultiColumn,
  RegistrationForm,
  RegistrationStepper,
  ReservationLayout,
  ReservationPanel,
  SideColumn,
  Text,
} from '../../components'
import {
  DEFAULT_TRANSACTION_ID,
  PATH,
  REGISTRATION_PATH,
} from '../../constants'
import {
  useAuthProvider,
  useCreateNextPath,
  useCreateTransactionApi,
  useCreditCardState,
  useGMOMultiPaymentApi,
  useLogin,
  useOptions,
  usePropertyProvider,
  useRegistrationState,
  useReservationsRecentApi,
  useSearchQueryParams,
  useTransactionApi,
  useUpdateTransactionInputApi,
} from '../../hooks'
import { Registration, registrationSchema } from '../../schemas'
import { canonicalizeHyphen, getCurrentJstDateTime } from '../../utils'

const translateNamespace = 'frames.RegistrationNewFrame'

type UseHandleSubmit = {
  setLoading: (value: boolean) => void
  setError: UseFormSetError<Registration>
  setPageError: (value: boolean) => void
  isReady: boolean
  transactionId?: number
}

const useSetError = (setError: UseFormSetError<Registration>) => {
  const { t } = useTranslation()
  const onError = useCallback(
    (error: string) => {
      switch (error) {
        case '100': // Cardno error
        case '101': // Card format error
        case '102': // Card range error
          setError('cardNumber', {
            message: t(`${translateNamespace}.error.cardno`),
          })
          break
        case '110':
        case '111':
        case '112':
          setError('expirationYear', {
            message: t(`${translateNamespace}.error.expirationYear`),
          })
          break
        case '113':
          setError('expirationMonth', {
            message: t(`${translateNamespace}.error.expirationMonth`),
          })
          break
        case '121': // CVC error
        case '122': // CVC range error
          setError('securityCode', {
            message: t(`${translateNamespace}.error.securityCode`),
          })
          break
        case '131': // Holder name
        case '132': // Holder name range error
          setError('holderName', {
            message: t(`${translateNamespace}.error.holderName`),
          })
          break
        default:
          break
      }
    },
    [setError, t]
  )

  return { onError }
}

const useHandleSubmit = ({
  setLoading,
  setError,
  setPageError,
  isReady,
  transactionId,
}: UseHandleSubmit) => {
  const { token } = useAuthProvider()
  const navigate = useNavigate()
  const { onUpdateRegistrationState } = useRegistrationState()
  const { onUpdateCreditCardState } = useCreditCardState()
  const { trigger: updateTransaction } = useUpdateTransactionInputApi(token)
  const { onGetToken } = useGMOMultiPaymentApi()
  const { onCreateNextPath } = useCreateNextPath()
  const { onError } = useSetError(setError)

  const onHandleSubmit = useCallback(
    async (formValues: Registration) => {
      if (!isReady) {
        return
      }
      setLoading(true)

      const card = {
        cardno: canonicalizeHyphen(formValues.cardNumber),
        expire: `${formValues.expirationYear}${formValues.expirationMonth}`,
        holdername: formValues.holderName,
        securitycode: formValues.securityCode,
      }
      try {
        const { resultCode, tokenObject } = await onGetToken(card)
        if (resultCode && transactionId) {
          if (resultCode.toString() !== '000') {
            setLoading(false)
            // TODO Error handling
            onError(resultCode.toString())
            return
          }
          // Save personal information to localstorage
          onUpdateRegistrationState(formValues)
          // Save card number, expire dates, holder name to in memory.
          onUpdateCreditCardState({
            cardno: card.cardno,
            expirationMonth: formValues.expirationMonth,
            expirationYear: formValues.expirationYear,
            holderName: card.holdername,
          })

          const res = await updateTransaction({
            transactionId,
            input: {
              address: `${formValues.postalCode} ${formValues.prefecture} ${formValues.city} ${formValues.street}`,
              email: formValues.email,
              gmoToken: tokenObject.token[0],
              gmoTokenExpiredAt: tokenObject.toBeExpiredAt,
              kana: formValues.kana,
              name: formValues.name,
              phone: formValues.phone,
              postalCode: formValues.postalCode,
              specialServiceRequest: formValues.specialServiceRequest,
              dateOfBirth:
                formValues.birthYear !== '' &&
                formValues.birthMonth !== '' &&
                formValues.birthDay
                  ? `${formValues.birthYear}-${formValues.birthMonth}-${formValues.birthDay}`
                  : undefined,
            },
          })
          if (!res || res.status !== 204) {
            setPageError(true)
            window.scrollTo(0, 0)
            return
          }
          navigate(
            onCreateNextPath(REGISTRATION_PATH.confirmation, {
              queryParams: { transaction: transactionId },
            })
          )
        }
      } catch (e) {
        setPageError(true)
        window.scrollTo(0, 0)
      } finally {
        setLoading(false)
      }
    },
    [
      setLoading,
      navigate,
      onCreateNextPath,
      onGetToken,
      onUpdateRegistrationState,
      onUpdateCreditCardState,
      updateTransaction,
      onError,
      setPageError,
      isReady,
      transactionId,
    ]
  )

  return { onHandleSubmit }
}

const useDefaultValues = () => {
  const { registrationState } = useRegistrationState()
  const { creditCardState } = useCreditCardState()

  const currentDate = getCurrentJstDateTime()
  const year = getYear(currentDate)

  // Get personal information from fid(OpenID Connect) data.
  // Prefer user-entered values(saved at localstorage).
  const auth = useAuthProvider()
  const {
    name,
    kana,
    phone,
    postalCode,
    prefecture,
    city,
    street,
    email,
    specialServiceRequest,
    birthYear,
    birthMonth,
    birthDay,
  } = registrationState

  return useMemo(() => {
    const [authBirthYear, authBirthMonth, authBirthDay] =
      auth.dateOfBirth.split('-')
    return {
      token: auth.token,
      defaultValues: {
        name: name || auth.name,
        kana: kana || auth.kana,
        phone: phone || auth.phone,
        postalCode: postalCode || auth.postalCode,
        prefecture: prefecture || auth.prefecture || '北海道',
        city: city || auth.address1,
        street: street || `${auth.address2} ${auth.address3}`,
        email: email || auth.email,
        cardNumber: creditCardState.cardno || '',
        holderName: creditCardState.holderName || '',
        expirationMonth: creditCardState.expirationMonth || '01',
        expirationYear: creditCardState.expirationYear || year.toString(),
        securityCode: '',
        specialServiceRequest: specialServiceRequest || '',
        birthYear: birthYear || authBirthYear || '1980',
        birthMonth: birthMonth || authBirthMonth || '01',
        birthDay: birthDay || authBirthDay || '01',
      },
    }
  }, [
    name,
    kana,
    phone,
    postalCode,
    prefecture,
    city,
    street,
    email,
    specialServiceRequest,
    auth.name,
    auth.email,
    auth.address1,
    auth.address2,
    auth.address3,
    auth.kana,
    auth.phone,
    auth.postalCode,
    auth.prefecture,
    auth.token,
    auth.dateOfBirth,
    year,
    creditCardState.cardno,
    creditCardState.expirationMonth,
    creditCardState.expirationYear,
    creditCardState.holderName,
    birthYear,
    birthMonth,
    birthDay,
  ])
}

export const RegistrationNewFrame = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { params, onCreateNextPath } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { token, defaultValues } = useDefaultValues()
  const { isHotel, isSpa, isActivity } = usePropertyProvider()
  const { trigger: createTransaction } = useCreateTransactionApi(token)
  const [isPriceLoading, setPriceLoading] = useState(true)
  const { onLogin } = useLogin()
  const [isLoading, setLoading] = useState(false)
  const { onGet: onGetOptions } = useOptions()
  const options = onGetOptions()
  const [isPriceLoaded, setPriceLoaded] = useState(false)
  const [transactionId, setTransactionId] = useState<number | undefined>(
    queryParams.transaction
  )
  const { data: transaction } = useTransactionApi(transactionId, token)
  const isReserved = Number.isFinite(transaction?.reservationId)

  const ref = useRef(true)
  useEffect(() => {
    // To avoid multiple executions
    if (!ref.current || transactionId !== DEFAULT_TRANSACTION_ID) {
      setPriceLoading(false)

      // Update transaction ID in query parameter
      const path = onCreateNextPath(PATH.registrations.root, {
        queryParams: { transaction: transactionId },
      })
      window.history.replaceState(null, '', `${path.pathname}?${path.search}`)

      return
    }
    ref.current = false
    // At first, call transaction api to create transaction.
    // (It's like server-side session)
    // When guest click confirm button, call transaction input api to save
    // guest inputted values.
    createTransaction({
      bookingEngineHotel: params.property,
      bookingEnginePlan: params.plan,
      bookingEngineRoom: params.room,
      checkInDate: queryParams.checkInDate,
      checkOutDate: isHotel
        ? queryParams.checkOutDate
        : queryParams.checkInDate,
      numberOfAdults: queryParams.numberOfAdults,
      numberOfChildren: queryParams.numberOfChildren,
      numberOfInfants: queryParams.numberOfInfants,
      roomCount: queryParams.roomCount,
      options,
    })
      .then((res) => {
        if (res?.status === 401) {
          onLogin()
          return
        }
        // If transaction was created then call price api.
        // If price called before transaction created, returns 404 error.
        setPriceLoading(false)
        setTransactionId(res?.data.id)
      })
      .catch((e) => {
        if (isAxiosError(e) && e.response?.status === 401) {
          onLogin()
        }
      })
  }, [
    createTransaction,
    params.property,
    params.room,
    params.plan,
    queryParams.checkInDate,
    queryParams.checkOutDate,
    queryParams.numberOfAdults,
    queryParams.numberOfChildren,
    queryParams.numberOfInfants,
    queryParams.roomCount,
    onLogin,
    options,
    transactionId,
    onCreateNextPath,
    isHotel,
  ])

  const formMethods = useForm<Registration>({
    defaultValues,
    resolver: zodResolver(registrationSchema),
  })
  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods
  const isError = Object.keys(errors).length > 0

  const [isPageError, setPageError] = useState(false)

  const [existsRecent, setExistsRecent] = useState(false)
  const { data } = useReservationsRecentApi({
    token,
    isHotel: isHotel ?? false,
    isSpa: isSpa ?? false,
    isActivity: isActivity ?? false,
  })
  useEffect(() => {
    if ((data?.count ?? 0) > 0) {
      setExistsRecent(true)
    }
  }, [data])

  const { onHandleSubmit } = useHandleSubmit({
    setLoading,
    setError,
    setPageError,
    isReady: isPriceLoaded,
    transactionId,
  })
  const onSubmit = handleSubmit(onHandleSubmit)

  const reservationPath = useMemo(() => {
    if (isSpa) {
      return PATH.reservations.spas
    }
    if (isActivity) {
      return PATH.reservations.activities
    }
    return PATH.reservations.hotels
  }, [isSpa, isActivity])

  if (isLoading) {
    return (
      <Loader>
        <Text align="center">
          <Trans i18nKey={`${translateNamespace}.loading`} />
        </Text>
      </Loader>
    )
  }

  return (
    <ReservationLayout pageTitle={t(`${translateNamespace}.pageTitle`)}>
      <RegistrationStepper
        current={1}
        stepName={t(`${translateNamespace}.stepper`)}
      />
      <FormProvider {...formMethods}>
        <MultiColumn isSpColumnReverse>
          <MainColumn>
            {isPageError && (
              <ErrorCard>
                <Text>
                  <Trans
                    i18nKey={`${translateNamespace}.error.confirmationError`}
                  >
                    Sorry, An error has occurred We apologize for the
                    inconvenience.
                    <br />
                    Please change your search conditions and try again.
                  </Trans>
                </Text>
              </ErrorCard>
            )}
            {isReserved && transaction?.reservationId && (
              <ErrorCard>
                <Text>
                  <Trans
                    i18nKey={`${translateNamespace}.reserved`}
                    t={t}
                    components={{
                      reservationLink: (
                        <Link
                          className={styles.link}
                          to={onCreateNextPath(reservationPath.reservation, {
                            pathParams: {
                              reservation: transaction.reservationId.toString(),
                            },
                            clearQueryParams: true,
                          })}
                        />
                      ),
                    }}
                  />
                </Text>
              </ErrorCard>
            )}
            {!isReserved && existsRecent && (
              <ErrorCard warning>
                <Text>
                  <Trans
                    i18nKey={`${translateNamespace}.error.exists`}
                    t={t}
                    components={{
                      reservationsLink: (
                        <Link
                          className={styles.link}
                          to={reservationPath.root}
                        />
                      ),
                    }}
                  />
                </Text>
              </ErrorCard>
            )}
            <FormNote>
              <Text>{t(`${translateNamespace}.note`)}</Text>
            </FormNote>
            <form onSubmit={onSubmit}>
              <RegistrationForm disabledInquiryForm={isActivity} />
              <MultiColumn isSpColumnReverse>
                <Button
                  htmlType="button"
                  variant="tertiary"
                  outline
                  size="large"
                  onClick={() => {
                    if (options.length > 0) {
                      navigate(onCreateNextPath(PATH.options))
                      return
                    }
                    navigate(onCreateNextPath(PATH.plans))
                  }}
                >
                  {options.length > 0
                    ? t(`${translateNamespace}.backToOption`)
                    : t(`${translateNamespace}.backToPlan`)}
                </Button>
                <Button
                  htmlType="submit"
                  variant="primary"
                  size="large"
                  disabled={isError || !isPriceLoaded || isReserved}
                >
                  {t(`${translateNamespace}.confirm`)}
                </Button>
              </MultiColumn>
            </form>
          </MainColumn>
          <SideColumn>
            {isPriceLoading ? (
              <Loader />
            ) : (
              <ReservationPanel
                isHotel={isHotel}
                showDiscountCode={!isReserved}
                checkInDate={queryParams.checkInDate}
                checkOutDate={queryParams.checkOutDate}
                numberOfAdults={queryParams.numberOfAdults}
                numberOfChildren={queryParams.numberOfChildren}
                numberOfInfants={queryParams.numberOfInfants}
                roomCount={queryParams.roomCount}
                property={params.property}
                room={params.room}
                plan={params.plan}
                transactionId={transactionId}
                onApiError={(_error) => {
                  setPageError(true)
                  window.scrollTo(0, 0)
                }}
                onPriceDidLoad={() => {
                  setPriceLoaded(true)
                }}
              />
            )}
          </SideColumn>
        </MultiColumn>
      </FormProvider>
    </ReservationLayout>
  )
}
