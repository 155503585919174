import { useTranslation } from 'react-i18next'

import {
  ReservationHistoryLayout,
  ReservationIndex,
  ReservationIndexProps,
} from '../../components'
import { useAuthProvider } from '../../hooks'

const translateNamespace = 'frames.ReservationIndexFrame'

type ReservationIndexFrameProps = ReservationIndexProps

export const ReservationIndexFrame: React.FC<ReservationIndexFrameProps> = ({
  reservationType,
}) => {
  const { t } = useTranslation()

  const { name } = useAuthProvider()

  return (
    <ReservationHistoryLayout
      pageTitle={t(`${translateNamespace}.pageTitle`)}
      reservationName={name}
    >
      <ReservationIndex reservationType={reservationType} />
    </ReservationHistoryLayout>
  )
}
