import { useMemo } from 'react'

import clsx from 'clsx'

import { capitalize } from './formatters'
import * as AlignStyles from './styles/TextAlign.module.scss'
import * as BackColorStyles from './styles/TextBackColor.module.scss'
import * as ColorStyles from './styles/TextColor.module.scss'
import * as FontStyles from './styles/TextFont.module.scss'

export const fontSize = [10, 12, 14, 16, 18, 20, 22, 24] as const
export const textAlign = ['left', 'center', 'right'] as const
export const fontColor = [
  'black0',
  'black1',
  'blue0',
  'blue1',
  'blue2',
  'blue3',
  'gray0',
  'gray1',
  'gray2',
  'gray3',
  'red0',
  'red1',
  'white',
] as const
export const backgroundColor = [
  'black0',
  'black1',
  'blue0',
  'blue1',
  'blue2',
  'blue3',
  'gray0',
  'gray1',
  'gray2',
  'gray4',
  'red0',
  'red1',
  'white',
] as const
export const fontStyleValues = ['normal', 'italic'] as const
export const fontWeight = ['normal', 'bold'] as const

type FontSize = (typeof fontSize)[number]
type Align = (typeof textAlign)[number]

type BaseTextStyleProps = {
  size?: FontSize
  pcSize?: FontSize
  spSize?: FontSize
  color?: (typeof fontColor)[number]
  background?: (typeof backgroundColor)[number]
  align?: Align
  pcAlign?: Align
  spAlign?: Align
  fontStyle?: (typeof fontStyleValues)[number]
  weight?: (typeof fontWeight)[number]
  inline?: boolean
}

export type BaseTextProps = BaseTextStyleProps & {
  children: React.ReactNode
}

const useStyle = ({
  size,
  pcSize,
  spSize,
  color,
  background,
  align,
  pcAlign,
  spAlign,
  fontStyle,
  weight,
  inline,
}: BaseTextStyleProps) =>
  useMemo(
    () => ({
      classSize: size && FontStyles[`size${size}`],
      classPcSize: pcSize && FontStyles[`pcSize${pcSize}`],
      classSpSize: spSize && FontStyles[`spSize${spSize}`],
      classColor:
        color &&
        ColorStyles[`color${capitalize(color)}` as keyof typeof ColorStyles],
      classBackground:
        background &&
        BackColorStyles[
          `bg${capitalize(background)}` as keyof typeof BackColorStyles
        ],
      classAlign:
        align &&
        AlignStyles[`align${capitalize(align)}` as keyof typeof AlignStyles],
      classPcAlign:
        pcAlign &&
        AlignStyles[
          `pcAlign${capitalize(pcAlign)}` as keyof typeof AlignStyles
        ],
      classSpAlign:
        spAlign &&
        AlignStyles[
          `spAlign${capitalize(spAlign)}` as keyof typeof AlignStyles
        ],
      classStyle:
        fontStyle &&
        FontStyles[`style${capitalize(fontStyle)}` as keyof typeof FontStyles],
      classWeight:
        weight &&
        FontStyles[`weight${capitalize(weight)}` as keyof typeof FontStyles],
      classInline: inline && FontStyles.inline,
    }),
    [
      size,
      pcSize,
      spSize,
      color,
      background,
      align,
      pcAlign,
      spAlign,
      fontStyle,
      weight,
      inline,
    ]
  )

export const BaseText: React.FC<BaseTextProps> = ({
  size,
  pcSize,
  spSize,
  color,
  background,
  align,
  pcAlign,
  spAlign,
  fontStyle,
  weight,
  inline,
  children,
}) => {
  const {
    classSize,
    classPcSize,
    classSpSize,
    classColor,
    classBackground,
    classAlign,
    classPcAlign,
    classSpAlign,
    classStyle,
    classWeight,
    classInline,
  } = useStyle({
    size,
    pcSize,
    spSize,
    color,
    background,
    align,
    pcAlign,
    spAlign,
    fontStyle,
    weight,
    inline,
  })

  return (
    <div
      className={clsx([
        classSize,
        classPcSize,
        classSpSize,
        classColor,
        classBackground,
        classAlign,
        classPcAlign,
        classSpAlign,
        classStyle,
        classWeight,
        classInline,
      ])}
    >
      {children}
    </div>
  )
}

export type ColoredTextProps = Omit<BaseTextProps, 'color'>

export const Text: React.FC<ColoredTextProps> = (props) => (
  <BaseText color="black1" {...props} />
)

export const WhiteText: React.FC<ColoredTextProps> = (props) => (
  <BaseText color="white" {...props} />
)

export const BlueText: React.FC<ColoredTextProps> = (props) => (
  <BaseText color="blue1" {...props} />
)
