import clsx from 'clsx'

import * as styles from './Flex.module.scss'

export type FlexProps = {
  children: React.ReactNode
  optionClass?: string
}

export const Flex: React.FC<FlexProps> = ({ optionClass, children }) => (
  <div className={clsx(styles.container, optionClass)}>{children}</div>
)

export type FlexBoxProps = {
  children: React.ReactNode
  optionClass?: string
}

export const FlexBox: React.FC<FlexBoxProps> = ({ optionClass, children }) => (
  <div className={clsx(optionClass)}>{children}</div>
)
