import { useState } from 'react'

import clsx from 'clsx'

import * as styles from './Toggle.module.scss'

import { Flex, FlexBox } from '../Flex'

type ToggleProps = {
  defaultIsOn?: boolean
  text: React.ReactNode
  onToggle: (isOn: boolean) => void
}

export const Toggle: React.FC<ToggleProps> = ({
  defaultIsOn = false,
  text,
  onToggle,
}) => {
  const [isOn, setIsOn] = useState(defaultIsOn)
  return (
    <button
      type="button"
      onClick={() => {
        onToggle(!isOn)
        setIsOn(!isOn)
      }}
    >
      <Flex optionClass={styles.flex}>
        <FlexBox>
          <div className={clsx(styles.toggle, isOn && styles.checked)}>
            <input type="checkbox" name="check" />
          </div>
        </FlexBox>
        <FlexBox>{text}</FlexBox>
      </Flex>
    </button>
  )
}
