import React from 'react'

import { PropertyContext } from './PropertyContext'

import { usePropertyApi, useRouteParams } from '../../hooks'

type PropertyProviderProps = {
  children: React.ReactNode
}

export const PropertyProvider: React.FC<PropertyProviderProps> = ({
  children,
}) => {
  const { property } = useRouteParams()
  const { data, isLoading } = usePropertyApi(property)
  if (isLoading || !data) {
    return null
  }

  return (
    <PropertyContext.Provider value={data}>{children}</PropertyContext.Provider>
  )
}
