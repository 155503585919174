import { ReactElement, Ref, forwardRef } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'

import { Textarea, TextareaProps } from './Textarea'

type FormTextareaProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & TextareaProps

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _FormTextarea = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormTextareaProps<TFieldValues, TName>,
  ref?: Ref<HTMLTextAreaElement>
) => {
  const {
    field: { name, onChange, value, onBlur },
  } = useController(props)

  return (
    <Textarea
      {...props}
      ref={ref}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  )
}

export const FormTextarea = forwardRef(_FormTextarea) as <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormTextareaProps<TFieldValues, TName> & {
    ref?: Ref<HTMLTextAreaElement>
  }
) => ReactElement
