import clsx from 'clsx'

// The import/no-cycle error occurs when exporting Pagination with Flex.
// So, don't make shorthands for the following imports.
import * as styles from './MultiColumn.module.scss'

import { Flex, FlexBox } from '../../basics/Flex'

type MultiColumnProps = {
  isSpColumnReverse?: boolean
  children: React.ReactNode
}

export const MultiColumn: React.FC<MultiColumnProps> = ({
  isSpColumnReverse,
  children,
}) => (
  <Flex
    optionClass={clsx(
      styles.container,
      isSpColumnReverse && styles.spColumnReverse
    )}
  >
    {children}
  </Flex>
)

type MainColumnProps = {
  children: React.ReactNode
}

export const MainColumn: React.FC<MainColumnProps> = ({ children }) => (
  <FlexBox optionClass={styles.mainColumn}>{children}</FlexBox>
)

type SideColumnProps = {
  isSticky?: boolean
  children: React.ReactNode
}

export const SideColumn: React.FC<SideColumnProps> = ({
  isSticky,
  children,
}) => (
  <FlexBox optionClass={clsx(styles.sideColumn, isSticky && styles.sticky)}>
    {children}
  </FlexBox>
)
