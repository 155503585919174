import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './CancelPolicy.module.scss'

import { CancelPolicyResponse } from '../../../apis/fvillage'
import { Text } from '../../basics'

type CancelPolicyProps = CancelPolicyResponse & {
  planName?: string
}

const translateNamespace = 'components.CancelPolicy'

export const CancelPolicy: React.FC<CancelPolicyProps> = ({
  additionalCases = [],
  cancellationTodayRate,
  nonRefundable,
  noShowRate,
  planName,
}) => {
  const { t } = useTranslation()
  // If cancelPolicy is null, the cancellation charge cannot be calculated and is non-refundable.
  // When cancelPolicy is null, nonRefundable is undefined, so following bool conditions are made with false.
  return (
    <div className={styles.contents}>
      {planName && (
        <Text size={16} weight="bold">
          {planName}
        </Text>
      )}
      <ul>
        {nonRefundable !== false && (
          <li>
            <div>{t(`${translateNamespace}.nonRefundable`)}</div>
          </li>
        )}
        {nonRefundable === false && (
          <React.Fragment>
            {noShowRate !== null && (
              <li>
                <div className={styles.label}>
                  {t(`${translateNamespace}.noShow`)}:{' '}
                </div>
                <div>
                  {t(`${translateNamespace}.cancelRate`, {
                    rate: noShowRate,
                  })}
                </div>
              </li>
            )}
            {cancellationTodayRate !== null && (
              <li>
                <div className={styles.label}>
                  {t(`${translateNamespace}.forTheDay`)}:{' '}
                </div>
                <div>
                  {t(`${translateNamespace}.cancelRate`, {
                    rate: cancellationTodayRate,
                  })}
                </div>
              </li>
            )}
            {additionalCases.map((policy) => (
              <li key={policy.id}>
                <div className={styles.label}>
                  {t(`${translateNamespace}.cancelDate`, {
                    date: policy.endDays,
                  })}
                  :{' '}
                </div>
                <div>
                  {t(`${translateNamespace}.cancelRate`, {
                    rate: policy.rate,
                  })}
                </div>
              </li>
            ))}
            {additionalCases.slice(-1).map((policy) => (
              <li key={policy.id}>
                <div className={styles.label}>
                  {t(`${translateNamespace}.cancelFreeDate`, {
                    date: (policy.startDays || 0) + 1,
                  })}
                  :{' '}
                </div>
                <div>{t(`${translateNamespace}.noCharge`)}</div>
              </li>
            ))}
          </React.Fragment>
        )}
      </ul>
    </div>
  )
}
