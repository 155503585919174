import { ReactElement, Ref, forwardRef } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'

import { Input, InputProps } from './Input'

type FormInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & InputProps

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _FormInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormInputProps<TFieldValues, TName>,
  ref?: Ref<HTMLInputElement>
) => {
  const {
    field: { name, onChange, value },
  } = useController(props)

  return (
    <Input
      {...props}
      ref={ref}
      name={name}
      inputMode={props.inputMode}
      onChange={(e) => {
        onChange(e)
        props.onChange?.(e)
      }}
      onBlur={props.onBlur}
      value={value}
    />
  )
}

export const FormInput = forwardRef(_FormInput) as <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormInputProps<TFieldValues, TName> & {
    ref?: Ref<HTMLInputElement>
  }
) => ReactElement
