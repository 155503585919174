import React from 'react'

import * as styles from './SortSelect.module.scss'

export type SortSelectProps = {
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  defaultValue?: string
  children: React.ReactNode
}

export const SortSelect: React.FC<SortSelectProps> = ({
  onChange,
  defaultValue,
  children,
}) => (
  <div className={styles.sortSelectWrapper}>
    <select
      name="sort"
      className={styles.sortSelect}
      onChange={onChange}
      value={defaultValue}
    >
      {children}
    </select>
  </div>
)
