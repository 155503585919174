import React from 'react'

import clsx from 'clsx'

import * as styles from './RoomIndexFrame.module.scss'

import {
  BannerHurusatoNouzeiSmall,
  BannerSummerFes,
  FacilitySearchPanel,
  LoginModal,
  MainColumn,
  MultiColumn,
  RoomIndex,
  SearchLayout,
  SearchMainContent,
  SearchPanel,
  SideColumn,
} from '../../components'
import { usePropertyProvider, useStorageEnabled } from '../../hooks'

export const RoomIndexFrame = () => {
  const { isHotel, isActivity } = usePropertyProvider()
  const storageEnabled = useStorageEnabled()
  return (
    <SearchLayout>
      {!storageEnabled && <LoginModal isOpen onClose={() => {}} nextPath="#" />}
      <SearchMainContent>
        <MultiColumn>
          <SideColumn>
            {isHotel ? (
              <SearchPanel />
            ) : (
              <FacilitySearchPanel isActivity={isActivity} />
            )}
            <div className={clsx(styles.bannerArea)}>
              {isActivity ? <BannerSummerFes /> : <BannerHurusatoNouzeiSmall />}
            </div>
          </SideColumn>
          <MainColumn>
            <RoomIndex />
          </MainColumn>
        </MultiColumn>
      </SearchMainContent>
    </SearchLayout>
  )
}
