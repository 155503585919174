import React from 'react'

type TopNavImageProps = {
  color: string
}

export const TopNavImage: React.FC<TopNavImageProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    className={color}
  >
    <g transform="translate(-1200.784 -1086.001)">
      <g data-name="icon　02" transform="translate(3918.783 -6841)">
        <g data-name="グループ 754" transform="translate(-61 -1)">
          <path
            d="M589-1140v-8h4a6.549,6.549,0,0,0-2.346-4.948A8.554,8.554,0,0,0,585-1155c-4.41,0-8,3.141-8,7h4v8H562v-6h6v-31h-6l2-9h42l2,9h-6v31h6v6Zm5-27a3.183,3.183,0,0,0-3,3.333v4h6v-4A3.183,3.183,0,0,0,594-1167Zm-9,0a3.183,3.183,0,0,0-3,3.333v4h6v-4A3.183,3.183,0,0,0,585-1167Zm-9,0a3.183,3.183,0,0,0-3,3.333v4h6v-4A3.182,3.182,0,0,0,576-1167Zm18-10a3.184,3.184,0,0,0-3,3.335v4h6v-4A3.184,3.184,0,0,0,594-1177Zm-9,0a3.184,3.184,0,0,0-3,3.335v4h6v-4A3.184,3.184,0,0,0,585-1177Zm-9,0a3.184,3.184,0,0,0-3,3.335v4h6v-4A3.183,3.183,0,0,0,576-1177Z"
            transform="translate(-3219 9114)"
          />
        </g>
      </g>
    </g>
  </svg>
)
