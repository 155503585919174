import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './FooterListAbove.module.scss'

const translateNamespace = 'components.Footer'

export const FooterListAbove: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ul className={styles.footerLinklist}>
      <li className={styles.footerLinklistItem}>
        <a
          href="https://www.hkdballpark.com/company/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${translateNamespace}.list.company`)}
        </a>
      </li>
      <li className={styles.footerLinklistItem}>
        <a
          href="https://www.hkdballpark.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${translateNamespace}.list.term`)}
        </a>
      </li>
    </ul>
  )
}
