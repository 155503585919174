import { useLocation, useSearchParams } from 'react-router-dom'

import { useLoginCallback } from '../../hooks'

export const LoginCallback = () => {
  const location = useLocation()
  const [searchParam] = useSearchParams(location.search)
  const code = searchParam.get('code')
  useLoginCallback(code)

  return null
}
