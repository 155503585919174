import { Helmet, HelmetTags } from 'react-helmet-async'

import { usePropertyProvider } from '../../../hooks'

const PAYMENT_URL = process.env.VITE_OAUTH_PAYMENT_URL || ''

export const PaymentHead = () => {
  const { gmoShopId } = usePropertyProvider()
  const onLoad = () => {
    window.Multipayment.init(gmoShopId ?? '')
  }

  const onChangeClientState = (newState: unknown, addedTags: HelmetTags) => {
    if (addedTags.scriptTags.length > 0) {
      const scriptTag = addedTags.scriptTags[0]
      scriptTag.onload = onLoad
    }
  }

  return (
    <Helmet onChangeClientState={onChangeClientState}>
      <script src={PAYMENT_URL} async />
    </Helmet>
  )
}
