import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './RoomDetailModal.module.scss'

import { ImageResponse } from '../../../apis/fvillage'
import { useImages } from '../../../hooks'
import { formatRoomSizeUnit, formatRoomType } from '../../../utils'
import { Icon, Modal, SlideShow } from '../../basics'

type RoomDetailModalProps = {
  description: string
  content: string
  images: ImageResponse[] | undefined
  isOpen: boolean
  onClose: () => void
  roomCategoryName: string
  canSmoke: boolean
  petsAllowed: boolean
  accessible: boolean
  minPax: number
  maxPax: number
  roomSize: number
  roomSizeUnit: string
  roomType: string
  isHotel: boolean
}

const translateNamespace = 'components.RoomDetailModal'

export const RoomDetailModal: React.FC<RoomDetailModalProps> = ({
  description,
  content,
  images,
  isOpen,
  onClose,
  roomCategoryName,
  canSmoke,
  petsAllowed,
  accessible,
  minPax,
  maxPax,
  roomSize,
  roomSizeUnit,
  roomType,
  isHotel,
}) => {
  const { t } = useTranslation()
  const items = useImages(images)

  // FIXME use sanitize-html instead of dangerouslySetInnerHTML
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        content: {
          maxWidth: 'calc(1100px + 8rem)',
        },
      }}
    >
      <div style={{ padding: '1rem' }}>
        <div>
          <div className={styles.informationCol}>
            <SlideShow
              items={items}
              renderItem={(item) => (
                <figure>
                  <img
                    src={item.pc.src}
                    alt={item.alt}
                    className="pc"
                    width="600"
                    height="400"
                  />
                  <img
                    src={item.sp.src}
                    alt={item.alt}
                    className="sp"
                    width="600"
                    height="400"
                  />
                </figure>
              )}
            />
            <div>
              <h4 className={styles.informationName}>{roomCategoryName}</h4>
              {isHotel && (
                <div className={styles.informationSummary}>
                  <Icon mark="capacity">
                    {t(`${translateNamespace}.capacity`)}:{' '}
                    {minPax !== maxPax
                      ? t(`${translateNamespace}.availableGuestsRange`, {
                          min: minPax,
                          max: maxPax,
                        })
                      : t(`${translateNamespace}.availableGuests`, {
                          max: maxPax,
                        })}
                  </Icon>
                  <Icon mark="size">
                    {t(`${translateNamespace}.roomSize`)}:{' '}
                    {t(`${translateNamespace}.about`, {
                      number: roomSize,
                    })}
                    {formatRoomSizeUnit(roomSizeUnit)}
                  </Icon>
                  <Icon mark="bed">
                    {t(`${translateNamespace}.roomType`)}:{' '}
                    {formatRoomType(roomType)}
                  </Icon>
                  {canSmoke ? (
                    <Icon mark="smoke">
                      {t(`${translateNamespace}.smokingAllowed`)}
                    </Icon>
                  ) : (
                    <Icon mark="smokeOff">
                      {t(`${translateNamespace}.nonSmoking`)}
                    </Icon>
                  )}
                  {petsAllowed && (
                    <Icon mark="dog">
                      {t(`${translateNamespace}.petsAllowed`)}
                    </Icon>
                  )}
                  {accessible && (
                    <Icon mark="wheelchair">
                      {t(`${translateNamespace}.accessible`)}
                    </Icon>
                  )}
                </div>
              )}
              <div className={styles.informationText}>{description}</div>
            </div>
          </div>
        </div>
        <div
          className={styles.informationBody}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}
        />
      </div>
    </Modal>
  )
}
