import useSWR from 'swr'

import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

export const useCancelConfirmationApi = (
  id: string | number,
  token: string
) => {
  const options = tokenOptions(token)

  const { data, isLoading, isValidating, error, mutate } = useSWR(
    `/reservations/${id}/confirm-cancellation`,
    () =>
      reservationApi
        .cancelConfirmation(Number(id), options)
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
