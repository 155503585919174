import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { SUPPORTED_LANGUAGES } from './constants'
import resources from './locales'

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en'
    resources: {
      en: typeof resources.en.translation
      ja: typeof resources.ja.translation
    }
  }
}

const i18n = i18next.use(LanguageDetector).use(initReactI18next)

i18n.init({
  resources,
  fallbackLng: SUPPORTED_LANGUAGES,
  supportedLngs: SUPPORTED_LANGUAGES,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: true,
  },
  detection: {
    lookupQuerystring: 'lang',
  },
})

export default i18n
