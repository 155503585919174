import useSWR from 'swr'

import { notificationApi, options } from '../../apis/suitebookClient'

export const useNotificationsApi = () => {
  const { data, isLoading, isValidating, error } = useSWR(
    '/notifications',
    () => notificationApi.getNotifications(options).then((res) => res.data)
  )

  return { data, isLoading, isValidating, error }
}
