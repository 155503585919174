import { stringify } from 'querystring'

import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UsePlansApiProps = {
  property?: string
  room?: string
  checkInDate: string
  checkOutDate: string
  numberOfAdults: number
  numberOfChildren: number
  numberOfInfants: number
  roomCount: number
  planOrder: string
  showAllPlans: boolean
}

type OrderProps = 'price' | '-price'

const isOrder = (value?: string): value is OrderProps => {
  switch (value) {
    case 'price':
    case '-price':
      return true
    default:
      return false
  }
}

export const usePlansApi = ({
  property,
  room,
  checkInDate,
  checkOutDate,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  roomCount,
  planOrder = 'price',
  showAllPlans = false,
}: UsePlansApiProps) => {
  const params = {
    checkInDate,
    checkOutDate,
    roomCount,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    planOrder,
    showAllPlans,
  }
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property && room
      ? `/hotels/${property}/rooms/${room}/plans?${stringify(params)}`
      : null,
    property && room
      ? () =>
          propertyApi
            .listPlans(
              property,
              room,
              checkInDate,
              checkOutDate,
              roomCount,
              numberOfAdults,
              numberOfChildren,
              numberOfInfants,
              isOrder(planOrder) ? planOrder : 'price',
              showAllPlans,
              options
            )
            .then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
