import { StrictMode, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import { Dedupe, ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { isAxiosError } from 'axios'
import ReactDOM from 'react-dom/client'
import { SWRConfig } from 'swr'

import { App } from './App'
import { ScrollToTop } from './components'
import { PATH } from './constants'
import i18n from './i18n'
import './styles/app.scss'

import { version } from '../package.json'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c368f834f9844107bf16c2614a006822@o44447.ingest.sentry.io/4504677435637760',
    normalizeDepth: 10,
    integrations: [
      new BrowserTracing(),
      new ExtraErrorData({ depth: 10 }),
      new Dedupe(),
    ],
    tracesSampleRate: 1.0,
  })
}

const release = `<!-- v${version} -->`

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <HelmetProvider>
          <SWRConfig
            value={{
              onError: (error) => {
                if (
                  isAxiosError(error) &&
                  error.response?.status === 500 &&
                  window.location.pathname !== PATH.errors.serverError
                ) {
                  window.location.href = PATH.errors.serverError
                }
              },
            }}
          >
            <Suspense fallback={<div>Loading</div>}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: release }} />
              <ScrollToTop />
              <App />
            </Suspense>
          </SWRConfig>
        </HelmetProvider>
      </BrowserRouter>
    </I18nextProvider>
  </StrictMode>
)
