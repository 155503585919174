import React from 'react'

import * as styles from './ToTop.module.scss'

export const Circle: React.FC = () => (
  <img
    alt="return to top"
    src="/img/Footer/toTop-outer.png"
    className={styles.toTopCircle}
  />
)
