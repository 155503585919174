import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './News.module.scss'

import { BookingEngineNews } from '../../../apis/fvillage'
import { Markdown } from '../Markdown'

type NewsProps = {
  news: BookingEngineNews
}

export const News: React.FC<NewsProps> = ({ news }) => {
  const { i18n } = useTranslation()

  return (
    <div className={styles.news}>
      <Markdown
        content={
          i18n.language === 'ja' ? news.contentJa ?? '' : news.contentEn ?? ''
        }
      />
    </div>
  )
}
