import React from 'react'

import * as styles from './ToTop.module.scss'

export const Arrow: React.FC = () => (
  <img
    alt="return to top"
    src="/img/Footer/toTop-inner.svg"
    className={styles.toTopArrow}
  />
)
