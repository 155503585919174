import { useCallback } from 'react'

import { hookstate, useHookstate } from '@hookstate/core'

export type ReservationState = {
  confirmationCode?: string
  reservationId?: string
}

const initialState = {
  confirmationCode: '',
  reservationId: '',
}

export const reservationStore = hookstate(initialState)

export const useReservationState = () => {
  const state = useHookstate(reservationStore)

  const reservationState = state.get()

  const onUpdateReservationState = useCallback(
    (registration: ReservationState) => state.merge(registration),
    [state]
  )

  const onClearReservationState = useCallback(
    () =>
      state.set({
        confirmationCode: '',
        reservationId: '',
      }),
    [state]
  )

  return {
    reservationState,
    onUpdateReservationState,
    onClearReservationState,
  }
}
