import { useCallback, useMemo } from 'react'

import { useHookstate } from '@hookstate/core'
import { localstored } from '@hookstate/localstored'

import { Registration } from '../../schemas'

export const useStorageEnabled = () =>
  useMemo(() => {
    if (!navigator.cookieEnabled) {
      return false
    }

    try {
      return (
        typeof window.sessionStorage !== 'undefined' &&
        typeof window.localStorage !== 'undefined' &&
        window.sessionStorage !== null &&
        window.localStorage !== null
      )
    } catch (e) {
      return false
    }
  }, [])

const initialState = {
  name: '',
  kana: '',
  phone: '',
  postalCode: '',
  prefecture: '',
  city: '',
  street: '',
  email: '',
  specialServiceRequest: '',
  birthYear: '',
  birthMonth: '',
  birthDay: '',
}

type RegistrationStateProps = Omit<
  Registration,
  | 'cardNumber'
  | 'expirationMonth'
  | 'expirationYear'
  | 'securityCode'
  | 'holderName'
>

export const useRegistrationState = () => {
  // Personal information would saved to localstorage
  // for guest does not need to input again
  const state = useHookstate<RegistrationStateProps>(
    initialState,
    localstored({
      key: 'registration-key',
    })
  )

  const registrationState = state.get()

  const onUpdateRegistrationState = useCallback(
    (registration: RegistrationStateProps) => {
      state.merge(registration)
    },
    [state]
  )

  /**
   * Clear all state.
   */
  const onClearRegistrationState = useCallback(
    () =>
      state.set({
        name: '',
        kana: '',
        phone: '',
        postalCode: '',
        prefecture: '',
        city: '',
        street: '',
        email: '',
        specialServiceRequest: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
      }),
    [state]
  )

  /**
   * Clear specified key's state.
   */
  const onClearRegistrationStateByKey = useCallback(
    (value: keyof typeof initialState) => {
      if (Object.keys(initialState).includes(value)) {
        const key: keyof typeof initialState = value
        return state.set((p) => ({ ...p, [key]: '' }))
      }
      return state
    },
    [state]
  )

  return {
    registrationState,
    onUpdateRegistrationState,
    onClearRegistrationState,
    onClearRegistrationStateByKey,
  }
}
