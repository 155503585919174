import { Trans, useTranslation } from 'react-i18next'

import * as styles from './FlightTicketModal.module.scss'

import { Button, Modal, ModalProps, Text } from '../../basics'

type FlightTicketModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  linkUrl: string
}

const translateNamespace = 'components.FlightTicketModal'

export const FlightTicketModal: React.FC<FlightTicketModalProps> = ({
  linkUrl,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Text>{t(`${translateNamespace}.title`)}</Text>}
      footer={
        <div className={styles.actionButton}>
          <Button
            htmlType="button"
            size="full"
            variant="secondary"
            onClick={() => {
              onClose()
              window.open(linkUrl, '_blank')
            }}
          >
            {t(`${translateNamespace}.flightTicket`)}
          </Button>
        </div>
      }
    >
      <div className={styles.note}>
        <Text>
          <Trans i18nKey={`${translateNamespace}.note`} />
        </Text>
      </div>
    </Modal>
  )
}
