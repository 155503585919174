import * as grid from 'react-grid-system'

type ContainerProps = Pick<grid.Configuration, 'gridColumns'> &
  Pick<grid.ContainerProps, 'fluid'> & {
    children: React.ReactNode
  }

export const Container: React.FC<ContainerProps> = (props) => {
  const { fluid = true, gridColumns = 12, children } = props

  grid.setConfiguration({
    gutterWidth: 0,
    gridColumns,
  })

  return <grid.Container fluid={fluid}>{children}</grid.Container>
}

export type RowProps = Pick<
  grid.RowProps,
  'gutterWidth' | 'direction' | 'justify' | 'align'
> & {
  gutterHeight?: number | string
  children: React.ReactNode
}

export const Row: React.FC<RowProps> = ({
  gutterHeight,
  children,
  ...props
}) => {
  const marginBottom =
    typeof gutterHeight === 'number' ? `${gutterHeight}px` : gutterHeight

  return (
    <grid.Row style={{ marginBottom }} {...props}>
      {children}
    </grid.Row>
  )
}

export type ColProps = Pick<grid.ColProps, 'width' | 'className'> & {
  sp?: number
  pc?: number
  offset?: {
    sp: number
    pc: number
  }
  children: React.ReactNode
}

export const Col: React.FC<ColProps> = (props) => {
  const { pc, sp, offset, children } = props

  return (
    <grid.Col
      xxxl={pc}
      xxl={pc}
      xl={pc}
      lg={pc}
      md={pc}
      sm={sp}
      xs={sp}
      {...props}
      offset={{
        xxxl: offset?.pc,
        xxl: offset?.pc,
        xl: offset?.pc,
        lg: offset?.pc,
        md: offset?.pc,
        sm: offset?.sp,
        xs: offset?.sp,
      }}
    >
      {children}
    </grid.Col>
  )
}
