import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DetailList } from './DetailList'
import * as styles from './ReservationDetail.module.scss'

import { CancelPolicyResponse } from '../../../apis/fvillage'
import {
  useAuthProvider,
  useCancelConfirmationApi,
  useCreateNextPath,
  useReservationPath,
} from '../../../hooks'
import { ButtonWrapper } from '../../basics'
import { CancelPolicySection } from '../../contents'

const translateNamespace = `components.ReservationDetail`

export const CancelPolicyDetailList: React.FC<{
  cancelPolicy?: CancelPolicyResponse
  isCancelled?: boolean
}> = ({ cancelPolicy, isCancelled }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    params: { reservation: id },
    onCreateNextPath,
  } = useCreateNextPath()
  const { token } = useAuthProvider()
  const { data } = useCancelConfirmationApi(id ?? '', token)
  const reservationPath = useReservationPath()

  return (
    <DetailList
      header={t(`${translateNamespace}.cancellation`)}
      listItems={[
        {
          items: [
            <div>
              <CancelPolicySection
                cancelPolicy={cancelPolicy}
                canCancel={data?.canCancel}
                isCancelled={isCancelled}
              />
              <div className={styles.spacer}>
                <ButtonWrapper
                  position="center"
                  htmlType="button"
                  size="medium"
                  variant="danger"
                  outline
                  disabled={!data?.canCancel || isCancelled}
                  onClick={() => {
                    navigate(
                      onCreateNextPath(reservationPath.cancel.root, {
                        pathParams: {
                          reservation: id,
                        },
                      })
                    )
                  }}
                >
                  {isCancelled
                    ? t(`${translateNamespace}.alreadyCanceled`)
                    : t(`${translateNamespace}.cancel`)}
                </ButtonWrapper>
              </div>
            </div>,
          ],
          key: 'cancelPolicy',
        },
      ]}
    />
  )
}
