import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { kenall } from '../../apis'
import { canonicalizeHyphen } from '../../utils'

const getAddress = async (
  key: string,
  { arg }: { arg: { postalCode: string } }
) => {
  const data = await kenall.getAddress(arg.postalCode).then((res) => res.data)
  return data
}

export const useKenallApi = () => {
  const { trigger, data, error, isMutating } = useSWRMutation(
    '/kenall',
    getAddress
  )

  const onTrigger = useCallback(
    (value: string) => {
      if (!value) {
        return
      }
      const postalCode = canonicalizeHyphen(value)
      if (postalCode.length < 7) {
        return
      }
      if (postalCode.length < 7 || /^[0-9]+$/.exec(postalCode) === null) {
        return
      }
      trigger({ postalCode })
    },
    [trigger]
  )

  return { data, error, isMutating, onTrigger }
}
