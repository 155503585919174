import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

export const usePropertyApi = (property?: string) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property ? `/hotels/${property}` : null,
    property
      ? () => propertyApi.getProperty(property, options).then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
