type GetTokenProps = {
  cardno: string
  expire: string
  holdername: string
  securitycode: string
}

export type TokenResultProps = {
  resultCode: '000' | number
  tokenObject: {
    isSecurityCodeSet: boolean | string
    maskedCardNo: string
    toBeExpiredAt: string
    token: string[]
  }
}

const TOKEN_NO = '1' // Number of tokens to be issued

export const useGMOMultiPaymentApi = () => {
  const onGetToken = ({
    cardno,
    expire,
    holdername,
    securitycode,
  }: GetTokenProps) =>
    new Promise<TokenResultProps>((resolve) => {
      window.Multipayment.getToken(
        { cardno, expire, holdername, securitycode, tokennumber: TOKEN_NO },
        resolve
      )
    })

  return { onGetToken }
}
