import { Helmet } from 'react-helmet-async'

import { usePropertyProvider } from '../../../hooks'

export const RegistrationCompletionHead = () => {
  const { isHotel } = usePropertyProvider()

  if (isHotel) {
    return (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
              dataLayer.push({'event': 'cv-hotel'});
            `,
          },
        ]}
      />
    )
  }

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: `
            dataLayer.push({'event': 'cv-spa'});
          `,
        },
      ]}
    />
  )
}
