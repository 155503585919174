import React, { useMemo, useState } from 'react'

import clsx from 'clsx'
import * as ReactDOMServer from 'react-dom/server'
import {
  Autoplay,
  EffectFade,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
} from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

/* eslint-disable import/no-unresolved */
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
/* eslint-enable import/no-unresolved */

import './swiper.scss'
import * as styles from './SlideShow.module.scss'

type SlideShowProps<T> = {
  items: T[]
  renderItem: (item: T, i: number) => React.ReactNode
  onTap?: (swiper: SwiperClass) => void
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const useSlides = <T,>({ items, renderItem }: SlideShowProps<T>) =>
  useMemo(
    () =>
      items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={`slideshow-${i}`}>{renderItem(item, i)}</SwiperSlide>
      )),
    [items, renderItem]
  )

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const SlideShow = <T,>({
  items,
  renderItem,
  onTap,
}: SlideShowProps<T>) => {
  const slides = useSlides({ items, renderItem })

  if (items.length < 1) {
    return null
  }

  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      navigation
      modules={[Pagination, Navigation]}
      loop
      className={styles.swiper}
      wrapperClass={clsx(styles.wrapper, 'slide-show')}
      onTap={onTap}
    >
      {slides}
    </Swiper>
  )
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const FadingSlideShow = <T,>({
  items,
  renderItem,
  onTap,
}: SlideShowProps<T>) => {
  const slides = useSlides({ items, renderItem })

  if (items.length < 1) {
    return null
  }

  return (
    <Swiper
      className="fading-swiper"
      pagination={{
        type: 'custom',
        renderCustom: (swiper, current, total) =>
          ReactDOMServer.renderToStaticMarkup(
            <React.Fragment>
              <div>{`0${current}`}</div>
              <div className={styles.paginationTotal}>{`0${total}`}</div>
            </React.Fragment>
          ),
      }}
      modules={[Pagination, EffectFade, Autoplay]}
      loop
      effect="fade"
      fadeEffect={{
        crossFade: true,
      }}
      autoplay={{ delay: 4000 }}
      onTap={onTap}
    >
      {slides}
    </Swiper>
  )
}

export type ThumbsGalleryProps<T> = {
  items: T[]
  renderItem: (item: T, i: number) => React.ReactNode
  initialSlide?: number
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const ThumbsGallery = <T,>({
  items,
  renderItem,
  initialSlide = 0,
}: ThumbsGalleryProps<T>) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)
  const slides = useSlides({ items, renderItem })

  return (
    <React.Fragment>
      <Swiper
        loop
        spaceBetween={10}
        navigation
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumbs-gallery"
        initialSlide={initialSlide}
      >
        {slides}
      </Swiper>
      {items.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          loop
          spaceBetween={10}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
          className="thumbs-gallery"
          initialSlide={initialSlide}
        >
          {slides}
        </Swiper>
      )}
    </React.Fragment>
  )
}
