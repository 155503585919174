import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { tokenOptions, transactionApi } from '../../apis/suitebookClient'

type UsePatchDiscountProps = {
  token: string
  property?: string
  room?: string
  plan?: string
}

export const usePatchDiscountApi = ({
  token,
  plan,
  property,
  room,
}: UsePatchDiscountProps) => {
  const options = tokenOptions(token)

  const patchDiscount = useCallback(
    async (
      key: string,
      {
        arg: { discountCode, transactionId },
      }: { arg: { discountCode: string; transactionId?: number } }
    ) => {
      if (property && room && plan && transactionId) {
        // Catch exception at component and show error message
        // when coupon code is invalid data.
        const { status } = await transactionApi
          .patchDiscount(
            property,
            room,
            plan,
            transactionId,
            { discountCode },
            options
          )
          .then((res) => ({ status: res.status }))
        return { status }
      }
      return { status: 500 }
    },
    [options, property, room, plan]
  )

  const { trigger, error } = useSWRMutation(
    `/hotels/${property}/rooms/${room}/plans/${plan}/price/discount`,
    patchDiscount
  )

  return {
    trigger,
    error,
  }
}
