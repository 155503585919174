import { useTranslation } from 'react-i18next'

import * as styles from './OptionPlanIndexFrame.module.scss'

import {
  BannerHurusatoNouzeiSmall,
  FacilityOptionPlanPanel,
  MainColumn,
  MultiColumn,
  OptionPlanIndex,
  OptionPlanPanel,
  SearchLayout,
  SearchMainContent,
  SideColumn,
  Text,
} from '../../components'
import { usePropertyProvider } from '../../hooks'

const translateNamespace = 'frames.OptionPlanIndexFrame'

export const OptionPlanIndexFrame = () => {
  const { t } = useTranslation()
  const { isHotel } = usePropertyProvider()

  return (
    <SearchLayout>
      <SearchMainContent>
        <MultiColumn>
          <SideColumn>
            {isHotel ? <OptionPlanPanel /> : <FacilityOptionPlanPanel />}
            <div className={styles.optionDesc}>
              <Text size={14}>{t(`${translateNamespace}.optionDesc`)}</Text>
            </div>
            <div className={styles.bannerArea}>
              <BannerHurusatoNouzeiSmall />
            </div>
          </SideColumn>
          <MainColumn>
            <OptionPlanIndex />
          </MainColumn>
        </MultiColumn>
      </SearchMainContent>
    </SearchLayout>
  )
}
