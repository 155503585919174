import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './PropertyModal.module.scss'

import {
  BaseText,
  Button,
  Col,
  Container,
  Flex,
  FlexBox,
  Modal,
  ModalProps,
  Row,
  Text,
} from '../../basics'
import { LogoImage } from '../../contents/Header/LogoNav/LogoImage'

const translateNamespace = 'components.SearchModal'

export type PropertyModalItem = {
  shortName: string
  slug: string
  image: string
  insideFvillage?: boolean
  access?: string
}

type PropertyModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  properties: Array<PropertyModalItem>
  onNext: (property: PropertyModalItem) => void
  defaultProperty: PropertyModalItem | null
  nextButtonText?: React.ReactNode
}

export const PropertyModal: React.FC<PropertyModalProps> = ({
  isOpen,
  onClose,
  properties,
  onNext,
  defaultProperty,
  nextButtonText,
}) => {
  const { t } = useTranslation()
  const [selectedProperty, setSelectedProperty] = useState<PropertyModalItem>(
    defaultProperty ?? properties[0]
  )

  if (properties.length === 0) {
    return null
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Text>{t(`${translateNamespace}.selectProperty`)}</Text>}
      subTitle={<Text>{t(`${translateNamespace}.selectPropertyDesc`)}</Text>}
      footer={
        <Flex optionClass={styles.footer}>
          <FlexBox optionClass={styles.footerText}>
            <Text align="center" size={12}>
              {t(`${translateNamespace}.property`)}
            </Text>
          </FlexBox>
          <FlexBox optionClass={styles.footerText}>
            {selectedProperty ? (
              <Text align="center" weight="bold">
                {selectedProperty.shortName}
              </Text>
            ) : (
              <BaseText align="center" size={12} color="gray1">
                {t(`${translateNamespace}.selectPropertyDescShort`)}
              </BaseText>
            )}
          </FlexBox>
        </Flex>
      }
      leftButton={
        <Button variant="secondary" htmlType="button" onClick={onClose}>
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={!selectedProperty}
          onClick={() => {
            if (!selectedProperty) {
              return
            }
            onNext(selectedProperty)
          }}
        >
          {nextButtonText ?? t(`${translateNamespace}.selectDate`)}
        </Button>
      }
    >
      <div className={styles.properties}>
        <div className={styles.divider}>
          <LogoImage color="" />
        </div>
        <Container>
          <Row>
            {properties
              .filter((property) => property.insideFvillage)
              ?.map((property) => {
                const { shortName, slug, image, access } = property
                return (
                  <Col
                    sp={12}
                    pc={6}
                    className={styles.propertyCol}
                    key={shortName}
                  >
                    <button
                      className={clsx(
                        selectedProperty?.slug === slug &&
                          styles.propertySelected,
                        styles.property
                      )}
                      type="button"
                      onClick={() => setSelectedProperty(property)}
                    >
                      <div className={styles.propertyImgWrap}>
                        <img
                          className={styles.propertyImg}
                          src={image}
                          alt={shortName}
                        />
                      </div>
                      <div className={styles.propertyName}>
                        <Text inline weight="bold" size={14}>
                          {shortName}
                        </Text>
                        <BaseText size={12} color="gray3" align="left">
                          {access}
                        </BaseText>
                      </div>
                    </button>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </div>
      <div className={styles.divider}>
        <Text weight="bold" size={16}>
          {t(`${translateNamespace}.partner`)}
        </Text>
      </div>
      <div className={styles.properties}>
        <Container>
          <Row>
            {properties
              .filter((property) => !property.insideFvillage)
              .map((property) => {
                const { shortName, image, access, slug } = property
                return (
                  <Col
                    sp={12}
                    pc={6}
                    className={styles.propertyCol}
                    key={shortName}
                  >
                    <button
                      className={clsx(
                        selectedProperty?.slug === slug &&
                          styles.propertySelected,
                        styles.property
                      )}
                      type="button"
                      onClick={() => setSelectedProperty(property)}
                    >
                      <div className={styles.propertyImgWrap}>
                        <img
                          className={styles.propertyImg}
                          src={image}
                          alt={shortName}
                        />
                      </div>
                      <div className={styles.propertyName}>
                        <Text inline weight="bold" size={14}>
                          {shortName}
                        </Text>
                        <BaseText size={12} color="gray3">
                          {access}
                        </BaseText>
                      </div>
                    </button>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </div>
    </Modal>
  )
}
