import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Registration } from '../../../../schemas'
import { Col, Container, ErrorMessage, FormInput } from '../../../basics'
import { FormRow } from '../FormRow'

export const EmailForm = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<Registration>()
  return (
    <Container>
      <FormRow>
        <Col pc={6}>
          <FormInput
            control={control}
            name="email"
            required
            inputMode="email"
            label={t(`components.EmailForm.label.email`)}
            placeholder={t(`components.EmailForm.label.email`)}
          />
          <ErrorMessage errors={errors} name="email" />
        </Col>
      </FormRow>
    </Container>
  )
}
