import { useCallback } from 'react'

import { OptionItem } from '../../apis/fvillage'

/**
 * Option items are POST to API server at RegistrationNewFrame(not option page).
 * So we need to carry option items to RegistrationNewFrame page.
 *
 * When set option items to hookstate, reload browser at RegistrationNewFrame,
 * states are disappeared because states are not persistenced.
 * (When login token was expired, RegistrationNewFrame page would auto reloaded)
 *
 * hookstate/localstored@4.0.0 is not support session storage.
 * We put optional items to session storage.
 * (Save to localStorage is too long for lifespan)
 */
export const useOptions = () => {
  const onGet = useCallback(() => {
    try {
      const options = sessionStorage.getItem('options.items')
      if (options) {
        return JSON.parse(options) as OptionItem[]
      }
      // If plan does not have any option, send API to empty array
      return []
    } catch (e) {
      return []
    }
  }, [])

  const onSet = useCallback((options: OptionItem[] | null) => {
    if (options === null) {
      return
    }
    try {
      sessionStorage.setItem('options.items', JSON.stringify(options))
    } catch (e) {
      // Do nothing
    }
  }, [])

  const onClear = useCallback(() => {
    try {
      sessionStorage.removeItem('options.items')
    } catch (e) {
      // Do nothing
    }
  }, [])

  return { onGet, onSet, onClear }
}
