import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import {
  PATH,
  getReservationPaths,
  reservationRouteType,
} from '../../constants'
import { usePropertyApi } from '../usePropertyApi'
import { useRouteParams } from '../useRouteParams'

export const useReservationPath = () => {
  const location = useLocation()
  const { property: slug } = useRouteParams()
  const { data: property } = usePropertyApi(slug)

  return useMemo(() => {
    const isReservationPage = [
      PATH.reservations.hotels.root,
      PATH.reservations.spas.root,
      PATH.reservations.activities.root,
    ].find((path) => location.pathname.startsWith(path))

    if (isReservationPage) {
      if (location.pathname.includes(reservationRouteType.Spa)) {
        return getReservationPaths(reservationRouteType.Spa)
      }
      if (location.pathname.includes(reservationRouteType.Activity)) {
        return getReservationPaths(reservationRouteType.Activity)
      }

      return getReservationPaths(reservationRouteType.Hotel)
    }

    if (property?.isSpa) {
      return getReservationPaths(reservationRouteType.Spa)
    }
    if (property?.isActivity) {
      return getReservationPaths(reservationRouteType.Activity)
    }

    return getReservationPaths(reservationRouteType.Hotel)
  }, [property, location])
}
