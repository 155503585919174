import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'

import clsx from 'clsx'

import * as styles from './Input.module.scss'

const translateNamespace = 'components.Input'

/**
 * type="number" is not allowed since default value required a number.
 * In our case, we want set blank to default.
 * If we set undefined to input type="number" field as default,
 * and when state was changed, React raise
 * `A component is changing an uncontrolled input to be controlled` warnging.
 * So, we use input type="text" inputMode="numeric" instead.
 */
type HtmlType = 'text' | 'email' | 'password'

export type InputProps = {
  name: string
  label?: React.ReactNode
  htmlType?: HtmlType
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined
  value?: string | undefined
  placeholder?: string
  required?: boolean
  helpMessage?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  minLength?: number
  maxLength?: number
  format?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      name,
      htmlType = 'text',
      inputMode,
      placeholder,
      required = false,
      label = null,
      helpMessage,
      onChange,
      onBlur,
      minLength,
      maxLength,
      format,
    },
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <section>
        <label htmlFor={name}>
          <span className={styles.labelText}>{label}</span>
          {required && (
            <span className={styles.requiredAsterisk}>
              {t(`${translateNamespace}.required`)}
            </span>
          )}
          {format ? (
            <PatternFormat
              format={format}
              allowEmptyFormatting
              className={clsx(styles.infoElm)}
              getInputRef={ref}
              type={htmlType === 'email' ? 'text' : htmlType}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              inputMode={inputMode}
              id={name}
              minLength={minLength}
              maxLength={maxLength}
            />
          ) : (
            <input
              className={clsx(styles.infoElm)}
              ref={ref}
              type={htmlType}
              name={name}
              value={value}
              placeholder={placeholder}
              onInput={onChange}
              onBlur={onBlur}
              inputMode={inputMode}
              id={name}
              minLength={minLength}
              maxLength={maxLength}
            />
          )}
          {helpMessage && <p className={styles.help}>{helpMessage}</p>}
        </label>
      </section>
    )
  }
)
