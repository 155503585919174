import { useMemo } from 'react'

import { FeatureEnum } from '../../constants'

export const useRoomFeature = (feature: string) => {
  const features = feature.split(',')

  return useMemo(
    () => ({
      canSmoke: features.includes(FeatureEnum.Smoking),
      petsAllowed: features.includes(FeatureEnum.PetsAllowed),
      accessible: features.includes(FeatureEnum.Accessible),
    }),
    [features]
  )
}
