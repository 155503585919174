import { forwardRef } from 'react'

import clsx from 'clsx'

import * as styles from './Checkbox.module.scss'

export type CheckboxProps = {
  name: string
  value?: string | number
  checked: boolean
  label: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, name, value, label, onChange, disabled }, ref) => {
    const id = `checkbox-${name}`
    return (
      <fieldset disabled={disabled}>
        <label htmlFor={id}>
          <div className={clsx(styles.checkConsentRow)}>
            <div className={clsx(styles.input)}>
              <input
                type="checkbox"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                ref={ref}
              />
              <div className={clsx(styles.inputDummy)} />
            </div>
            <p>{label}</p>
          </div>
        </label>
      </fieldset>
    )
  }
)
