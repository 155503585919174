import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './ReservationItem.module.scss'

import { ReservationResponse } from '../../../apis/fvillage'
import {
  useCreateNextPath,
  usePublication,
  useReservationPath,
} from '../../../hooks'
import { formatJstDate, formatNights } from '../../../utils'
import { Button, Flex, FlexBox, Text } from '../../basics'
import { ItemText } from '../ReservationDetail/ItemText'
import { ReservationStatus } from '../ReservationStatus'

type ReservationItemProps = ReservationResponse

const translateNamespace = 'components.ReservationItem'

export const ReservationItem: React.FC<ReservationItemProps> = ({
  id,
  confirmationCode,
  transaction,
  bookedAt,
  guestStatus,
}) => {
  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()
  const reservationPath = useReservationPath()

  const { t, i18n } = useTranslation()

  const hotelPublication = usePublication(
    i18n.language,
    transaction?.input?.bookingEngineHotel?.publications
  )
  const hotelName = hotelPublication?.name ?? ''

  const planPublication = usePublication(
    i18n.language,
    transaction?.input?.bookingEnginePlan?.publications
  )
  const planName = planPublication?.name ?? ''

  const checkInDate = transaction?.input?.checkInDate
  const checkOutDate = transaction?.input?.checkOutDate
  const numberOfAdults = transaction?.input?.numberOfAdults
  const numberOfChildren = transaction?.input?.numberOfChildren
  const numberOfInfants = transaction?.input?.numberOfInfants
  const totalPrice = transaction?.input?.totalPrice

  const isHotel = transaction?.input?.bookingEngineHotel?.isHotel
  const isActivity = transaction?.input?.bookingEngineHotel?.isActivity
  const checkInTimeStart =
    transaction?.input?.bookingEnginePlan?.checkInTimeStartNote ||
    transaction?.input?.bookingEnginePlan?.checkInTimeStart ||
    '10:00'
  const checkOutTimeEnd =
    transaction?.input?.bookingEnginePlan?.checkOutTimeEndNote ||
    transaction?.input?.bookingEnginePlan?.checkOutTimeEnd ||
    '22:00'

  const images = transaction?.input?.bookingEngineHotel?.images ?? []
  const image = images.length > 0 ? images[0].url : '/img/hotel01.jpg'

  return (
    <Flex optionClass={styles.container}>
      <FlexBox optionClass={styles.itemLeft}>
        <div className={styles.head}>
          <div className={styles.itemImg}>
            <figure>
              <img alt={hotelName} height="600" src={image} width="600" />
            </figure>
          </div>
          <div className={styles.itemName}>
            <p>{hotelName}</p>
          </div>
          <div className={styles.status}>
            <ReservationStatus guestStatus={guestStatus} />
          </div>
        </div>
        <div>
          <div className={styles.itemInfo}>
            {isHotel && (
              <p>
                <span className={styles.itemDates}>
                  {checkInDate &&
                    formatJstDate(
                      checkInDate,
                      i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy',
                      i18n.language
                    )}
                </span>
                <span className={styles.itemDates}>~</span>
                <span className={styles.itemDates}>
                  {checkOutDate &&
                    formatJstDate(
                      checkOutDate,
                      i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy',
                      i18n.language
                    )}
                </span>
                <span className={styles.itemDates}>
                  {checkInDate &&
                    checkOutDate &&
                    formatNights(checkInDate, checkOutDate)}
                </span>
              </p>
            )}
            {!isHotel && (
              <p>
                <span className={styles.itemDates}>
                  {checkInDate &&
                    formatJstDate(
                      checkInDate,
                      i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy',
                      i18n.language
                    )}
                  {!isActivity && (
                    <ItemText>
                      {' '}
                      {checkInTimeStart} ~ {checkOutTimeEnd}
                    </ItemText>
                  )}
                </span>
              </p>
            )}
            <p>{planName}</p>
            <Text pcSize={14} spSize={12}>
              {t(`${translateNamespace}.adults`, { adults: numberOfAdults })}
              {' / '}
              {t(`${translateNamespace}.children`, {
                children: numberOfChildren,
              })}
              {' / '}
              {t(`${translateNamespace}.infants`, {
                infants: numberOfInfants,
              })}
              {isHotel && `・${t(`${translateNamespace}.room`, { unit: 1 })}`}
            </Text>
          </div>
        </div>
        <div className={styles.itemFoot}>
          <div>
            <p className={styles.price}>
              {t(`${translateNamespace}.totalPrice`)}
              <b className={styles.yen}>¥</b>
              <b className={styles.num}>{totalPrice?.toLocaleString() ?? ''}</b>
              {t(`${translateNamespace}.taxIncluded`)}
            </p>
          </div>
        </div>
      </FlexBox>
      <FlexBox optionClass={styles.itemRight}>
        <div className={styles.itemStatus}>
          <ReservationStatus guestStatus={guestStatus} />
        </div>
        <div className={styles.itemBtn}>
          <Button
            htmlType="button"
            outline
            variant="primary"
            onClick={() => {
              navigate(
                onCreateNextPath(reservationPath.reservation, {
                  pathParams: {
                    reservation: id?.toString(),
                  },
                })
              )
            }}
          >
            {t(`${translateNamespace}.reservationDetail`)}
          </Button>
        </div>
        <div className={styles.itemData}>
          <dl>
            <dt>{t(`${translateNamespace}.reservationCode`)}</dt>
            <dd>{confirmationCode}</dd>
            <dt>{t(`${translateNamespace}.reservationDate`)}</dt>
            {bookedAt && (
              <dd>
                {formatJstDate(
                  bookedAt,
                  i18n.language === 'ja'
                    ? 'yyyy/MM/dd HH:mm'
                    : 'MM/dd/yyyy HH:mm',
                  i18n.language
                )}
              </dd>
            )}
          </dl>
        </div>
      </FlexBox>
    </Flex>
  )
}
