import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './RoomIndex.module.scss'

import { PATH } from '../../../constants'
import {
  useCreateNextPath,
  usePropertyProvider,
  useRoomsApi,
  useSearchQueryParams,
} from '../../../hooks'
import { Sort } from '../../basics'
import { Loader, NotFound, ShowAllToggle } from '../../contents'
import { RoomItem } from '../RoomItem'

const translateNamespace = 'components.RoomIndex'

// FIXME style
export const RoomIndex: React.FC = () => {
  const { isHotel } = usePropertyProvider()
  const {
    params: { property },
    onCreateNextPath,
  } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { data: rooms, isLoading } = useRoomsApi({
    property,
    ...queryParams,
    showAllRooms: queryParams.showAll,
  })
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (isLoading) {
    return <Loader />
  }

  const i18nKey = isHotel ? 'empty.roomNotFound' : 'empty.facilityNotFound'

  return (
    <React.Fragment>
      <Sort
        count={rooms?.length ?? 0}
        onChange={(e) => {
          const nextPath = onCreateNextPath(PATH.rooms, {
            queryParams: { roomOrder: e.target.value },
          })
          navigate(nextPath)
        }}
        defaultValue={queryParams.roomOrder ?? 'min_price'}
        noMargin
      >
        <option value="min_price">
          {t(`${translateNamespace}.Sort.ascPrice`)}
        </option>
        <option value="-min_price">
          {t(`${translateNamespace}.Sort.descPrice`)}
        </option>
      </Sort>
      <ShowAllToggle />
      <ul>
        {Array.isArray(rooms) && rooms.length > 0 ? (
          rooms.map((room) => (
            <li className={styles.item} key={room.id}>
              <RoomItem {...room} />
            </li>
          ))
        ) : (
          <NotFound
            title={t(`${i18nKey}.attention`)}
            description1={t(`${i18nKey}.direction`)}
            description2={t(`${i18nKey}.news`)}
          />
        )}
      </ul>
    </React.Fragment>
  )
}
