import React from 'react'
import { useTranslation } from 'react-i18next'

import { AddressForm } from './AddressForm'
import { EmailForm } from './EmailForm'
import { InquiryForm } from './InquiryForm'
import { PaymentForm } from './PaymentForm'
import { PersonalForm } from './PersonalForm'

import { Col, Container, Row, Section, Text } from '../../basics'

type RegistrationFormProps = {
  disabledInquiryForm?: boolean
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  disabledInquiryForm = false,
}) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <Section>
        <div className="pc">
          <Text size={22} weight="bold">
            {t(`components.RegistrationForm.registrationTitle`)}
          </Text>
        </div>
      </Section>
      <Section title={t(`components.AddressForm.title`)}>
        <Container>
          <Row gutterWidth={35}>
            <Col pc={6}>
              <PersonalForm />
            </Col>
            <Col pc={6}>
              <AddressForm />
            </Col>
          </Row>
        </Container>
      </Section>
      <Section title={t(`components.EmailForm.title`)}>
        <Container>
          <Row gutterWidth={35}>
            <Col>
              <EmailForm />
            </Col>
          </Row>
        </Container>
      </Section>

      {!disabledInquiryForm && (
        <Section title={t(`components.InquiryForm.title`)}>
          <Container>
            <Row gutterWidth={35}>
              <Col>
                <InquiryForm />
              </Col>
            </Row>
          </Container>
        </Section>
      )}

      <Section title={t(`components.PaymentForm.title`)}>
        <Container>
          <Row gutterWidth={35}>
            <Col>
              <PaymentForm />
            </Col>
          </Row>
        </Container>
      </Section>
    </React.Fragment>
  )
}
