import { useTranslation } from 'react-i18next'

import { format, parseISO } from 'date-fns'

import { DetailList } from './DetailList'
import { ItemText } from './ItemText'

import { TransactionInput } from '../../../apis/fvillage'
import { Col, Container, Row } from '../../basics'

type GuestDetailListProps = TransactionInput

const translateNamespace = `components.ReservationDetail`

export const GuestDetailList: React.FC<GuestDetailListProps> = ({
  name,
  kana,
  address,
  phone,
  email,
  dateOfBirth,
}) => {
  const { t } = useTranslation()

  const guestDetail = [
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.name`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>{name}</ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.furigana`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>{kana}</ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.address`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>〒{address}</ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.phone`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>{phone}</ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.email`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>{email}</ItemText>
        </Col>
      </Row>
    </Container>,
    <Container>
      <Row>
        <Col pc={3} sp={12}>
          <ItemText>{t(`${translateNamespace}.dateOfBirth`)}</ItemText>
        </Col>
        <Col pc={9} sp={12}>
          <ItemText>
            {dateOfBirth &&
              dateOfBirth !== '' &&
              format(parseISO(dateOfBirth), 'yyyy/MM/dd')}
          </ItemText>
        </Col>
      </Row>
    </Container>,
  ]

  return (
    <DetailList
      header={t(`${translateNamespace}.guestDetail`)}
      listItems={[
        {
          items: guestDetail,
          key: 'guestDetail',
        },
      ]}
    />
  )
}
