import { useCallback } from 'react'

import useSWRMutation from 'swr/mutation'

import { reservationApi, tokenOptions } from '../../apis/suitebookClient'

// 30 Seconds
const TIMEOUT_MSEC = 30000

export const useCreateReservationApi = (
  token: string,
  transactionId: number
) => {
  const options = tokenOptions(token)

  const createReservation = useCallback(
    async () =>
      reservationApi
        .createReservation(
          { transactionId },
          { ...options, timeout: TIMEOUT_MSEC }
        )
        .then((res) => ({
          data: res.data,
          status: res.status,
        })),
    [options, transactionId]
  )

  const { trigger, error, data } = useSWRMutation(
    '/reservation/create',
    createReservation
  )

  return {
    trigger,
    error,
    data,
  }
}
