import { useState } from 'react'

import clsx from 'clsx'

import * as styles from './Accordion.module.scss'

import { BaseText, Text } from '../Text'

type AccordionProps = {
  title: React.ReactNode
  subTitle: React.ReactNode
  children: React.ReactNode
  defaultOpen?: boolean
  subTitleColor?: 'gray3' | 'red1'
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  subTitle,
  defaultOpen = false,
  children,
  subTitleColor = 'gray3',
}) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  return (
    <div className={styles.accordion}>
      <a
        className={styles.label}
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setOpen(!isOpen)
        }}
      >
        <div className={styles.title}>
          <Text weight="bold" size={18}>
            {title}
          </Text>
        </div>
        <BaseText color={subTitleColor} size={18}>
          {subTitle}
        </BaseText>
        <div className={clsx(styles.accordionIcon, isOpen && styles.isOpen)} />
      </a>
      <div className={clsx(styles.content, isOpen && styles.isOpen)}>
        {children}
      </div>
    </div>
  )
}
