import { stringify } from 'querystring'

import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

export const usePropertyAvailabilitiesApi = (params?: {
  fromDate: string
  toDate: string
}) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    `/hotels/availabilities?${stringify(params)}`,
    () =>
      propertyApi
        .getPropertyAvailabilities(params?.fromDate, params?.toDate, options)
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
