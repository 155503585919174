import * as styles from './ReservationDetail.module.scss'

import { BaseText, List, Text } from '../../basics'

type DetailListProps = {
  header: React.ReactNode
  listItems: {
    key: string
    subHeader?: React.ReactNode
    items: React.ReactNode[]
  }[]
}

export const DetailList: React.FC<DetailListProps> = ({
  header,
  listItems,
}) => (
  <div className={styles.detailList}>
    <div className={styles.header}>
      <BaseText color="white" size={24} weight="bold">
        {header}
      </BaseText>
    </div>
    {listItems.map(({ subHeader, items, key }, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${key}-${i}`}>
        {subHeader && (
          <div className={styles.subHeader}>
            <Text size={18} weight="bold">
              {subHeader}
            </Text>
          </div>
        )}
        <div className={styles.list}>
          <List
            boundaryColor="gray"
            items={items}
            renderItem={(item, j) => (
              // eslint-disable-next-line react/no-array-index-key
              <List.Item key={`${key}-item-${i}-${j}`}>
                <div className={styles.item}>{item}</div>
              </List.Item>
            )}
          />
        </div>
      </div>
    ))}
  </div>
)
