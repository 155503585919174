import { stringify } from 'querystring'

import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

type UseOptionsApiProps = {
  property?: string
  room?: string
  plan?: string
  checkInDate: string
  checkOutDate: string
  numberOfAdults: number
  numberOfChildren: number
  numberOfInfants: number
  roomCount: number
}

export const useOptionsApi = ({
  property,
  room,
  plan,
  checkInDate,
  checkOutDate,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  roomCount,
}: UseOptionsApiProps) => {
  const params = {
    checkInDate,
    checkOutDate,
    roomCount,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
  }
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property && room && plan
      ? `/hotels/${property}/rooms/${room}/plans/${plan}/options?${stringify(
          params
        )}`
      : null,
    property && room && plan
      ? () =>
          propertyApi
            .listOptions(
              property,
              room,
              plan,
              checkInDate,
              checkOutDate,
              roomCount,
              numberOfAdults,
              numberOfChildren,
              numberOfInfants,
              options
            )
            .then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
