import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'
import { Decimal } from 'decimal.js'

import * as styles from './ReservationPanel.module.scss'

import { useAuthProvider, useLogin, usePriceApi } from '../../../hooks'
import { getNights } from '../../../utils'
import { formatJstDate } from '../../../utils/formatters'
import { Button, BaseText as Text } from '../../basics'
import { Loader } from '../../contents'
import { DiscountCodeForm } from '../../registrations'
import { Panel } from '../Panel'

type ReservationPanelProps = {
  isHotel?: boolean
  reservationId?: string | number | null
  cancelPrice?: number
  showDiscountCode?: boolean
  reservationModal?: React.ReactNode
  property?: string
  room?: string
  plan?: string
  checkInDate?: string
  checkOutDate?: string
  numberOfAdults?: number
  numberOfChildren?: number
  numberOfInfants?: number
  roomCount?: number
  qrLink?: string | null
  advancedCheckedInGuestUrl?: string | null
  isCancelled?: boolean
  onApiError?: (error: AxiosError) => void
  onPriceDidLoad?: (loaded: boolean) => void
  transactionId?: number
}

const translateNamespace = 'components.ReservationPanel'

const isNumber = (value: number | null | undefined): value is number => {
  if (typeof value === 'undefined') {
    return false
  }
  if (value === null) {
    return false
  }
  return true
}

export const ReservationPanel: React.FC<ReservationPanelProps> = ({
  isHotel,
  reservationId,
  cancelPrice,
  showDiscountCode = false,
  reservationModal,
  checkInDate = '',
  checkOutDate = '',
  numberOfAdults = 0,
  numberOfChildren = 0,
  numberOfInfants = 0,
  plan = '',
  property = '',
  room = '',
  roomCount = 0,
  qrLink,
  advancedCheckedInGuestUrl,
  isCancelled,
  onApiError,
  onPriceDidLoad,
  transactionId = 0,
}) => {
  const { t, i18n } = useTranslation()
  const { token } = useAuthProvider()
  const { onValidateTokenExpired } = useLogin()

  const { nights, rooms } = useMemo(
    () => ({
      rooms: roomCount ?? 0,
      nights: getNights(checkInDate, checkOutDate),
    }),
    [checkInDate, checkOutDate, roomCount]
  )

  const { data, isLoading, mutate, error } = usePriceApi({
    token,
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    propertySlug: property,
    roomSlug: room,
    planSlug: plan,
    roomCount: rooms,
    transactionId,
    reservationId,
  })

  useEffect(() => {
    if (error) {
      onValidateTokenExpired(error)
      onApiError?.(error)
    }
  }, [error, onValidateTokenExpired, onApiError])

  useEffect(() => {
    // Tell parent component to price api did load.
    if (data) {
      onPriceDidLoad?.(true)
    }
  }, [data, onPriceDidLoad])

  if (isLoading || !data) {
    return <Loader />
  }

  const {
    roomPrice,
    optionPrice: optionalPrice,
    discountPrice: discount,
    totalPrice: total,
    discountCode,
    taxes,
  } = data
  const cancellationFee = cancelPrice ?? data.cancellationFee ?? undefined

  return (
    <div className={styles.panel}>
      <Panel
        items={[
          showDiscountCode && (
            <DiscountCodeForm
              discountCode={discountCode ?? ''}
              onApplyFinish={() => {
                // Reload price api.
                mutate()
              }}
              transactionId={transactionId}
            />
          ),
          <dl>
            <Text size={18} weight="bold">
              {t(`${translateNamespace}.title`)}
            </Text>
            {reservationModal}
          </dl>,
          <React.Fragment>
            <dl>
              <dt>
                {isHotel
                  ? t(`${translateNamespace}.label.checkInDate`)
                  : t(`${translateNamespace}.label.dateOfUse`)}
              </dt>
              <dd>
                {formatJstDate(
                  checkInDate,
                  i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
                )}
              </dd>
            </dl>
            {isHotel && (
              <dl>
                <dt>{t(`${translateNamespace}.label.checkOutDate`)}</dt>
                <dd>
                  {formatJstDate(
                    checkOutDate,
                    i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
                  )}
                </dd>
              </dl>
            )}
            {isHotel && (
              <dl>
                <dt>{t(`${translateNamespace}.label.nights`)}</dt>
                <dd>{t(`${translateNamespace}.nights`, { nights })}</dd>
              </dl>
            )}
            {isHotel && (
              <dl>
                <dt>{t(`${translateNamespace}.label.rooms`)}</dt>
                <dd>{t(`${translateNamespace}.rooms`, { rooms })}</dd>
              </dl>
            )}
            <dl>
              <dt>
                {isHotel
                  ? t(`${translateNamespace}.label.guests`)
                  : t(`${translateNamespace}.label.people`)}
              </dt>
              <dd>
                <Trans
                  i18nKey={`${translateNamespace}.guests`}
                  value={{
                    numberOfAdults,
                    numberOfChildren,
                    numberOfInfants,
                  }}
                >
                  {{ numberOfAdults }} adults
                  <br />
                  {{ numberOfChildren }} children
                  <br />
                  {{ numberOfInfants }} infants
                </Trans>
              </dd>
            </dl>
          </React.Fragment>,
          <React.Fragment>
            {isNumber(roomPrice) && (
              <dl>
                <dt>
                  {isHotel
                    ? t(`${translateNamespace}.label.roomPrice`)
                    : t(`${translateNamespace}.label.ticketPrice`)}
                </dt>
                <dd>¥{roomPrice.toLocaleString()}</dd>
              </dl>
            )}
            {isNumber(optionalPrice) && (
              <dl>
                <dt>{t(`${translateNamespace}.label.optionalPrice`)}</dt>
                <dd>¥{optionalPrice.toLocaleString()}</dd>
              </dl>
            )}
          </React.Fragment>,
          <React.Fragment>
            {isNumber(total) && (
              <dl>
                <dt>{t(`${translateNamespace}.label.subTotal`)}</dt>
                <dd>¥{total.toLocaleString()}</dd>
              </dl>
            )}
            {taxes?.map((tax) => {
              if (!tax.taxAmount || !tax.taxRate) {
                return null
              }
              return (
                <dl key={`tax${tax.taxRate}${tax.taxAmount}`}>
                  <dt>
                    {`${t(`${translateNamespace}.label.tax`)} ${
                      tax.taxRate * 100
                    }%`}
                  </dt>
                  <dd>¥{tax.taxAmount.toLocaleString()}</dd>
                </dl>
              )
            })}
          </React.Fragment>,
          <div>
            {isNumber(discount) ? (
              <React.Fragment>
                <dl>
                  <dt>{t(`${translateNamespace}.label.total`)}</dt>
                  {isNumber(total) && <dd>¥{total.toLocaleString()}</dd>}
                </dl>
                <dl>
                  <dt>{t(`${translateNamespace}.label.discount`)}</dt>
                  <dd>
                    <Text color="red0">- ¥{discount.toLocaleString()}</Text>
                  </dd>
                </dl>
                <dl>
                  <dt>{t(`${translateNamespace}.label.totalDiscount`)}</dt>
                  {isNumber(total) && (
                    <dd className={styles.price}>
                      <b className={styles.yen}>¥</b>
                      <b className={styles.num}>
                        {new Decimal(total - discount)
                          .toNumber()
                          .toLocaleString()}
                      </b>
                    </dd>
                  )}
                </dl>
              </React.Fragment>
            ) : (
              <dl>
                <dt>{t(`${translateNamespace}.label.total`)}</dt>
                {isNumber(total) && (
                  <dd className={styles.price}>
                    <b className={styles.yen}>¥</b>
                    <b className={styles.num}>{total.toLocaleString()}</b>
                  </dd>
                )}
              </dl>
            )}
          </div>,
          <div>
            {typeof cancellationFee !== 'undefined' && (
              <dl>
                <dt>
                  <Text color="red0">
                    {t(`${translateNamespace}.label.cancel`)}
                  </Text>
                </dt>
                {isNumber(cancellationFee) && (
                  <dd className={styles.price}>
                    <Text color="red0">
                      <b className={styles.yen}>¥</b>
                      <b className={styles.num}>
                        {cancellationFee.toLocaleString()}
                      </b>
                    </Text>
                  </dd>
                )}
              </dl>
            )}
          </div>,
        ]}
      />
      {qrLink && !isCancelled && (
        <div className={styles.links}>
          <Button
            htmlType="button"
            outline
            variant="cta"
            onClick={() => {
              if (qrLink) {
                window.open(qrLink, '_blank', 'noreferrer')
              }
            }}
          >
            {t(`${translateNamespace}.qrLink`)}
          </Button>
        </div>
      )}
      {advancedCheckedInGuestUrl && !isCancelled && (
        <div className={styles.links}>
          <Button
            htmlType="button"
            outline
            variant="cta"
            onClick={() => {
              if (advancedCheckedInGuestUrl) {
                window.open(advancedCheckedInGuestUrl, '_blank', 'noreferrer')
              }
            }}
          >
            {t(`${translateNamespace}.advancedCheckedInGuestUrl`)}
          </Button>
        </div>
      )}
    </div>
  )
}
