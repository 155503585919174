import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { isAxiosError } from 'axios'

import { ModelErrorResponse, ReservationResponse } from '../../apis/fvillage'
import {
  BaseText,
  Button,
  CancelPolicySection,
  CancellationStepper,
  ErrorCard,
  Loader,
  MainColumn,
  MultiColumn,
  PageMessage,
  ReservationDetailButton,
  ReservationLayout,
  ReservationPanel,
  SideColumn,
} from '../../components'
import {
  useAuthProvider,
  useCancelApi,
  useCancelConfirmationApi,
  useCreateNextPath,
  useReservationApi,
  useReservationPath,
} from '../../hooks'

const translateNamespace = `frames.CancellationIndexFrame`

const getErrorStatusCode = (error?: unknown) => {
  if (isAxiosError(error) && error.response && error.response.data) {
    const modelError: ModelErrorResponse = error.response.data
    return modelError.errorCode ?? 'default'
  }
  return 'default'
}

const useDefaultValue = (reservation?: ReservationResponse) =>
  useMemo(() => {
    const cancelPolicy =
      reservation?.transaction?.input?.cancelPolicy ?? undefined
    const isHotel = reservation?.transaction?.input?.bookingEngineHotel?.isHotel

    const {
      checkInDate,
      checkOutDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      roomCount,
    } = reservation?.transaction?.input ?? {}
    const property = reservation?.transaction?.input?.bookingEngineHotel?.slug
    const room = reservation?.transaction?.input?.bookingEngineRoom?.slug
    const plan = reservation?.transaction?.input?.bookingEnginePlan?.slug

    return {
      cancelPolicy,
      isHotel,
      checkInDate,
      checkOutDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      roomCount,
      property,
      room,
      plan,
    }
  }, [reservation])

export const CancellationIndexFrame = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    params: { reservation: id },
    onCreateNextPath,
  } = useCreateNextPath()
  const { name, token } = useAuthProvider()
  const { reservation, isLoading: isReservationLoading } = useReservationApi({
    token,
    id: Number(id),
  })
  const [isLoading, setLoading] = useState(false)

  const { data: cancelConfirmation } = useCancelConfirmationApi(id ?? '', token)

  const { trigger: cancelTrigger, error } = useCancelApi(id ?? '', token)
  const reservationPath = useReservationPath()
  const cancelReservation = useCallback(async () => {
    setLoading(true)
    try {
      await cancelTrigger()
      navigate(
        onCreateNextPath(reservationPath.cancel.completion, {
          pathParams: {
            reservation: id,
          },
        })
      )
    } finally {
      setLoading(false)
    }
  }, [cancelTrigger, id, navigate, onCreateNextPath, reservationPath])

  const {
    cancelPolicy,
    checkInDate,
    checkOutDate,
    isHotel,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    plan,
    property,
    room,
    roomCount,
  } = useDefaultValue(reservation)

  if ((isReservationLoading && !reservation) || isLoading) {
    return <Loader />
  }

  return (
    <ReservationLayout
      pageTitle={t(`${translateNamespace}.pageTitle`)}
      reservationName={name}
    >
      <CancellationStepper
        current={1}
        stepName={t(`${translateNamespace}.stepName`)}
      />
      <PageMessage>
        <Trans i18nKey={`${translateNamespace}.pageMessage`}>
          Your cancellation has not been processed yet. <br />
          Please check the cancellation fee below and click the Cancel button.{' '}
          <br />
          The cancellation process will be executed when you click the Cancel
          button.
        </Trans>
      </PageMessage>
      <MultiColumn isSpColumnReverse>
        <MainColumn>
          <CancelPolicySection cancelPolicy={cancelPolicy} />
          {error && (
            <ErrorCard
              statusCode={getErrorStatusCode(error)}
              errorMessage={t(`${translateNamespace}.error`)}
            />
          )}
          <MultiColumn isSpColumnReverse>
            <Button
              htmlType="button"
              variant="tertiary"
              size="xmedium"
              onClick={() => {
                navigate(
                  onCreateNextPath(reservationPath.reservation, {
                    pathParams: {
                      reservation: id,
                    },
                  })
                )
              }}
            >
              <BaseText weight="bold">
                {t(`${translateNamespace}.stopProcessing`)}
              </BaseText>
            </Button>

            <Button
              htmlType="button"
              outline
              size="xmedium"
              variant="danger"
              disabled={!cancelConfirmation?.canCancel}
              onClick={cancelReservation}
            >
              <BaseText weight="bold">
                {t(`${translateNamespace}.next`)}
              </BaseText>
            </Button>
          </MultiColumn>
        </MainColumn>
        <SideColumn>
          <ReservationPanel
            isHotel={isHotel}
            reservationId={id}
            cancelPrice={cancelConfirmation?.cancellationFee}
            reservationModal={
              <ReservationDetailButton transaction={reservation?.transaction} />
            }
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            numberOfAdults={numberOfAdults}
            numberOfChildren={numberOfChildren}
            numberOfInfants={numberOfInfants}
            roomCount={roomCount}
            property={property}
            room={room}
            plan={plan}
            transactionId={reservation?.transaction?.id}
          />
        </SideColumn>
      </MultiColumn>
    </ReservationLayout>
  )
}
